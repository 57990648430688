import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { SaveWorkInitPayload, WorkInit } from 'work/api/workInitApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface WorkInitState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: WorkInit | undefined }
}

const initialState: WorkInitState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: workInitReducer } = createSlice({
  name: 'workInit',
  initialState,
  reducers: {
    saveWorkInitStarted(
      state,
      { payload }: PayloadAction<SaveWorkInitPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveWorkInitSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<WorkInit>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveWorkInitFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveWorkInitStarted,
  saveWorkInitSuccess,
  saveWorkInitFail
} = actions

export default workInitReducer
