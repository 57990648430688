import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import {
  RequestFinalSurvey,
  SaveRequestFinalSurveyPayload
} from 'work/api/requestFinalSurveyApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface RequestFinalSurveyState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: RequestFinalSurvey | undefined }
}

const initialState: RequestFinalSurveyState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: requestFinalSurveyReducer } = createSlice({
  name: 'requestFinalSurvey',
  initialState,
  reducers: {
    saveRequestFinalSurveyStarted(
      state,
      { payload }: PayloadAction<SaveRequestFinalSurveyPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveRequestFinalSurveySuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<RequestFinalSurvey>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveRequestFinalSurveyFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveRequestFinalSurveyStarted,
  saveRequestFinalSurveySuccess,
  saveRequestFinalSurveyFail
} = actions

export default requestFinalSurveyReducer
