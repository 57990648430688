import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import { ItemChecklist } from 'ItemsChecklist/api/itemsChecklistApi'
import { Block } from 'block/api/blockApi'
import api from 'common/api/api'
import { ItemTypeChecklist } from 'itemTypeChecklist/api/itemTypeChecklistApi'
import { Lot } from 'lot/api/lotApi'
import { PaymentAttachment } from 'paymentAttachment/api/paymentAttachmentApi'
import { Project } from 'project/api/projectApi'
import qs from 'qs'
import createRequestError from 'shared/createRequestError'
import { Street } from 'street/api/streetApi'
import { Upload } from 'upload/api/uploadApi'
import { UserWorkProfile } from 'user/api/userApi'
import workStatus from 'work/enums/workStatus'
import tipoEventoObraEnum from 'workEvent/enums/tipoEventoObraEnum'

/**
 * Obra
 */
export interface BlockForWorkResponse extends Block {
  rua: Street
}
export interface LotWorkResponse extends Lot {
  quadra: BlockForWorkResponse
}

export interface Proprietario {
  id: string
  pessoaNome: string
}

export interface Work {
  id: string
  codigo?: number
  loteId: string
  lote?: LotWorkResponse
  condominioId: string
  status: workStatus
  areaConstruida: number
  areaAConstruir: number
  areaTotal: number
  areaTerreno: number
  numeroPavimento: number
  proprietarios?: Proprietario[]
  itemsProcessoAprovado?: boolean
  itemsObraAprovado?: boolean
  todosItemsChecklistEnviadosObra: boolean
  todosItemsChecklistEnviadosProcesso: boolean
  alteradoRecentemente: boolean
  exibeFlagAcesso: boolean
  dataUltimoEvento: string
  possuiAutor?: boolean
  reanalisado?: boolean
  comprovanteDePagamentoEnviado: boolean
  comprovanteDePagamentoAprovado?: boolean
  emReanalise?: boolean
  todosItemsChecklistEnviadosFinalObra: boolean
  itemsFinalObraAprovado: boolean
  checklistAprovado?: boolean
  VistoriaAprovada?: boolean
  habitadaComRessalva: boolean
  projetoHomologadoEnviado: boolean
  ultimoEventoTipoEvento: tipoEventoObraEnum
  loteArea: string
  tipoProcesso?: string | number
  prazoAnalise?: string
  habitada?: boolean | null
  obraIniciadaSemAutorizacao?: boolean
}

export type FetchWorksOptions = PaginatedEndpointOptions<Work>

export type FetchWorksResponse = PaginatedEndpointResponse<Work>

/**
 * Carrega lista de Obras paginados e filtradas pela query.
 */
export async function fetchWorks(options?: FetchWorksOptions) {
  try {
    const endpoint = `/Obra${
      options ? qs.stringify(options, { addQueryPrefix: true }) : ''
    }`

    const { data } = await api.get<FetchWorksResponse>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista Obras de um condomínio
 */
export async function fetchWorksFromCondominium(condominiumId: string) {
  try {
    const endpoint = `/Condominio/${condominiumId}/obras`

    const { data } = await api.get<Work[]>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega uma Obra pelo id.
 */
export async function fetchWork(id: string) {
  try {
    const { data } = await api.get<Work>(`Obra/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveWorkPayload = SaveResourcePayload<
  Omit<
    Work,
    | 'itemsProcessoAprovado'
    | 'itemsObraAprovado'
    | 'todosItemsChecklistEnviadosObra'
    | 'todosItemsChecklistEnviadosProcesso'
    | 'alteradoRecentemente'
    | 'exibeFlagAcesso'
    | 'dataUltimoEvento'
    | 'possuiAutor'
    | 'reanalisado'
    | 'comprovanteDePagamentoEnviado'
    | 'comprovanteDePagamentoAprovado'
    | 'todosItemsChecklistEnviadosFinalObra'
    | 'itemsFinalObraAprovado'
    | 'habitadaComRessalva'
    | 'projetoHomologadoEnviado'
    | 'emReanalise'
    | 'ultimoEventoTipoEvento'
    | 'loteArea'
  >
>

/**
 * Salva uma Obra, se houver id altera o existente.
 */
export async function saveWork(work: SaveWorkPayload) {
  try {
    const request = work.id ? api.put : api.post

    const { data } = await request<Work>('Obra', work)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma Obra pelo id.
 */
export async function deleteWork(id: string) {
  try {
    const { data } = await api.delete<Work>(`Obra/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface UserWork {
  id: string
  pessoaId: string
  obraId: string
  cargoObra: UserWorkProfile
  permissoes?: string[]
}

export type SaveUserWorkPayload = SaveResourcePayload<UserWork>

/**
 * Vincula uma pessoa a uma obra
 */
export async function saveUserWork(userWork: SaveUserWorkPayload) {
  try {
    const { data } = await api.post<UserWork>('Obra/PessoaObra', userWork)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type FetchUserWorkOptions = PaginatedEndpointOptions<UserWork>
export type FetchUserWorksResponse = PaginatedEndpointResponse<UserWork>

export async function fetchUserWork(
  workId: string,
  options?: FetchUserWorkOptions
) {
  try {
    const { data } = await api.get<FetchUserWorksResponse>('Obra/PessoaObra', {
      params: { obraId: workId, ...options }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma usuario vinculado a pelo id.
 */
export async function deleteUserWork(id: string) {
  try {
    const { data } = await api.delete<UserWork>(`Obra/PessoaObra/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface RemoveRoutineWorkOptions {
  obraId: string
  rotinaId: string
}

export interface RemoveRoutineWork {
  id: string
  obraId: string
  rotinaId: string
}
/**
 * Remover uma rotina de uma obra.
 */
export async function deleteRoutineWork(options: RemoveRoutineWorkOptions) {
  try {
    const { data } = await api.delete<RemoveRoutineWork>(
      `/Obra/${options.obraId}/Rotina/RemoveRotinaDaObra/${options.rotinaId}`,
      { data: options }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface AtualizaAcessoBody {
  obraId: string
  marcarNaoLida: boolean
}

/**
 * Marcar como não lido
 */
export async function atualizaAcesso(atualizaAcessoBody: AtualizaAcessoBody) {
  try {
    const { data } = await api.post<AtualizaAcessoBody>(
      `Obra/atualizaAcesso`,
      atualizaAcessoBody
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface Book {
  projeto: Project
  comprovante: PaymentAttachment
  tipoItemChecklists: (ItemTypeChecklist & {
    itemChecklists: ItemChecklist[]
  })[]
  cartaLiberacao?: {
    id: string
    obraId: string
    aprovada: boolean
    cartasUpload: {
      aprovado: boolean
      homologado: boolean
      upload: Upload
    }[]
  }
}

/**
 * Carrega lista de Obras paginados e filtradas pela query.
 */
export async function fetchBook(ObraId: string) {
  try {
    const endpoint = `Obra/${ObraId}/book`

    const { data } = await api.get<Book>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Salva uma Obra, se houver id altera o existente.
 */
export async function reanalyzeProccess(data: {
  obraId: string
  mensagem: string
}) {
  try {
    const { data: response } = await api.put<Work>('Obra/Reanalisar', data)

    return response
  } catch (error) {
    throw createRequestError(error)
  }
}

export interface SincronizaChecklistType {
  id: string
}

export async function SincronizaChecklist(id: SincronizaChecklistType) {
  try {
    const { data } = await api.put<SincronizaChecklistType>(
      `/Obra/${id.id}/AtualizaChecklistObra`,
      id
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
