import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ItemChecklistApproval } from 'ItemsChecklist/api/ItemChecklistApprovalApi'
import { RequestError } from 'types/global'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state de aprovação de item de checklist
 */
export interface ItemChecklistApprovalState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
}
// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: ItemChecklistApprovalState = {
  status: {},
  errors: {}
}

const { actions, reducer: itemChecklistApprovalReducer } = createSlice({
  name: 'itemChecklistApproval',
  initialState,
  reducers: {
    saveItemChecklistApprovalStarted(
      state,
      { payload }: PayloadAction<ItemChecklistApproval>
    ) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveItemChecklistApprovalSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: 'new'
      }>
    ) {
      const { forItem } = payload

      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveItemChecklistApprovalFail(
      state,
      { payload }: PayloadAction<{ forItem: 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const {
  saveItemChecklistApprovalFail,
  saveItemChecklistApprovalStarted,
  saveItemChecklistApprovalSuccess
} = actions

export default itemChecklistApprovalReducer
