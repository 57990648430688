import {
  FetchItemsTypeChecklistOptions,
  ItemTypeChecklist,
  SaveItemTypeChecklistPayload
} from 'itemTypeChecklist/api/itemTypeChecklistApi'
import { Pagination, RequestError, ResourceFailPayload } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface ItemTypeChecklistState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: ItemTypeChecklist | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: ItemTypeChecklistState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: itemTypeChecklistReducer } = createSlice({
  name: 'itemTypeChecklist',
  initialState,
  reducers: {
    fetchItemsTypeChecklistStarted(
      state,
      action: PayloadAction<FetchItemsTypeChecklistOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchItemsTypeChecklistSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: ItemTypeChecklist }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchItemsTypeChecklistFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    fetchSingleItemTypeChecklistStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    fetchSingleItemTypeChecklistSuccess(
      state,
      { payload }: PayloadAction<ItemTypeChecklist>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    fetchSingleItemTypeChecklistFail(
      state,
      { payload }: PayloadAction<{ id: string; error?: RequestError }>
    ) {
      state.status[payload.id] = 'Error'
      state.errors[payload.id] = payload.error

      return state
    },
    saveItemTypeChecklistStarted(
      state,
      { payload }: PayloadAction<SaveItemTypeChecklistPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveItemTypeChecklistSuccess(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; result: ItemTypeChecklist }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveItemTypeChecklistFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteItemTypeChecklistStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteItemTypeChecklistSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      for (const page in state.pages) {
        const items = state.pages[page]
        items && remove(items, payload)
      }

      return state
    },
    deleteItemTypeChecklistFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

export const getItemTypeChecklistById = (id: string) =>
  createSelector(({ itemTypeChecklist }) => itemTypeChecklist.items[id])

export const itemsTypeChecklistOnPage = createSelector(
  ({ itemTypeChecklist }) => {
    const { pagination, pages } = itemTypeChecklist

    return pages[pagination.current] || []
  }
)

export const {
  fetchItemsTypeChecklistFail,
  fetchItemsTypeChecklistStarted,
  fetchItemsTypeChecklistSuccess,
  fetchSingleItemTypeChecklistFail,
  fetchSingleItemTypeChecklistStarted,
  fetchSingleItemTypeChecklistSuccess,
  saveItemTypeChecklistFail,
  saveItemTypeChecklistStarted,
  saveItemTypeChecklistSuccess,
  deleteItemTypeChecklistFail,
  deleteItemTypeChecklistStarted,
  deleteItemTypeChecklistSuccess
} = actions

export default itemTypeChecklistReducer
