import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'
import { SubmitInitialWork, saveWorkInit } from 'work/api/submitInitialWork'

import { RootState } from 'store'

/**
 * Interfaces
 */

export interface SubmitInitialWorkState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

/**
 * Thunks
 */

export const saveSubmitInitialWork = createAsyncThunk(
  'submitInitialWork/saveSubmitInitialWork',
  async (payload: SubmitInitialWork, { rejectWithValue }) => {
    try {
      return await saveWorkInit(payload.obraId)(payload)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
/**
 * Slice
 */

const SubmitInitialWorkAdapter = createEntityAdapter<SubmitInitialWork>()

const { reducer } = createSlice({
  name: 'submitInitialWork',
  initialState: SubmitInitialWorkAdapter.getInitialState<
    SubmitInitialWorkState
  >({
    status: {},
    errors: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveSubmitInitialWork.pending, (state, action) => {
      const submitInitialWork = action.meta.arg
      state.status[submitInitialWork.obraId] = 'Pending'
      state.errors[submitInitialWork.obraId] = undefined
    })

    builder.addCase(saveSubmitInitialWork.fulfilled, (state, action) => {
      const submitInitialWork = action.meta.arg
      SubmitInitialWorkAdapter.upsertOne(state, action.payload)
      state.status[submitInitialWork.obraId] = 'Done'
      state.errors[submitInitialWork.obraId] = undefined
    })

    builder.addCase(saveSubmitInitialWork.rejected, (state, action) => {
      state.status[action.meta.arg.obraId] = 'Error'
      state.errors[action.meta.arg.obraId] = action.payload as any
    })
  }
})

/**
 * Selectors
 */

export const createSelectStatusOfSubmitInitialWork = (id: string) => {
  return ({ submitInitialWork }: RootState) => submitInitialWork.status[id]
}

export const selectErrorById = (id: string) => {
  return ({ submitInitialWork }: RootState) => submitInitialWork.errors[id]
}

export default reducer
