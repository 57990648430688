import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ServiceProviderCreateState } from 'serviceProvider/contracts/types'

const { reducer, actions } = createSlice({
  name: 'serviceProvider/provider',
  initialState: {} as ServiceProviderCreateState,
  reducers: {
    addServiceProviders: (
      state,
      action: PayloadAction<ServiceProviderCreateState>
    ) => {
      return action.payload
    }
  }
})

export const { addServiceProviders } = actions

export default reducer
