import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'

import { RootState } from 'store'
import { SendRealeaseLetter } from 'releaseLetter/api/releaseLetter'
import { sendRealeaseLetterThunk } from 'releaseLetter/thunks/releaseLetter'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

export interface SendRealeaseLetterState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const itemAdapter = createEntityAdapter<SendRealeaseLetter>()

const initialState: SendRealeaseLetterState = {
  status: {},
  errors: {}
}

const { actions, reducer: sendRealeaseLetterReducer } = createSlice({
  name: 'sendRealeaseLetter',
  initialState: itemAdapter.getInitialState(initialState),
  reducers: {
    addIncludedSendRealeaseLetterItems: itemAdapter.upsertMany
  },
  extraReducers(builder) {
    builder.addCase(sendRealeaseLetterThunk.pending, (state, action) => {
      const itemId = action.meta.arg.obraId

      state.status[itemId] = 'Saving'
      state.errors[itemId] = undefined
    })

    builder.addCase(sendRealeaseLetterThunk.fulfilled, (state, action) => {
      const itemId = action.meta.arg.obraId

      state.status[itemId] = 'Done'
      state.errors[itemId] = undefined

      itemAdapter.upsertOne(state, action.payload)
    })

    builder.addCase(sendRealeaseLetterThunk.rejected, (state, action) => {
      const itemId = action.meta.arg.obraId
      state.status[itemId] = 'Error'
      state.errors[itemId] = action.payload as any
    })
  }
})

export const { addIncludedSendRealeaseLetterItems } = actions

export const getSendReleaseLetterStatus = (id: string) => ({
  sendReleaseLetter
}: RootState) => sendReleaseLetter.status[id]

export const getSendReleaseLetterError = (id: string) => ({
  sendReleaseLetter
}: RootState) => sendReleaseLetter.errors[id]

export default sendRealeaseLetterReducer
