import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Pagination, RequestError, ResourceStatus } from 'types/global'
import {
  createNotificationThunk,
  deleteNotificationThunk,
  getNotificationThunk,
  getNotificationsThunk,
  updateNotificationThunk
} from 'notifications/thunks/notifications'
import { normalize, schema } from 'normalizr'

import { Notification } from 'notifications/api/notification'
import createSelector from 'shared/createSelector'

export interface NotificationState {
  status: Dictionary<ResourceStatus>
  items: Dictionary<Notification>
  errors: Dictionary<RequestError>
  pagination: Pagination
  pages: Dictionary<string[]>
}

const adapter = createEntityAdapter<Notification>()
const notificationSchema = new schema.Entity<Notification>('notification')

const { reducer } = createSlice({
  name: 'notification',
  initialState: adapter.getInitialState<NotificationState>({
    items: {},
    errors: {},
    status: {},
    pages: {},
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1
    }
  }),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNotificationsThunk.pending, state => {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined
    })

    builder.addCase(getNotificationsThunk.fulfilled, (state, action) => {
      const { entities } = normalize(action.payload.result, [
        notificationSchema
      ])

      state.items = {
        ...state.items,
        ...entities.notification
      }

      const pagination: Pagination = {
        current: action.payload.page,
        total: action.payload.totalItemCount
      }

      state.pages[pagination?.current ?? ''] = Object.keys(
        entities?.notification ?? {}
      )

      state.pagination = pagination

      state.status['page'] = 'Done'
    })

    builder.addCase(getNotificationsThunk.rejected, (state, action) => {
      state.status['page'] = 'Error'
      state.errors['page'] = action.payload as any
    })

    builder.addCase(createNotificationThunk.pending, state => {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined
    })

    builder.addCase(createNotificationThunk.fulfilled, (state, action) => {
      const { entities } = normalize(action.payload, notificationSchema)
      state.items = {
        ...state.items,
        ...entities.notification
      }

      state.status['new'] = 'Done'
    })

    builder.addCase(createNotificationThunk.rejected, (state, action) => {
      state.status['new'] = 'Error'
      state.errors['new'] = action.payload as any
    })

    builder.addCase(deleteNotificationThunk.pending, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Deleting'
      state.errors[id] = undefined
    })

    builder.addCase(deleteNotificationThunk.fulfilled, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Done'
      state.items[id] = undefined
    })

    builder.addCase(deleteNotificationThunk.rejected, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Error'
      state.errors[id] = action.payload as any
    })

    builder.addCase(getNotificationThunk.pending, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Pending'
      state.errors[id] = undefined
    })

    builder.addCase(getNotificationThunk.fulfilled, (state, action) => {
      const id = action.meta.arg.id
      const { entities } = normalize(action.payload, notificationSchema)

      state.items = {
        ...state.items,
        ...entities.notification
      }

      state.status[id] = 'Done'
    })

    builder.addCase(getNotificationThunk.rejected, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Error'
      state.errors[id] = action.payload as any
    })

    builder.addCase(updateNotificationThunk.pending, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Saving'
      state.errors[id] = undefined
    })

    builder.addCase(updateNotificationThunk.fulfilled, (state, action) => {
      const id = action.meta.arg.id
      const { entities } = normalize(action.payload, notificationSchema)

      state.items = {
        ...state.items,
        ...entities.notification
      }

      state.status[id] = 'Done'
    })

    builder.addCase(updateNotificationThunk.rejected, (state, action) => {
      const id = action.meta.arg.id

      state.status[id] = 'Error'
      state.errors[id] = action.payload as any
    })
  }
})

export const selectCurrentPage = createSelector(({ notification }) => {
  const { pagination, pages } = notification

  return pages[pagination.current]
})

export const selectPagination = createSelector(
  ({ notification }) => notification.pagination
)

export const selectById = (id: string) =>
  createSelector(({ notification }) => notification.items[id])

export const selectStatus = (id: string) =>
  createSelector(({ notification }) => notification.status[id])

export const selectError = (id: string) =>
  createSelector(({ notification }) => notification.errors[id])

export default reducer
