import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack
} from '@chakra-ui/core'
import React, { FC, useCallback, useEffect } from 'react'
import { condominiumProfileSelected, logout } from 'auth/reducers/authReducer'

import Avatar from 'carbono/Avatar'
import { Condominium } from 'condominium/api/condominiumApi'
import { Header } from 'carbono/layout/SidebarLayout'
import Icon from 'carbono/Icon'
import SelectCondominium from './selects/SelectCondominium'
import { loadUserPermissionsStarted } from 'userPermissions/reducers/userPermissionsReducer'
import { useDispatch } from 'react-redux'
import useRouter from 'use-react-router'
import useTypedSelector from 'common/hooks/useTypedSelector'

const AppHeader: FC = () => {
  const dispatch = useDispatch()
  const { history } = useRouter()
  const payload = useTypedSelector(({ auth }) => auth.tokenPayload)

  const currentCondominium = useTypedSelector(
    ({ auth }) => auth.currentCondominium
  )

  const onClickLogout = useCallback(() => {
    dispatch(logout())
  }, [])

  const onClickUserData = useCallback(() => {
    history.push(`/user/${payload?.pessoa}`)
  }, [])

  const onChangeCondominium = useCallback((condominium?: Condominium) => {
    if (!condominium) return
    dispatch(condominiumProfileSelected(condominium.id))

    history.push(`/condominiums/${condominium.id}`)
  }, [])

  useEffect(() => {
    if (!currentCondominium) return
    dispatch(loadUserPermissionsStarted({ CondominioId: currentCondominium }))
  }, [currentCondominium])

  return (
    <Header>
      <Stack isInline align='center' justify='flex-end' width='100%'>
        <Box minWidth={350}>
          <SelectCondominium
            isSize='small'
            value={currentCondominium}
            onChange={onChangeCondominium}
          />
        </Box>

        <Menu>
          <MenuButton as={Button} bg='white' borderColor='gray.200'>
            <Avatar email={payload?.email ?? ''} size={30} mr={2} />
            {payload?.given_name}
          </MenuButton>
          <MenuList placement='bottom-end'>
            <MenuItem onClick={onClickUserData} border='none'>
              <Icon icon='user' mr={8} /> Meus dados
            </MenuItem>

            <MenuItem onClick={onClickLogout} border='none'>
              <Icon icon='sign-out-alt' mr={8} /> Sair
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Header>
  )
}

export default AppHeader
