import {
  ItemChecklistDisapproveAnalisis,
  SaveItemChecklistDisapproveAnalisiskPayload,
  saveItemChecklistDisapproveAnalisisk
} from 'ItemsChecklist/api/itemChecklistDisapproveAnalisisApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveItemChecklistDisapproveAnalisisFail,
  saveItemChecklistDisapproveAnalisisStarted,
  saveItemChecklistDisapproveAnalisisSuccess
} from 'ItemsChecklist/reducers/itemChecklistDisapproveAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const itemChecklistDisapproveAnalisisSchema = new schema.Entity<
  ItemChecklistDisapproveAnalisis
>('itemChecklistDisapproveAnalisis')

export function* handleSaveItemChecklistDisapproveAnalisis({
  payload
}: PayloadAction<SaveItemChecklistDisapproveAnalisiskPayload>) {
  try {
    const response = yield call(saveItemChecklistDisapproveAnalisisk, payload)

    const { entities } = normalize(
      response,
      itemChecklistDisapproveAnalisisSchema
    )

    return yield put(
      saveItemChecklistDisapproveAnalisisSuccess({
        forItem: payload.id || 'new',
        result: entities.itemChecklistDisapproveAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveItemChecklistDisapproveAnalisisFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* ItemChecklistDisapproveAnalisisSaga() {
  yield takeLeading(
    saveItemChecklistDisapproveAnalisisStarted.type,
    handleSaveItemChecklistDisapproveAnalisis
  )
}

export default ItemChecklistDisapproveAnalisisSaga
