import { PaginatedEndpointOptions, SaveResourcePayload } from 'types/global'
import {
  deleteResource,
  fetchAll,
  fetchSingle,
  save
} from 'common/api/apiHelper'

import { Subject } from './subjects'
import { Upload } from 'upload/api/uploadApi'

export interface TechnicalDocument {
  id: string
  condominioId: string
  disciplinaId: string
  disciplina: Subject
  uploads: Upload[]
}

interface SaveTechnicalDocument {
  id: string
  condominioId: string
  disciplinaId: string
  uploads: string[]
}

export type SaveTechnicalDocumentPayload = SaveResourcePayload<
  SaveTechnicalDocument
>
export type FetchTechnicalDocumentsOptions = PaginatedEndpointOptions<
  TechnicalDocument
>

export const fetchTechnicalDocuments = fetchAll<TechnicalDocument>(
  'DocumentoTecnico'
)

export const fetchSingleTechnicalDocument = fetchSingle<TechnicalDocument>(
  id => `DocumentoTecnico/${id}`
)

export const saveTechnicalDocument = save<SaveTechnicalDocumentPayload>(
  'DocumentoTecnico'
)

export const deleteTechnicalDocument = deleteResource<TechnicalDocument>(
  id => `DocumentoTecnico/${id}`
)
