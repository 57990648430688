import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

import { SubmitWorkToAnalisis } from 'submitWorkToAnalisis/api/submitWorkToAnalisisApi'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do submissão de obra para análise
 */
export interface SubmitWorkToAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const initialState: SubmitWorkToAnalisisState = {
  status: {},
  errors: {}
}

const { actions, reducer: SubmitWorkToAnalisisReducer } = createSlice({
  name: 'submitWorkToAnalisis',
  initialState,
  reducers: {
    saveSubmitWorkToAnalisisStarted(
      state,
      { payload }: PayloadAction<SubmitWorkToAnalisis>
    ) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveSubmitWorkToAnalisisSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<SubmitWorkToAnalisis>>
    ) {
      const { forItem } = payload

      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveSubmitWorkToAnalisisFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const {
  saveSubmitWorkToAnalisisFail,
  saveSubmitWorkToAnalisisStarted,
  saveSubmitWorkToAnalisisSuccess
} = actions

export default SubmitWorkToAnalisisReducer
