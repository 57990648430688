import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

/**
 */
export function fetchAll<T>(endpointResourceName: string) {
  return async function(options?: PaginatedEndpointOptions<T>) {
    try {
      const { data } = await api.get<PaginatedEndpointResponse<T>>(
        endpointResourceName,
        { params: options }
      )

      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

/**
 */
export function fetchSingle<T>(
  endpointResourceName: (params: string) => string
) {
  return async function(id: string) {
    try {
      const { data } = await api.get<T>(endpointResourceName(id))

      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

/**
 */
export function save<T>(endpointResourceName: string) {
  return async function(
    entity: T extends { id: string } ? SaveResourcePayload<T> : T
  ) {
    try {
      const request = 'id' in entity ? api.put : api.post

      const { data } = await request<T>(endpointResourceName, entity)

      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

/**
 */
export function update<T>(endpointResourceName: string) {
  return async function(entity: T) {
    try {
      const { data } = await api.put<T>(endpointResourceName, entity)
      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

/**
 */
export function deleteResource<T>(
  endpointResourceName: (params: string) => string
) {
  return async function(id: string) {
    try {
      const { data } = await api.delete<T>(endpointResourceName(id))

      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

export function postResource<T>(
  endpointResourceName: (params: string) => string
) {
  return async function(id: string) {
    try {
      const { data } = await api.post<T>(endpointResourceName(id))

      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

export function updateSubLocal<T>(
  endpointResourceName: string,
  localId: string
) {
  return async function(entity: T) {
    entity = { ...entity, localId }
    try {
      const { data } = await api.put<T>(endpointResourceName, entity)
      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

export function updateSubSubLocal<T>(
  endpointResourceName: string,
  subLocalId: string
) {
  return async function(entity: T) {
    entity = { ...entity, subLocalId }
    try {
      const { data } = await api.put<T>(endpointResourceName, entity)
      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

export function updateSubItem<T>(
  endpointResourceName: string,
  ItemManutencaoId: string
) {
  return async function(entity: T) {
    entity = { ...entity, ItemManutencaoId }
    try {
      const { data } = await api.put<T>(endpointResourceName, entity)
      return data
    } catch (error) {
      throw createRequestError(error)
    }
  }
}

export function fetchDetails<T>(endpointResourceName: string) {
  return async function(options?: T) {
    try {
      const { data } = await api.get<T>(endpointResourceName, {
        params: options
      })

      return data
    } catch (error) {
      const errorData = createRequestError(error)
      if (errorData.status === 404) {
        return undefined
      }
      throw createRequestError(error)
    }
  }
}
