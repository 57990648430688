import React, { FC, MouseEventHandler } from 'react'
import { PseudoBox, Icon } from '@chakra-ui/core'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { useMenuItemStyle } = require('@chakra-ui/core/dist/Menu/styles')

type OptionProps = {
  isSelected?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Option: FC<OptionProps> = ({ isSelected, children, onClick }) => {
  const styles = useMenuItemStyle()

  return (
    <PseudoBox
      display='flex'
      minHeight='32px'
      alignItems='center'
      onClick={onClick}
      cursor='pointer'
      {...styles}
      _hover={{
        bg: 'gray.50'
      }}
    >
      <Icon
        name='check'
        opacity={isSelected ? 1 : 0}
        color='currentColor'
        size='1em'
        ml='1rem'
        mr='-4px'
        aria-hidden
        data-menuitem-icon=''
      />
      <PseudoBox textAlign='left' as='span' mx='1rem' flex='1'>
        {children}
      </PseudoBox>
    </PseudoBox>
  )
}

export default Option
