import { AxiosError } from 'axios'
import { RequestError } from 'types/global'

function createRequestError(e: AxiosError): RequestError {
  if (!e.response)
    return {
      name: 'NetworkError',
      status: 0
    }

  const { title, status, errors, traceId } = e.response.data

  return {
    name: 'RequestError',
    status: status,
    description: title,
    traceId: traceId,
    items: errors
  }
}

export default createRequestError
