import {
  SaveWorkCommentPayload,
  WorkComment,
  saveWorkComment
} from 'workComment/api/workCommentApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveWorkCommentFail,
  saveWorkCommentStarted,
  saveWorkCommentSuccess
} from 'workComment/reducer/workCommentReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const workCommentSchema = new schema.Entity<WorkComment>('workComment')

export function* handleSaveWorkComment({
  payload
}: PayloadAction<SaveWorkCommentPayload>) {
  try {
    const response = yield call(saveWorkComment, payload)

    const { entities } = normalize(response, workCommentSchema)

    return yield put(
      saveWorkCommentSuccess({
        forItem: payload.id || 'new',
        result: entities.workComment[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveWorkCommentFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* workCommentSaga() {
  yield takeLeading(saveWorkCommentStarted.type, handleSaveWorkComment)
}

export default workCommentSaga
