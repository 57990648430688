import { ShelveProcess, shelveProcess } from 'process/api/shelveProcess'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const shelveProcessThunk = createAsyncThunk(
  'process/shelveProcess',
  async (payload: ShelveProcess, { rejectWithValue }) => {
    try {
      return await shelveProcess(payload)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
