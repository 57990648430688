import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import {
  FinishReanalysis,
  saveFinishReanalysis
} from 'reanalysis/api/finishReanalysis'
import { RequestError, ResourceStatus } from 'types/global'

import { RootState } from 'store'

/**
 * Interfaces
 */

export interface FinishReanalysisState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

/**
 * Thunks
 */

export const loadFinishReanalysis = createAsyncThunk(
  'finishReanalysis/loadFinishReanalysis',
  async (value: FinishReanalysis, { rejectWithValue }) => {
    try {
      return await saveFinishReanalysis(value.obraId)(value)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/**
 * Slice
 */

const FinishReanalysisAdapter = createEntityAdapter<FinishReanalysis>()

const { reducer } = createSlice({
  name: 'finishReanalysis',
  initialState: FinishReanalysisAdapter.getInitialState<FinishReanalysisState>({
    status: {},
    errors: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadFinishReanalysis.pending, (state, action) => {
      const value = action.meta.arg
      state.status[value.obraId] = 'Pending'
      state.errors[value.obraId] = undefined
    })

    builder.addCase(loadFinishReanalysis.fulfilled, (state, action) => {
      const value = action.meta.arg
      FinishReanalysisAdapter.upsertOne(state, action.payload)
      state.status[value.obraId] = 'Done'
    })

    builder.addCase(loadFinishReanalysis.rejected, (state, action) => {
      const value = action.meta.arg

      state.status[value.obraId] = 'Error'
      state.errors[value.obraId] = action.payload as any
    })
  }
})

export const createSelectStatusOfFinishReanalysis = (obraId: string) => {
  return ({ finishReanalysis }: RootState) => finishReanalysis.status[obraId]
}
export const selectError = (obraId: string) => {
  return ({ finishReanalysis }: RootState) => finishReanalysis.errors[obraId]
}

export default reducer
