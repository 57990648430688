// ISSO É TEMPORÁRIOOOOOO!!
// TODO(Fábio): precisamos apagar isso o quanto antes!!
import Axios from 'axios'
import qs from 'qs'

const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
})

export default api
