import {
  ItemChecklistApproveAnalisis,
  SaveItemChecklistApproveAnalisiskPayload
} from 'ItemsChecklist/api/itemChecklistApproveAnalisisApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface ItemChecklistApproveAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ItemChecklistApproveAnalisis | undefined }
}

const initialState: ItemChecklistApproveAnalisisState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: itemChecklistApproveAnalisisReducer } = createSlice({
  name: 'itemChecklistApproveAnalisis',
  initialState,
  reducers: {
    saveItemChecklistApproveAnalisisStarted(
      state,
      { payload }: PayloadAction<SaveItemChecklistApproveAnalisiskPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveItemChecklistApproveAnalisisSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<ItemChecklistApproveAnalisis>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveItemChecklistApproveAnalisisFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveItemChecklistApproveAnalisisStarted,
  saveItemChecklistApproveAnalisisSuccess,
  saveItemChecklistApproveAnalisisFail
} = actions

export default itemChecklistApproveAnalisisReducer
