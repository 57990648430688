import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import { Upload } from 'upload/api/uploadApi'
import api from 'common/api/api'
import createApi from 'common/utils/createApi'
import createRequestError from 'shared/createRequestError'

export interface PaymentAttachment {
  id: string
  uploadId: string
  aprovado?: boolean
  obraId: string
  upload: Upload
  podeDeletar?: boolean
}
export interface PaymentDeleteOptions {
  obraId: string
  id: string
}

export type FetchPaymentAttachmentsOptions = PaginatedEndpointOptions<
  PaymentAttachment
>

export type FetchPaymentAttachmentResponse = PaginatedEndpointResponse<
  PaymentAttachment
>

export type SavePaymentAttachmentPayload = SaveResourcePayload<
  Omit<PaymentAttachment, 'upload'>
>

export async function fetchPaymentAttachments(
  options?: FetchPaymentAttachmentsOptions
) {
  try {
    const { data } = await api.get<FetchPaymentAttachmentResponse>(
      'ComprovantePagamento',
      {
        params: { ...options, include: 'Upload' }
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export async function deletePaymentAttachment(options: PaymentDeleteOptions) {
  try {
    const { data } = await api.delete<PaymentAttachment>(
      `ComprovantePagamento/${options.obraId}/${options.id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export const {
  getById: fetchSinglePaymentAttachment,
  save: savePaymentAttachment
} = createApi<Omit<PaymentAttachment, 'upload'>>('ComprovantePagamento')
