import { save } from 'common/api/apiHelper'

export interface ItemChecklistAnalisys {
  obraId: string
  mensagem: string
  aprovado: boolean
  uploadIds: string[]
}

export const saveItemChecklistAnalisys = (workId: string) =>
  save<ItemChecklistAnalisys>(`Obra/${workId}/AnalisarItensChecklist`)
