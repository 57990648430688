import React, { FC, useEffect } from 'react'
import useTypedSelector from 'common/hooks/useTypedSelector'
import { loadBlocksFromStreetStarted } from 'block/reducers/blockReducer'
import { useDispatch } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

export interface BlockProps {
  streetId: string
  selected?: any
  setSelected: (item?: any) => void
  errorStatus?: boolean
  onModal?: boolean
}

const BlockSelect: FC<BlockProps> = ({
  streetId,
  selected,
  setSelected,
  errorStatus,
  onModal
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setSelected()
    if (!streetId) return

    dispatch(loadBlocksFromStreetStarted(streetId))
  }, [streetId])

  const blocks = useTypedSelector(({ block }) => {
    if (!streetId) return []

    const blocks = block.byStreet[streetId]
    return blocks ? blocks.map(item => block.items[item]) : []
  })

  return (
    <Autocomplete
      disablePortal={onModal ? true : false}
      key={selected}
      value={selected || null}
      noOptionsText={'Não há quadras cadastrados para a rua selecionada'}
      disabled={!streetId}
      id='blockSelect'
      size='small'
      onChange={(event, newValue) => {
        setSelected(newValue || '')
      }}
      options={blocks}
      getOptionLabel={option => `${option?.nome}`}
      renderInput={params => (
        <TextField
          error={errorStatus}
          placeholder={
            !streetId
              ? 'Para selecionar uma quadra primeiro selecione uma rua'
              : 'Selecione uma quadra'
          }
          {...params}
          variant='outlined'
        />
      )}
    ></Autocomplete>
  )
}

export default BlockSelect
