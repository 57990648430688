import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import {
  ReviewProject,
  SaveReviewProjectPayload
} from 'project/api/reviewProjectApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface ReviewProjectState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ReviewProject | undefined }
}

const initialState: ReviewProjectState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: reviewProjectReducer } = createSlice({
  name: 'reviewProject',
  initialState,
  reducers: {
    saveReviewProjectStarted(
      state,
      { payload }: PayloadAction<SaveReviewProjectPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveReviewProjectSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<ReviewProject>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveReviewProjectFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveReviewProjectStarted,
  saveReviewProjectSuccess,
  saveReviewProjectFail
} = actions

export default reviewProjectReducer
