import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

/**
 * Pode ser uma Rua (condomínio horizontal)
 * ou um Bloco (condomínio vertical)
 */
export interface Street {
  id: string
  nome: string
  condominioId: string
}

export type FetchStreetsOptions = PaginatedEndpointOptions<Street>

export type FetchStreetResponse = PaginatedEndpointResponse<Street>

/**
 * Carrega lista de ruas/blocos paginadas e filtradas pela query.
 */
export async function fetchStreets(options?: FetchStreetsOptions) {
  try {
    const { data } = await api.get<FetchStreetResponse>('Rua', {
      params: options
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de ruas/blocos paginados e filtradas pela query.
 */
export async function fetchStreetsFromCondominium(condominiumId: string) {
  try {
    const endpoint = `/Condominio/${condominiumId}/ruas`

    const { data } = await api.get<Street[]>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega uma rua/bloco pelo id.
 */
export async function fetchStreet(id: string) {
  try {
    const { data } = await api.get<Street>(`Rua/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveStreetPayload = SaveResourcePayload<Street>

/**
 * Salva uma rua/bloco, se houver id altera o existente.
 */
export async function saveStreet(street: SaveStreetPayload) {
  try {
    const request = street.id ? api.put : api.post

    const { data } = await request<Street>('Rua', street)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma rua/blocos pelo id.
 */
export async function deleteStreet(id: string) {
  try {
    const { data } = await api.delete<Street>(`Rua/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
