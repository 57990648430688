import { WorkStartedIrregularly } from 'WorkStartedIrregularly/contracts/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { workStartedIrregularly } from 'WorkStartedIrregularly/contracts/queries'

export const workStartedIrregularlyThunk = createAsyncThunk(
  'workStartedIrregularly',
  async (data: WorkStartedIrregularly, { rejectWithValue }) => {
    try {
      const result = await workStartedIrregularly(data.obraId)(data)

      return result
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)
