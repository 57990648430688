import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ReactiveProject {
  id: string
  obraId: string
  mensagem?: string
}

export type SaveReactiveProjectPayload = SaveResourcePayload<ReactiveProject>

export const saveReactiveProject = save<ReactiveProject>(
  'Obra/ReativarProcesso'
)
