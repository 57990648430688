import { FetchStatesOptions, State } from 'state/api/stateApi'
import { Pagination, RequestError, ResourceStatus } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface StateState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  items: { [key: string]: State | undefined }
}

const initialState: StateState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */
const { actions, reducer: cityReducer } = createSlice({
  name: 'city',
  initialState,
  reducers: {
    loadStatesStarted(
      state,
      { payload }: PayloadAction<FetchStatesOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadStatesSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: State }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadStatesFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    }
  }
})

export const { loadStatesFail, loadStatesStarted, loadStatesSuccess } = actions

export default cityReducer
