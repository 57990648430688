import {
  AddRoutineExistingWork,
  SaveAddRoutineExistingWorkPayload
} from 'work/api/addRoutineExistingWorkApi'
import { Pagination, RequestError } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface AddRoutineExistingWorkState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: AddRoutineExistingWork | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: AddRoutineExistingWorkState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 25,
    total: 1
  }
}

const { actions, reducer: AddRoutineExistingWorkReducer } = createSlice({
  name: 'addRoutineExistingWork',
  initialState,
  reducers: {
    saveAddRoutineExistingWorkStarted(
      state,
      { payload }: PayloadAction<SaveAddRoutineExistingWorkPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveAddRoutineExistingWorkSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: AddRoutineExistingWork
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveAddRoutineExistingWorkFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})
/**
 * Cria key para acessar status e errors quando item é carregado por um relacionamento
 */
export const createByWorkKey = (workId: string) => `work.${workId}`

export const getNonCompliancesStatusByWork = (workId: string) =>
  createSelector(
    state => state.addRoutineExistingWork.status[createByWorkKey(workId)]
  )

export const {
  saveAddRoutineExistingWorkStarted,
  saveAddRoutineExistingWorkSuccess,
  saveAddRoutineExistingWorkFail
} = actions

export default AddRoutineExistingWorkReducer
