import {
  ItemsChecklistAnalisis,
  ItemsChecklistAnalisisStage,
  SaveCompletionWorkItemsPayload,
  SaveInitialWorkItemsPayload
} from 'ItemsChecklist/api/ItemsChecklistSubmitions'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface ItemsChecklistAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: {
    [key: string]:
      | (ItemsChecklistAnalisis & { stage: ItemsChecklistAnalisisStage })
      | undefined
  }
}

const initialState: ItemsChecklistAnalisisState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: itemsChecklistSubmitionReducer } = createSlice({
  name: 'itemsChecklistSubmition',
  initialState,
  reducers: {
    saveCompletionWorkItemsStarted(
      state,
      { payload }: PayloadAction<SaveCompletionWorkItemsPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveCompletionWorkItemsSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<ItemsChecklistAnalisis>>
    ) {
      const { forItem, result } = payload

      state.items[forItem] = { ...result, stage: 'Final' }
      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveCompletionWorkItemsFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    saveInitialWorkItemsStarted(
      state,
      { payload }: PayloadAction<SaveInitialWorkItemsPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveInitialWorkItemsSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<ItemsChecklistAnalisis>>
    ) {
      const { forItem, result } = payload

      state.items[forItem] = { ...result, stage: 'Initial' }
      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveInitialWorkItemsFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveCompletionWorkItemsStarted,
  saveCompletionWorkItemsSuccess,
  saveCompletionWorkItemsFail,
  saveInitialWorkItemsFail,
  saveInitialWorkItemsStarted,
  saveInitialWorkItemsSuccess
} = actions

export default itemsChecklistSubmitionReducer
