import {
  InhabitedWithReservations,
  saveInhabitedWithReservations
} from 'work/api/inhabitedWithReservations'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  saveInhabitedWithReservationsFail,
  saveInhabitedWithReservationsStarted,
  saveInhabitedWithReservationsSuccess
} from 'work/reducers/inhabitedWithReservations'

import { PayloadAction } from '@reduxjs/toolkit'

export function* handleSaveInhabitedWithReservations({
  payload
}: PayloadAction<InhabitedWithReservations>) {
  try {
    const response = yield call(saveInhabitedWithReservations, payload)

    return yield put(
      saveInhabitedWithReservationsSuccess({
        forItem: 'new',
        result: response
      })
    )
  } catch (error) {
    return yield put(
      saveInhabitedWithReservationsFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* InhabitedWithReservationsSaga() {
  yield takeLeading(
    saveInhabitedWithReservationsStarted.type,
    handleSaveInhabitedWithReservations
  )
}

export default InhabitedWithReservationsSaga
