import React, { FC, useEffect } from 'react'
import useTypedSelector from 'common/hooks/useTypedSelector'
import { loadStreetsFromCondominiumStarted } from 'street/reducers/streetReducer'
import { useDispatch } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

export interface StreetProps {
  condominiumId: string
  selected?: any
  setSelected: (item?: any) => void
  errorStatus?: boolean
  onModal?: boolean
}

const StreetSelect: FC<StreetProps> = ({
  condominiumId,
  selected,
  setSelected,
  errorStatus,
  onModal
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadStreetsFromCondominiumStarted(condominiumId))
  }, [condominiumId])

  const streets = useTypedSelector(({ street }) => {
    const streets = street.byCondominium[condominiumId]
    return streets ? streets.map(item => street.items[item]) : []
  })

  return (
    <>
      {streets && (
        <Autocomplete
          disablePortal={onModal ? true : false}
          style={{ position: 'relative' }}
          value={selected || null}
          id='streetSelect'
          size='small'
          onChange={(event, newValue) => {
            setSelected(newValue || '')
          }}
          options={streets}
          getOptionLabel={option => `${option?.nome}`}
          renderInput={params => (
            <TextField
              error={errorStatus}
              placeholder='Selecione uma rua'
              {...params}
              variant='outlined'
            />
          )}
        ></Autocomplete>
      )}
    </>
  )
}

export default StreetSelect
