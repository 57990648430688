import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'

import { RemoveCaveat } from 'removeCaveat/api/removeCaveat'
import { RootState } from 'store'
import { removeCaveatThunk } from 'removeCaveat/thunks/removeCaveat'

/**
 * Interfaces
 */

export interface RemoveCaveatState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

/**
 * Slice
 */

const RemoveCaveatAdapter = createEntityAdapter<RemoveCaveat>()

const { reducer } = createSlice({
  name: 'removeCaveat',
  initialState: RemoveCaveatAdapter.getInitialState<RemoveCaveatState>({
    status: {},
    errors: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(removeCaveatThunk.pending, (state, action) => {
      const value = action.meta.arg
      state.status[value.obraId] = 'Pending'
      state.errors[value.obraId] = undefined
    })

    builder.addCase(removeCaveatThunk.fulfilled, (state, action) => {
      const value = action.meta.arg
      RemoveCaveatAdapter.upsertOne(state, action.payload)
      state.status[value.obraId] = 'Done'
    })

    builder.addCase(removeCaveatThunk.rejected, (state, action) => {
      const value = action.meta.arg

      state.status[value.obraId] = 'Error'
      state.errors[value.obraId] = action.payload as any
    })
  }
})

export const selectStatus = (obraId: string) => {
  return ({ removeCaveat }: RootState) => removeCaveat.status[obraId]
}

export const selectError = (obraId: string) => {
  return ({ removeCaveat }: RootState) => removeCaveat.errors[obraId]
}

export default reducer
