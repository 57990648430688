import { Block, FetchBlocksOptions, SaveBlockPayload } from 'block/api/blockApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface BlockState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  byStreet: { [key: string]: string[] | undefined }
  items: { [key: string]: Block | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: BlockState = {
  items: {},
  pages: {},
  byStreet: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: blockReducer } = createSlice({
  name: 'block',
  initialState,
  reducers: {
    loadBlocksStarted(
      state,
      action: PayloadAction<FetchBlocksOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadBlocksSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Block }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadBlocksFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadBlocksFromStreetStarted(state, action: PayloadAction<string>) {
      const key = `street.${action.payload}`
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadBlocksFromStreetSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forStreet: string
        result: { [id: string]: Block }
      }>
    ) {
      const { result, forStreet } = payload
      const key = `street.${forStreet}`

      state.status[key] = 'Done'
      state.errors[key] = undefined

      state.byStreet[forStreet] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadBlocksFromStreetFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `street.${payload.forItem}`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadSingleBlockStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleBlockSuccess(state, { payload }: PayloadAction<Block>) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleBlockFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveBlockStarted(state, { payload }: PayloadAction<SaveBlockPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveBlockSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Block>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byStreet = state.byStreet[result.ruaId]

      if (byStreet) {
        !byStreet.includes(result.id) && byStreet.push(result.id)
      } else state.byStreet[result.ruaId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveBlockFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteBlockStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteBlockSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      return state
    },
    deleteBlockFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

export const getBlocksOnCurrentPage = createSelector(({ block }) => {
  const { pagination, pages } = block

  return pages[pagination.current] || []
})

export const getBlockById = (id: string) =>
  createSelector(({ block }) => block.items[id])

export const getBlockStatusById = (id: string) =>
  createSelector(({ block }) => block.status[id])

export const {
  loadBlocksStarted,
  loadBlocksSuccess,
  loadBlocksFail,
  loadBlocksFromStreetStarted,
  loadBlocksFromStreetSuccess,
  loadBlocksFromStreetFail,
  loadSingleBlockStarted,
  loadSingleBlockSuccess,
  loadSingleBlockFail,
  saveBlockStarted,
  saveBlockSuccess,
  saveBlockFail,
  deleteBlockStarted,
  deleteBlockSuccess,
  deleteBlockFail
} = actions

export default blockReducer
