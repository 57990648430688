import { FinishWork, SaveFinishWorkPayload } from 'work/api/finishWorkApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface FinishWorkState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: FinishWork | undefined }
}

const initialState: FinishWorkState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: finishWorkReducer } = createSlice({
  name: 'finishWork',
  initialState,
  reducers: {
    saveFinishWorkStarted(
      state,
      { payload }: PayloadAction<SaveFinishWorkPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveFinishWorkSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<FinishWork>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveFinishWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveFinishWorkStarted,
  saveFinishWorkSuccess,
  saveFinishWorkFail
} = actions

export default finishWorkReducer
