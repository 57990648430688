import 'common/assets/reset.css'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import 'moment/locale/pt-br'

import * as serviceWorker from './serviceWorker'

import {
  CSSReset,
  ThemeProvider as ChakraThemeProvider,
  theme as chakraTheme
} from '@chakra-ui/core'

import App from 'App'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { ReactQueryConfigProvider } from 'react-query'
import { ThemeProvider } from 'styled-components'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import setupYup from 'common/utils/setupYup'
import store from 'store'
import moment from 'moment'

setupYup()
library.add(fas)
moment.locale('pt-br')

const reactQueryConfig = {
  queries: {
    retry: 2,
    staleTime: 1_000 * 60 * 15,
    refetchOnWindowFocus: false
  }
}

ReactDOM.render(
  <ReactQueryConfigProvider config={reactQueryConfig}>
    <ThemeProvider theme={chakraTheme}>
      <ChakraThemeProvider>
        <CSSReset />

        <Provider store={store}>
          <App />
        </Provider>
      </ChakraThemeProvider>
    </ThemeProvider>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
