import { RootState } from 'store'

export type Selector<R> = (state: RootState) => R

/**
 * Apenas um helper para ajudar com tipagem do selector
 */
function createSelector<R>(selector: Selector<R>) {
  return selector
}

export default createSelector
