import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import { User } from 'user/api/userApi'
import api from 'common/api/api'
import createApi from 'common/utils/createApi'
import createRequestError from 'shared/createRequestError'

export interface UserLot {
  id: string
  autor: boolean
  donoAtual: boolean
  dataCriacao: Date
  pessoaId: string
  loteId: string
  pessoa?: User
}

export type FetchUserLotsOptions = PaginatedEndpointOptions<UserLot>

export type FetchUserLotResponse = PaginatedEndpointResponse<UserLot>

export type SaveUserLotPayload = SaveResourcePayload<UserLot>

export const {
  delete: deleteUserLot,
  getAll: fetchUserLots,
  getById: fetchSingleUserLot,
  save: saveUserLot
} = createApi<UserLot>('Lote/PessoaLote')

/**
 * Carrega lista de pessoas relacionadas a um lote filtradas pela query.
 */
export async function fetchUserLotsFromLot(lotId: string) {
  try {
    const endpoint = `Lote/PessoaLote`

    const { data } = await api.get<UserLot[]>(endpoint, {
      params: {
        LoteId: lotId
      }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
