import {
  ItemChecklistAnalisys,
  saveItemChecklistAnalisys
} from 'ItemsChecklist/api/ItemChecklistProcessAnalisys'
import { ResourceFailPayload, ResourceStatus } from 'types/global'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'

import { RootState } from 'store'

/**
 * Interfaces
 */

export interface ItemChecklistAnalisysState {
  status: Record<string, ResourceStatus>
  errors: Record<string, ResourceFailPayload | undefined>
}

/**
 * Thunks
 */

export const saveItemAnalisys = createAsyncThunk(
  'itemChecklistAnalisys/saveItemAnalisys',
  async (item: ItemChecklistAnalisys, { rejectWithValue }) => {
    try {
      return await saveItemChecklistAnalisys(item.obraId)(item)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/**
 * Slice
 */

const itemChecklistAnalisysAdapter = createEntityAdapter<
  ItemChecklistAnalisys
>()

const { reducer } = createSlice({
  name: 'process',
  initialState: itemChecklistAnalisysAdapter.getInitialState<
    ItemChecklistAnalisysState
  >({
    status: {},
    errors: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveItemAnalisys.pending, (state, action) => {
      const item = action.meta.arg
      state.status[item.obraId] = 'Pending'
      state.errors[item.obraId] = undefined
    })

    builder.addCase(saveItemAnalisys.fulfilled, (state, action) => {
      const item = action.meta.arg
      itemChecklistAnalisysAdapter.upsertOne(state, action.payload)
      state.status[item.obraId] = 'Done'
      state.errors[item.obraId] = undefined
    })

    builder.addCase(saveItemAnalisys.rejected, (state, action) => {
      const item = action.meta.arg
      state.status[item.obraId] = 'Error'
      state.errors[item.obraId] = action.payload as any
    })
  }
})

/**
 * Selectors
 */

export const createSelectStatusOfItemChecklistAnalisys = (id: string) => {
  return ({ itemChecklistAnalisys }: RootState) =>
    itemChecklistAnalisys.status[id]
}

export default reducer
