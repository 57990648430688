import {
  ItemsChecklistAnalisis,
  SaveCompletionWorkItemsPayload,
  SaveInitialWorkItemsPayload,
  saveCompletionWorkItems,
  saveInitialWorkItems
} from 'ItemsChecklist/api/itemsChecklistAnalisis'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveCompletionWorkItemsFail,
  saveCompletionWorkItemsStarted,
  saveCompletionWorkItemsSuccess,
  saveInitialWorkItemsFail,
  saveInitialWorkItemsStarted,
  saveInitialWorkItemsSuccess
} from 'ItemsChecklist/reducers/itemsChecklistAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const itemsChecklistAnalisisSchema = new schema.Entity<
  ItemsChecklistAnalisis
>('itemsChecklistAnalisis')

export function* handleSaveCompletionWorkItems({
  payload
}: PayloadAction<SaveCompletionWorkItemsPayload>) {
  try {
    const { obraId } = payload
    const response = yield call(saveCompletionWorkItems(obraId), payload)

    const { entities } = normalize(response, itemsChecklistAnalisisSchema)

    return yield put(
      saveCompletionWorkItemsSuccess({
        forItem: payload.id || 'new',
        result: entities.itemsChecklistAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveCompletionWorkItemsFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleSaveInitialWorkItems({
  payload
}: PayloadAction<SaveInitialWorkItemsPayload>) {
  try {
    const { obraId } = payload
    const response = yield call(saveInitialWorkItems(obraId), payload)

    const { entities } = normalize(response, itemsChecklistAnalisisSchema)

    return yield put(
      saveInitialWorkItemsSuccess({
        forItem: payload.id || 'new',
        result: entities.itemsChecklistAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveInitialWorkItemsFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* itemsChecklistAnalisisSaga() {
  yield takeLeading(
    saveCompletionWorkItemsStarted.type,
    handleSaveCompletionWorkItems
  )
  yield takeLeading(
    saveInitialWorkItemsStarted.type,
    handleSaveInitialWorkItems
  )
}

export default itemsChecklistAnalisisSaga
