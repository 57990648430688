import {
  FetchItemsTypeChecklistOptions,
  SaveItemTypeChecklistPayload,
  deleteItemTypeChecklist,
  fetchItemTypeChecklist,
  fetchItemsTypeChecklist,
  saveItemTypeChecklist
} from 'itemTypeChecklist/api/itemTypeChecklistApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteItemTypeChecklistFail,
  deleteItemTypeChecklistStarted,
  deleteItemTypeChecklistSuccess,
  fetchItemsTypeChecklistFail,
  fetchItemsTypeChecklistStarted,
  fetchItemsTypeChecklistSuccess,
  fetchSingleItemTypeChecklistFail,
  fetchSingleItemTypeChecklistStarted,
  fetchSingleItemTypeChecklistSuccess,
  saveItemTypeChecklistFail,
  saveItemTypeChecklistStarted,
  saveItemTypeChecklistSuccess
} from 'itemTypeChecklist/reducers/itemTypeChecklistReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const itemTypeChecklistSchema = new schema.Entity('itemTypeChecklist')

export function* handleFetchItemsTypeChecklist(
  action: PayloadAction<FetchItemsTypeChecklistOptions | undefined>
) {
  try {
    const response = yield call(fetchItemsTypeChecklist, action.payload)

    const { entities } = normalize(response.result, [itemTypeChecklistSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      fetchItemsTypeChecklistSuccess({
        result: entities.itemTypeChecklist || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchItemsTypeChecklistFail(error))
  }
}

export function* handleFetchSingleItemTypeChecklist(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchItemTypeChecklist, action.payload)
    const { entities } = normalize(response, itemTypeChecklistSchema)

    return yield put(
      fetchSingleItemTypeChecklistSuccess(entities.condominium[response.id])
    )
  } catch (error) {
    return yield put(
      fetchSingleItemTypeChecklistFail({
        id: action.payload,
        error
      })
    )
  }
}

export function* handleSaveItemTypeChecklist({
  payload
}: PayloadAction<SaveItemTypeChecklistPayload>) {
  try {
    const response = yield call(saveItemTypeChecklist, payload)

    const { entities } = normalize(response, itemTypeChecklistSchema)

    return yield put(
      saveItemTypeChecklistSuccess({
        forItem: payload.id || 'new',
        result: entities.itemTypeChecklist[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveItemTypeChecklistFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteItemTypeChecklist({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteItemTypeChecklist, payload)

    return yield put(deleteItemTypeChecklistSuccess(payload))
  } catch (error) {
    return yield put(
      deleteItemTypeChecklistFail({
        forItem: payload,
        error
      })
    )
  }
}

function* itemTypeChecklistSaga() {
  yield takeLatest(
    fetchItemsTypeChecklistStarted.type,
    handleFetchItemsTypeChecklist
  )
  yield takeLatest(
    fetchSingleItemTypeChecklistStarted.type,
    handleFetchSingleItemTypeChecklist
  )
  yield takeLeading(
    saveItemTypeChecklistStarted.type,
    handleSaveItemTypeChecklist
  )

  yield takeLeading(
    deleteItemTypeChecklistStarted.type,
    handleDeleteItemTypeChecklist
  )
}

export default itemTypeChecklistSaga
