import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface WorkInit {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveWorkInitPayload = SaveResourcePayload<WorkInit>

export const saveWorkInit = save<WorkInit>('Obra/IniciarObra')
