import * as yup from 'yup'

import { Panel, PanelBody, PanelHeader } from 'carbono/Panel'
import React, { FC, useEffect, useState } from 'react'
import { getErrorMessage, getErrorVisibility } from 'shared/formikHelpers'

import Button from 'carbono/Button'
import Divider from 'carbono/Divider'
import FormItem from 'carbono/Forms/FormItem'
import { SaveUserWorkPayload } from 'work/api/workApi'
import SelectUser from 'common/components/selects/SelectUser'
import { Stack } from '@chakra-ui/core'
import { User } from 'user/api/userApi'
import { UserWorkProfile } from 'user/api/userApi'
import { saveUserWorkStarted } from 'user/reducers/userWorkReducer'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import useMessage from 'common/hooks/useToastMessage'
import usePrevious from 'common/hooks/usePrevious'
import useRouter from 'use-react-router'
import useTypedSelector from 'common/hooks/useTypedSelector'

export interface BindLotOwnerToWorkProps {
  id: string
}

const validationSchema = yup.object({
  pessoaId: yup.string().required('Obrigatório')
})

const BindLotOwnerToWork: FC<BindLotOwnerToWorkProps> = ({ id }) => {
  const [currentUser, setCurrentUser] = useState<User>()
  const dispatch = useDispatch()

  const work = useTypedSelector(({ work }) => work.items['new'])

  const status = useTypedSelector(({ userWork }) => userWork.status['new'])
  const previousStatus = usePrevious(status)

  const { history } = useRouter()

  const onSave = (values: SaveUserWorkPayload) => {
    dispatch(saveUserWorkStarted(values))
  }

  const { handleSubmit, errors, setFieldValue, touched } = useFormik<
    SaveUserWorkPayload
  >({
    initialValues: {
      cargoObra: UserWorkProfile.Autor,
      pessoaId: '',
      obraId: id
    },
    enableReinitialize: true,
    onSubmit: onSave,
    validationSchema
  })

  useEffect(() => {
    if (status === 'Done' && previousStatus === 'Saving') {
      work && history.push(`/work/${work.id}`)
    }
  }, [status])

  useMessage('userWork', 'new')

  return (
    <Panel>
      <PanelHeader>Adicionar autor do projeto</PanelHeader>
      <PanelBody>
        <form onSubmit={handleSubmit}>
          <FormItem
            label='Pessoa'
            hasError={getErrorVisibility(errors.pessoaId, touched.pessoaId)}
            message={getErrorMessage(errors.pessoaId, touched.pessoaId)}
          >
            <SelectUser
              isSize='small'
              value={currentUser}
              onChange={u => {
                setCurrentUser(u)
                u && setFieldValue('pessoaId', u.id)
              }}
              placeholder='Digite o nome ou o CPF da pessoa'
            />
          </FormItem>

          <Divider />
          <Stack isInline align='center' justify='flex-end'>
            <Button
              icon='save'
              type='submit'
              variant='primary'
              isLoading={status === 'Saving'}
              disabled={status === 'Saving'}
            >
              Salvar
            </Button>
          </Stack>
        </form>
      </PanelBody>
    </Panel>
  )
}

export default BindLotOwnerToWork
