import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'
import store from 'store'

export interface Upload {
  id: string
  nomeOriginal: string
  extensao: string
  contentType: string
  url: string
  descricao: string
  uploadDate: string
  userId: string
  pessoaId: string
  pessoa?: {
    id: string
    nome: string
    email: string
    crea: string
    cau: string
    userName: string
  }
}

export interface StagingUpload {
  destinationPath: string
  descricao: string
  file: File
}

const createUploadBody = (file: StagingUpload) => {
  const body = new FormData()

  body.append('arquivo', file.file)
  body.append('subcaminho', file.destinationPath)

  return body
}

const createUploadHeaders = () => {
  const token = store.getState().auth.token

  return {
    Authorization: `bearer ${token}`,
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }
}

export async function saveStagingUpload(
  stagingFile: StagingUpload,
  config?: any
) {
  try {
    const body = createUploadBody(stagingFile)
    const headers = createUploadHeaders()
    const endpoint = '/Upload'
    const { data } = await api.post<Upload>(endpoint, body, {
      headers,
      ...config
    })
    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export async function deleteFile(id: string) {
  try {
    const endpoint = `/Upload/${id}`

    await api.delete(endpoint)
  } catch (error) {
    throw createRequestError(error)
  }
}
