import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface ReanalyzeProccess {
  obraId: string
  mensagem: string
}

export async function edit(data: ReanalyzeProccess) {
  try {
    const { data: response } = await api.put<ReanalyzeProccess>(
      'Obra/Reanalisar',
      data
    )

    return response
  } catch (error) {
    throw createRequestError(error)
  }
}
