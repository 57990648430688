import {
  FetchTypeRoutineOptions,
  TypeRoutine,
  SaveTypeRoutinePayload,
  ListRoutineByIdInWorkOptions
} from 'typeRoutine/api/typeRoutineApi'
import { Pagination, RequestError, ResourceFailPayload } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface TypeRoutineState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: TypeRoutine | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: TypeRoutineState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: typeRoutineReducer } = createSlice({
  name: 'typeRoutine',
  initialState,
  reducers: {
    fetchTypeRoutineStarted(
      state,
      action: PayloadAction<FetchTypeRoutineOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchTypeRoutineSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: TypeRoutine }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchTypeRoutineFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    fetchSingleTypeRoutineStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    fetchSingleTypeRoutineSuccess(
      state,
      { payload }: PayloadAction<TypeRoutine>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    fetchSingleTypeRoutineFail(
      state,
      { payload }: PayloadAction<{ id: string; error?: RequestError }>
    ) {
      state.status[payload.id] = 'Error'
      state.errors[payload.id] = payload.error

      return state
    },
    fetchSingleListRoutinebyIdInWorkStarted(
      state,
      { payload }: PayloadAction<ListRoutineByIdInWorkOptions>
    ) {
      state.errors[payload.id] = undefined
      state.status[payload.id] = 'Pending'

      return state
    },
    fetchSingleListRoutinebyIdInWorkSuccess(
      state,
      { payload }: PayloadAction<ListRoutineByIdInWorkOptions>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    fetchSingleListRoutinebyIdInWorkFail(
      state,
      { payload }: PayloadAction<{ id: string; error?: RequestError }>
    ) {
      state.status[payload.id] = 'Error'
      state.errors[payload.id] = payload.error

      return state
    },
    fetchTypeRoutineByNameAndTypeRoutineStarted(
      state,
      action: PayloadAction<FetchTypeRoutineOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchTypeRoutineByNameAndTypeRoutineSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: TypeRoutine }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchTypeRoutineByNameAndTypeRoutineFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    saveTypeRoutineStarted(
      state,
      { payload }: PayloadAction<SaveTypeRoutinePayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveTypeRoutineSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: TypeRoutine
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveTypeRoutineFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    editTypeRoutineStarted(
      state,
      { payload }: PayloadAction<SaveTypeRoutinePayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    editTypeRoutineSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: TypeRoutine
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    editTypeRoutineFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteTypeRoutineStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteTypeRoutineSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      for (const page in state.pages) {
        const items = state.pages[page]
        items && remove(items, payload)
      }

      return state
    },
    deleteTypeRoutineFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    deleteRoutineCondominiumStarted(state, { payload }: PayloadAction<string>) {
      const key = `typeRoutine.new`
      state.status[key] = 'Deleting'
      state.errors[key] = undefined

      return state
    },
    deleteRoutineCondominiumSuccess(state, { payload }: PayloadAction<string>) {
      const key = `typeRoutine.new`
      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.items[key] = undefined

      // remove qualquer referencias ao item
      for (const page in state.pages) {
        const items = state.pages[page]
        items && remove(items, key)
      }

      return state
    },
    deleteRoutineCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `typeRoutine.new`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    }
  }
})

export const getTypeRoutineById = (id: string) =>
  createSelector(({ typeRoutine }) => typeRoutine.items[id])

export const typeRoutineOnPage = createSelector(({ typeRoutine }) => {
  const { pagination, pages } = typeRoutine
  return pages[pagination.current] || []
})

export const getTypeRoutinePagination = createSelector(({ typeRoutine }) => {
  const { pagination } = typeRoutine

  return pagination
})
export const {
  fetchTypeRoutineFail,
  fetchTypeRoutineStarted,
  fetchTypeRoutineSuccess,
  fetchSingleTypeRoutineFail,
  fetchSingleTypeRoutineStarted,
  fetchSingleTypeRoutineSuccess,
  fetchSingleListRoutinebyIdInWorkSuccess,
  fetchSingleListRoutinebyIdInWorkStarted,
  fetchSingleListRoutinebyIdInWorkFail,
  fetchTypeRoutineByNameAndTypeRoutineStarted,
  fetchTypeRoutineByNameAndTypeRoutineSuccess,
  fetchTypeRoutineByNameAndTypeRoutineFail,
  saveTypeRoutineFail,
  saveTypeRoutineStarted,
  saveTypeRoutineSuccess,
  editTypeRoutineStarted,
  editTypeRoutineSuccess,
  editTypeRoutineFail,
  deleteTypeRoutineFail,
  deleteTypeRoutineStarted,
  deleteTypeRoutineSuccess,
  deleteRoutineCondominiumFail,
  deleteRoutineCondominiumStarted,
  deleteRoutineCondominiumSuccess
} = actions

export default typeRoutineReducer
