import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'

import { UserPermissionsOptions } from 'userPermissions/api/userPermissionApi'
import createSelector from 'shared/createSelector'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

// A chave de uma coleção é o id do condominio ao qual os itens estão associados
export interface UserPermissionsState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  byWork: string[] | undefined
  rules: string[] | undefined
}

/**
 * Store de permissoes
 */

const initialState: UserPermissionsState = {
  status: {},
  errors: {},
  byWork: undefined,
  rules: undefined
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: userPermissionsReducer } = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    loadUserPermissionsStarted(
      state,
      { payload }: PayloadAction<UserPermissionsOptions>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      state.rules = undefined

      return state
    },
    loadUserPermissionsSuccess(
      state,
      { payload }: PayloadAction<{ result: string[]; workId?: string }>
    ) {
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.rules = payload.result

      return state
    },
    loadUserPermissionsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      state.rules = undefined

      return state
    }
  }
})

/**
 * ==================================================
 * Selectors
 * ==================================================
 */

export const getRules = createSelector(
  ({ userPermissions }) => userPermissions.rules
)

export const {
  loadUserPermissionsFail,
  loadUserPermissionsStarted,
  loadUserPermissionsSuccess
} = actions

export default userPermissionsReducer
