import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export default function<T>(endpointResource: string) {
  return {
    async getAll(options?: PaginatedEndpointOptions<T>) {
      try {
        const { data } = await api.get<T>(endpointResource, {
          params: options
        })

        return data
      } catch (error) {
        throw createRequestError(error)
      }
    },
    async getById(id: string) {
      try {
        const { data } = await api.get<PaginatedEndpointResponse<T>>(
          `${endpointResource}/${id}`
        )

        return data
      } catch (error) {
        throw createRequestError(error)
      }
    },
    async save(entity: SaveResourcePayload<T & { id: string }>) {
      try {
        const request = entity.id ? api.put : api.post

        const { data } = await request<T>(endpointResource, entity)

        return data
      } catch (error) {
        throw createRequestError(error)
      }
    },

    async delete(id: string) {
      try {
        const { data } = await api.delete<T>(`${endpointResource}/${id}`)

        return data
      } catch (error) {
        throw createRequestError(error)
      }
    }
  }
}
