import { save } from 'common/api/apiHelper'

export interface FinishReanalysis {
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export const saveFinishReanalysis = (ObraId: string) =>
  save<FinishReanalysis>(`Obra/${ObraId}/FinalizarReanalise`)
