import {
  FetchStreetsOptions,
  SaveStreetPayload,
  Street
} from 'street/api/streetApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface StreetState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  byCondominium: { [key: string]: string[] | undefined }
  items: { [key: string]: Street | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: StreetState = {
  items: {},
  pages: {},
  byCondominium: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: streetReducer } = createSlice({
  name: 'street',
  initialState,
  reducers: {
    loadStreetsStarted(
      state,
      action: PayloadAction<FetchStreetsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadStreetsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Street }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadStreetsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadStreetsFromCondominiumStarted(state, action: PayloadAction<string>) {
      const key = `condominium.${action.payload}`
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadStreetsFromCondominiumSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forCondominium: string
        result: { [id: string]: Street }
      }>
    ) {
      const { result, forCondominium } = payload
      const key = `condominium.${forCondominium}`

      state.status[key] = 'Done'
      state.errors[key] = undefined

      state.byCondominium[forCondominium] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadStreetsFromCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `condominium.${payload.forItem}`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadSingleStreetStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleStreetSuccess(state, { payload }: PayloadAction<Street>) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleStreetFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveStreetStarted(state, { payload }: PayloadAction<SaveStreetPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveStreetSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Street>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byCondominium = state.byCondominium[result.condominioId]

      if (byCondominium) {
        !byCondominium.includes(result.id) && byCondominium.push(result.id)
      } else state.byCondominium[result.condominioId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveStreetFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteStreetStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteStreetSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      return state
    },
    deleteStreetFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

export const getStreetsOnCurrentPage = createSelector(({ street }) => {
  const { pagination, pages } = street

  return pages[pagination.current] || []
})

export const getStreetById = (id: string) =>
  createSelector(({ street }) => street.items[id])

export const getStreetStatusById = (id: string) =>
  createSelector(({ street }) => street.status[id])

export const {
  loadStreetsStarted,
  loadStreetsSuccess,
  loadStreetsFail,
  loadStreetsFromCondominiumStarted,
  loadStreetsFromCondominiumSuccess,
  loadStreetsFromCondominiumFail,
  loadSingleStreetStarted,
  loadSingleStreetSuccess,
  loadSingleStreetFail,
  saveStreetStarted,
  saveStreetSuccess,
  saveStreetFail,
  deleteStreetStarted,
  deleteStreetSuccess,
  deleteStreetFail
} = actions

export default streetReducer
