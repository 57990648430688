import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  color,
  compose,
  space,
  typography
} from 'styled-system'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export interface IconProps extends SpaceProps, ColorProps, TypographyProps {}

const Icon = styled(FontAwesomeIcon)<IconProps>`
  ${compose(space, color, typography)}
`

export default Icon
