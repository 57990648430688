import React, { FC } from 'react'

import { Box } from '@chakra-ui/core'
import Grid from './Grid'
import Icon from './Icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Link } from 'react-router-dom'
import { SpaceProps } from 'styled-system'

export interface Breadcrumb {
  title: string
  route?: string
  icon?: IconProp
}

/**
 * Breadcrumbs
 */
export interface BreadcrumbsProps extends SpaceProps {
  items: Breadcrumb[]
}

const Breadcrumbs: FC<BreadcrumbsProps> = React.memo(({ items, ...props }) => {
  const firstItems = [...items]
  const lastItem = firstItems.pop()
  const breadcrumbs = firstItems.map(item => (
    <BreadcrumbItem key={item.title} item={item} hasNext />
  ))

  if (!lastItem) return <></>

  return (
    <Box {...props}>
      <Grid mode='flex' alignItems='center'>
        {breadcrumbs}
        <BreadcrumbItem item={lastItem} />
      </Grid>
    </Box>
  )
})

/**
 * Breadcrumb items
 */
export interface BreadcrumbItemProps {
  item: Breadcrumb
  hasNext?: boolean
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = React.memo(
  ({ item, hasNext }) => {
    const node = (
      <Box style={item.route ? { cursor: 'pointer' } : undefined}>
        {item.icon && <Icon icon={item.icon} mr={2} />}
        {item.title}
        {hasNext && <Icon icon='chevron-right' mx={2} />}
      </Box>
    )

    return item.route ? <Link to={item.route}>{node}</Link> : node
  }
)

export default Breadcrumbs
