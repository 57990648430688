import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Breadcrumb } from 'carbono/Breadcrumbs'
import createSelector from 'shared/createSelector'

export enum AppLayout {
  Sidebar = 'Sidebar',
  Auth = 'Auth',
  Loading = 'Loading',
  Open = 'Open'
}

export interface LayoutState {
  pageTitle: string
  isSidebarCollapsed: boolean
  current: AppLayout
  breadcrumbs: Breadcrumb[]
}

const initialState: LayoutState = {
  pageTitle: '',
  current: AppLayout.Loading,
  isSidebarCollapsed: false,
  breadcrumbs: []
}

const { reducer, actions } = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    updatePageTitle(state, action: PayloadAction<string>) {
      state.pageTitle = action.payload

      return state
    },
    switchLayout(state, action: PayloadAction<AppLayout>) {
      state.current = action.payload

      return state
    },
    toogleSidebarCollapse(state) {
      state.isSidebarCollapsed = !state.isSidebarCollapsed

      return state
    },
    updateBreadcrumbs(state, { payload }: PayloadAction<Breadcrumb[]>) {
      state.breadcrumbs = payload

      return state
    },
    clearBreadcrumbs(state) {
      state.breadcrumbs = []

      return state
    }
  }
})

export const getBreadcrumbs = createSelector(state => state.layout.breadcrumbs)

export const {
  updatePageTitle,
  switchLayout,
  updateBreadcrumbs,
  clearBreadcrumbs
} = actions
export default reducer
