import { Image, PseudoBox } from '@chakra-ui/core'
import md5 from 'md5'
import React, { FC } from 'react'
import { BoxProps } from './Box'

export interface AvatarProps extends BoxProps {
  email: string
  size?: number | string
}

const Avatar: FC<AvatarProps> = ({ email, size, ...props }) => {
  const hash = md5(email)
  const imageSize = size || 48

  return (
    <PseudoBox
      {...(props as any)}
      width={imageSize}
      height={imageSize}
      textAlign='center'
      overflow='hidden'
      borderRadius='full'
    >
      <Image
        width='100%'
        objectFit='cover'
        src={`https://www.gravatar.com/avatar/${hash}?d=identicon&s=${imageSize}`}
        alt='Avatar'
      />
    </PseudoBox>
  )
}

export default Avatar
