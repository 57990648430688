import { useCallback, useRef } from 'react'

import reducerEntity from 'common/types/reducerEntity'
import { useToast } from '@chakra-ui/core'
import useTypedSelector from 'common/hooks/useTypedSelector'
import useWhenDone from 'common/hooks/useWhenDone'

const useToastMessage = (r: reducerEntity, entityId: string) => {
  const error = useTypedSelector(({ [r]: reducer }) => reducer.errors[entityId])
  const toast = useToast()
  const once = useRef(false)

  const getErrorTitle = useCallback(
    () =>
      error && error.name === 'RequestError'
        ? 'Falha ao salvar'
        : 'Falha de conexão',
    [error]
  )

  const getErrorDescription = useCallback(() => {
    let message = ''

    if (error && error.items !== undefined)
      for (const key in error.items) {
        message += error.items[key].join(' ')
      }

    return error && error.name === 'RequestError'
      ? message || 'Falha ao salvar'
      : 'Verifique sua internet.'
  }, [error])

  useWhenDone(r, entityId, () => {
    once.current = false
  })

  useWhenDone(
    r,
    entityId,
    () => {
      if (once.current) return
      else once.current = false

      toast({
        position: 'top-right',
        title: getErrorTitle(),
        description: getErrorDescription(),
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    },
    'Error'
  )

  useWhenDone(r, entityId, () => {
    if (once.current) return
    else once.current = false

    toast({
      position: 'top-right',
      title: 'Ação efetuada com sucesso',
      description: '',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  })
}

export default useToastMessage
