import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface InhabitedWithReservations {
  obraId: string
  mensagem: string
  uploadIds: string[]
}

export async function saveInhabitedWithReservations(
  obj: InhabitedWithReservations
) {
  try {
    const { data } = await api.put<InhabitedWithReservations>(
      `Obra/${obj.obraId}/HabitadaComRessalva`,
      obj
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
