import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface SubmitToAnalisis {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveSubmitToAnalisisPayload = SaveResourcePayload<SubmitToAnalisis>

export const saveSubmitToAnalisis = save<SubmitToAnalisis>(
  'Obra/projeto/submeterParaAnalise'
)
