import Axios from 'axios'
import { Credentials } from 'auth/reducers/authReducer'
import createRequestError from 'shared/createRequestError'
import qs from 'qs'

const authApi = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
})

export interface LoginResponse {
  token: string
  refreshToken: string
  isInvite?: boolean
}

export async function fetchAuthToken(credentials: Credentials) {
  try {
    const { data } = await authApi.post<LoginResponse>('Usuario/login', {
      ...credentials,
      grantType: 'password',
      isWeb: true
    })

    return data
  } catch (e) {
    throw createRequestError(e)
  }
}

export async function fetchRenewedAuthToken(refreshToken: string) {
  try {
    const { data } = await authApi.post<LoginResponse>('Usuario/login', {
      refreshToken,
      grantType: 'refresh_token'
    })

    return data
  } catch (e) {
    throw createRequestError(e)
  }
}
