import {
  FinishWork,
  SaveFinishWorkPayload,
  saveFinishWork
} from 'work/api/finishWorkApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveFinishWorkFail,
  saveFinishWorkStarted,
  saveFinishWorkSuccess
} from 'work/reducers/finishWorkReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const finishWorkSchema = new schema.Entity<FinishWork>('finishWork')

export function* handleSaveFinishWork({
  payload
}: PayloadAction<SaveFinishWorkPayload>) {
  try {
    const response = yield call(saveFinishWork, payload)

    const { entities } = normalize(response, finishWorkSchema)

    return yield put(
      saveFinishWorkSuccess({
        forItem: payload.id || 'new',
        result: entities.finishWork[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveFinishWorkFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* FinishWorkSaga() {
  yield takeLeading(saveFinishWorkStarted.type, handleSaveFinishWork)
}

export default FinishWorkSaga
