import { Icon, PseudoBox, Text } from '@chakra-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import css from '@styled-system/css'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

export interface MenuItemProps {
  title?: string
  icon?: IconProp
}
export const MenuItem: FC<MenuItemProps> = props => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onClick = () => {
    if (props.children) return setIsExpanded(!isExpanded)
  }

  return (
    <>
      <PseudoBox
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        px={3}
        py={2}
        color='gray.50'
        onClick={onClick}
        _hover={{ bg: 'gray.800' }}
        borderRadius='md'
      >
        {props.icon && <MenuIcon icon={props.icon} />}
        <Text flexGrow={1} ml={2}>
          {props.title}
        </Text>

        {props.children && (
          <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
        )}
      </PseudoBox>
      <MenuInnerContainer isExpanded={isExpanded}>
        {props.children}
      </MenuInnerContainer>
    </>
  )
}

export const MenuIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`
export const MenuInnerContainer = styled.div<{ isExpanded?: boolean }>`
  transition: all 0.3s ease-in;
  overflow: hidden;
  max-height: ${props => (props.isExpanded ? 'fit-content' : '0px')};
  ${css({
    ml: 3
  })}
`
