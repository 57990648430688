import {
  FetchUserLotsOptions,
  SaveUserLotPayload,
  UserLot,
  deleteUserLot,
  fetchSingleUserLot,
  fetchUserLots,
  fetchUserLotsFromLot,
  saveUserLot
} from 'userLot/api/userLotApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteUserLotFail,
  deleteUserLotStarted,
  deleteUserLotSuccess,
  loadSingleUserLotFail,
  loadSingleUserLotStarted,
  loadSingleUserLotSuccess,
  loadUserLotsFail,
  loadUserLotsFromLotFail,
  loadUserLotsFromLotStarted,
  loadUserLotsFromLotSuccess,
  loadUserLotsStarted,
  loadUserLotsSuccess,
  saveUserLotFail,
  saveUserLotStarted,
  saveUserLotSuccess
} from 'userLot/reducers/userLotReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const userLotSchema = new schema.Entity<UserLot>('userLot')

export function* handleLoadUserLots(
  action: PayloadAction<FetchUserLotsOptions | undefined>
) {
  try {
    const response = yield call(fetchUserLots, action.payload)

    const { entities } = normalize(response.result, [userLotSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      loadUserLotsSuccess({
        result: entities.userLot,
        pagination
      })
    )
  } catch (error) {
    return yield put(loadUserLotsFail(error))
  }
}

export function* handleLoadSingleUserLot(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchSingleUserLot, action.payload)
    const { entities } = normalize(response, userLotSchema)

    return yield put(loadSingleUserLotSuccess(entities.userLot[response.id]))
  } catch (error) {
    return yield put(
      loadSingleUserLotFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadUserLotsFromLot(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchUserLotsFromLot, action.payload)

    return yield put(
      loadUserLotsFromLotSuccess({
        result: response.result || [],
        forLot: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadUserLotsFromLotFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveUserLot({
  payload
}: PayloadAction<SaveUserLotPayload>) {
  try {
    const response = yield call(saveUserLot, payload)

    const { entities } = normalize(response, userLotSchema)

    return yield put(
      saveUserLotSuccess({
        forItem: payload.id || 'new',
        result: entities.userLot[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUserLotFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteUserLot({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteUserLot, payload)

    return yield put(deleteUserLotSuccess(payload))
  } catch (error) {
    return yield put(
      deleteUserLotFail({
        forItem: payload,
        error
      })
    )
  }
}

function* userLotSaga() {
  yield takeLatest(loadUserLotsStarted.type, handleLoadUserLots)
  yield takeLatest(loadSingleUserLotStarted.type, handleLoadSingleUserLot)
  yield takeLatest(loadUserLotsFromLotStarted.type, handleLoadUserLotsFromLot)
  yield takeLeading(saveUserLotStarted.type, handleSaveUserLot)
  yield takeLeading(deleteUserLotStarted.type, handleDeleteUserLot)
}

export default userLotSaga
