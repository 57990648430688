import { Content, SidebarLayout } from 'carbono/layout/SidebarLayout'
import React, { FC } from 'react'

import AppHeader from 'app/components/AppHeader'
import AppSidebar from './AppSidebar'

const AppSidebarLayout: FC = ({ children }) => {
  return (
    <SidebarLayout>
      <AppSidebar />
      <AppHeader />
      <Content>{children}</Content>
    </SidebarLayout>
  )
}

export default AppSidebarLayout
