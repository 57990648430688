import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ProjectApproval,
  SaveProjectApprovalPayload
} from 'project/api/projectApprovalApi'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface ProjectApprovalState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ProjectApproval | undefined }
}

const initialState: ProjectApprovalState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: projectApprovalReducer } = createSlice({
  name: 'projectApproval',
  initialState,
  reducers: {
    saveProjectApprovalStarted(
      state,
      { payload }: PayloadAction<SaveProjectApprovalPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveProjectApprovalSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<ProjectApproval>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveProjectApprovalFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  saveProjectApprovalStarted,
  saveProjectApprovalSuccess,
  saveProjectApprovalFail
} = actions

export default projectApprovalReducer
