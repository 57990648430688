import {
  FetchSupportMaterialsOptions,
  SaveSupportMaterialPayload,
  SupportMaterial,
  deleteSupportMaterial,
  fetchSingleSupportMaterial,
  fetchSupportMaterials,
  saveSupportMaterial
} from 'supportMaterial/api/supportMaterialApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteSupportMaterialFail,
  deleteSupportMaterialStarted,
  deleteSupportMaterialSuccess,
  loadSingleSupportMaterialFail,
  loadSingleSupportMaterialStarted,
  loadSingleSupportMaterialSuccess,
  loadSupportMaterialsFail,
  loadSupportMaterialsStarted,
  loadSupportMaterialsSuccess,
  saveSupportMaterialFail,
  saveSupportMaterialStarted,
  saveSupportMaterialSuccess
} from 'supportMaterial/reducers/supportMaterialReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const supportMaterialSchema = new schema.Entity<SupportMaterial>(
  'supportMaterial'
)

export function* handleLoadSupportMaterials(
  action: PayloadAction<FetchSupportMaterialsOptions | undefined>
) {
  try {
    const response = yield call(fetchSupportMaterials, action.payload)

    const { entities } = normalize(response.result, [supportMaterialSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadSupportMaterialsSuccess({
        result: entities.supportMaterial || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadSupportMaterialsFail(error))
  }
}

export function* handleLoadSingleSupportMaterial(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchSingleSupportMaterial, action.payload)
    const { entities } = normalize(response, supportMaterialSchema)

    return yield put(
      loadSingleSupportMaterialSuccess(entities.supportMaterial[response.id])
    )
  } catch (error) {
    return yield put(
      loadSingleSupportMaterialFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveSupportMaterial({
  payload
}: PayloadAction<SaveSupportMaterialPayload>) {
  try {
    const response = yield call(saveSupportMaterial, payload)

    const { entities } = normalize(response, supportMaterialSchema)

    return yield put(
      saveSupportMaterialSuccess({
        forItem: payload.id || 'new',
        result: entities.supportMaterial[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveSupportMaterialFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteSupportMaterial({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteSupportMaterial, payload)

    return yield put(deleteSupportMaterialSuccess(payload))
  } catch (error) {
    return yield put(
      deleteSupportMaterialFail({
        forItem: payload,
        error
      })
    )
  }
}

function* SupportMaterialSaga() {
  yield takeLatest(loadSupportMaterialsStarted.type, handleLoadSupportMaterials)
  yield takeLatest(
    loadSingleSupportMaterialStarted.type,
    handleLoadSingleSupportMaterial
  )
  yield takeLeading(saveSupportMaterialStarted.type, handleSaveSupportMaterial)
  yield takeLeading(
    deleteSupportMaterialStarted.type,
    handleDeleteSupportMaterial
  )
}

export default SupportMaterialSaga
