import {
  Event,
  saveNonComplianceEvent,
  SaveEventPayload
} from 'nonComplianceEvent/api/nonComplianceEventApi'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  saveEventFail,
  saveEventStarted,
  saveEventSuccess
} from 'nonComplianceEvent/reducers/nonComplianceEventReducer'
import { call, put, takeLeading } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'

export const eventSchema = new schema.Entity<Event>('event')

export function* handleSaveEvent({ payload }: PayloadAction<SaveEventPayload>) {
  try {
    const response = yield call(saveNonComplianceEvent, payload)

    const { entities } = normalize(response, eventSchema)

    return yield put(
      saveEventSuccess({
        forItem: 'new',
        result: entities.event[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveEventFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* eventSaga() {
  yield takeLeading(saveEventStarted.type, handleSaveEvent)
}

export default eventSaga
