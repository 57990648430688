import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ProjectApproval {
  id: string
  aprovado: boolean
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveProjectApprovalPayload = SaveResourcePayload<ProjectApproval>

export const saveProjectApproval = save<ProjectApproval>(
  'Obra/projeto/avaliarProjeto'
)
