import {
  Event,
  SaveEventPayload
} from 'nonComplianceEvent/api/nonComplianceEventApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestError } from 'types/global'

export interface EventState {
  status: { [key: string]: 'Pending' | 'Saving' | 'Done' | 'Error' }
  errors: { [key: string]: RequestError | undefined }
  pages: { [page: number]: string[] }
  items: { [key: string]: Event | undefined }
}

const initialState: EventState = {
  items: {},
  pages: {},
  status: {},
  errors: {}
}

const { actions, reducer: eventReducer } = createSlice({
  name: 'event',
  initialState,
  reducers: {
    saveEventStarted(state, { payload }: PayloadAction<SaveEventPayload>) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveEventSuccess(
      state,
      { payload }: PayloadAction<{ forItem: string | 'new'; result: Event }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveEventFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const { saveEventFail, saveEventStarted, saveEventSuccess } = actions

export default eventReducer
