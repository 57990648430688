import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import { Process, fetchProcessByWorkId } from 'process/api/process'
import { ResourceFailPayload, ResourceStatus } from 'types/global'

import { RootState } from 'store'

/**
 * Interfaces
 */

export interface ProcessState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<ResourceFailPayload>
}

/**
 * Thunks
 */

export const loadSingleProcess = createAsyncThunk(
  'process/loadSinglepProcess',
  async (workId: string, { rejectWithValue }) => {
    try {
      return await fetchProcessByWorkId(workId)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/**
 * Slice
 */

const processAdapter = createEntityAdapter<Process>()

const { reducer } = createSlice({
  name: 'process',
  initialState: processAdapter.getInitialState<ProcessState>({
    status: {},
    errors: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSingleProcess.pending, (state, action) => {
      const id = action.meta.arg
      state.status[id] = 'Pending'
      state.errors[id] = undefined
    })

    builder.addCase(loadSingleProcess.fulfilled, (state, action) => {
      const id = action.meta.arg
      processAdapter.upsertOne(state, action.payload)
      state.status[id] = 'Done'
      state.errors[id] = undefined
    })

    builder.addCase(loadSingleProcess.rejected, (state, action) => {
      const id = action.meta.arg
      state.status[id] = 'Error'
      state.errors[id] = action.payload as any
    })
  }
})

/**
 * Selectors
 */

const processSelectors = processAdapter.getSelectors()

export const createSelectProcessByWorkId = (workId: string) => {
  return ({ process }: RootState) =>
    processSelectors.selectAll(process).filter(p => workId === p.id)[0]
}

export const createSelectStatusOfLoadSingleProcess = (id: string) => {
  return ({ process }: RootState) => process.status[id]
}

export default reducer
