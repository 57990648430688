import { Rules } from 'userPermissions/api/userPermissionApi'
import { getRules } from 'userPermissions/reducers/userPermissionsReducer'
import useTypedSelector from 'common/hooks/useTypedSelector'

const usePermission = (rules: Rules | Rules[]) => {
  const userRules = useTypedSelector(getRules)

  if (Array.isArray(rules))
    return (
      userRules !== undefined && rules.some(rule => userRules.includes(rule))
    )

  return userRules !== undefined && userRules.includes(rules)
}

export default usePermission
