import React, { FC } from 'react'

import { Box } from '@chakra-ui/core'

const AppSidebar: FC = ({ children }) => {
  return (
    <Box width='100%' h='100%'>
      {children}
    </Box>
  )
}

export default AppSidebar
