import {
  ItemChecklistApproval,
  saveItemChecklistApproval
} from 'ItemsChecklist/api/ItemChecklistApprovalApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveItemChecklistApprovalFail,
  saveItemChecklistApprovalStarted,
  saveItemChecklistApprovalSuccess
} from 'ItemsChecklist/reducers/ItemChecklistApprovalReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const itemChecklistApprovalSchema = new schema.Entity(
  'itemChecklistApproval'
)

export function* handleSaveItemChecklistApproval({
  payload
}: PayloadAction<ItemChecklistApproval>) {
  try {
    const response = yield call(saveItemChecklistApproval, payload)

    normalize(response, itemChecklistApprovalSchema)

    return yield put(
      saveItemChecklistApprovalSuccess({
        forItem: 'new'
      })
    )
  } catch (error) {
    return yield put(
      saveItemChecklistApprovalFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* itemChecklistApprovalSaga() {
  yield takeLeading(
    saveItemChecklistApprovalStarted.type,
    handleSaveItemChecklistApproval
  )
}

export default itemChecklistApprovalSaga
