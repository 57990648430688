import { SaveResourcePayload } from 'types/global'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface WorkComment {
  id: string
  obraId: string
  itemChecklistId?: string
  itemChecklistUploadId?: string
  comprovantePagamentoId?: string
  projetoId?: string
  mensagem: string
}

export type SaveWorkCommentPayload = SaveResourcePayload<WorkComment>

/**
 * Adiciona um comentário a um item de checklist ou a um upload
 */
export async function saveWorkComment(workComment: SaveWorkCommentPayload) {
  try {
    const { data } = await api.post<WorkComment>(
      'Obra/adicionarComentario',
      workComment
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
