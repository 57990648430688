import {
  ReactiveProject,
  SaveReactiveProjectPayload,
  saveReactiveProject
} from 'project/api/reactivateProject'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveReactiveProjectFail,
  saveReactiveProjectStarted,
  saveReactiveProjectSuccess
} from 'project/reducers/reactiveProjectReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const reactiveProjectSchema = new schema.Entity<ReactiveProject>(
  'reactiveProject'
)

export function* handleSaveReactiveProject({
  payload
}: PayloadAction<SaveReactiveProjectPayload>) {
  try {
    const response = yield call(saveReactiveProject, payload)

    const { entities } = normalize(response, reactiveProjectSchema)

    return yield put(
      saveReactiveProjectSuccess({
        forItem: payload.id || 'new',
        result: entities.reactiveProject[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveReactiveProjectFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* reactiveProjectSaga() {
  yield takeLeading(saveReactiveProjectStarted.type, handleSaveReactiveProject)
}

export default reactiveProjectSaga
