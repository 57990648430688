import {
  AnalysisRealeaseLetter,
  SendRealeaseLetter,
  analysisRealeaseLetter,
  getReleaseLetter,
  sendRealeaseLetter
} from 'releaseLetter/api/releaseLetter'

import { Upload } from 'upload/api/uploadApi'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { saveUploadsSuccess } from 'upload/reducers/uploadReducer'

export const getReleaseLetterThunk = createAsyncThunk(
  'releaseLetter/get',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const data = await getReleaseLetter(id)
      const uploads = [] as Upload[]

      data.forEach(item => {
        item.cartasUpload.forEach(carta => {
          uploads.push(carta.upload)
        })
      })

      dispatch(saveUploadsSuccess(uploads))

      return data
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)

export const sendRealeaseLetterThunk = createAsyncThunk(
  'releaseLetter/send',
  async (payload: SendRealeaseLetter, { rejectWithValue }) => {
    try {
      return await sendRealeaseLetter(payload.obraId)(payload)
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)

export const analysisRealeaseLetterThunk = createAsyncThunk(
  'releaseLetter/analysis',
  async (
    {
      payload,
      obraId
    }: {
      obraId: string
      payload: AnalysisRealeaseLetter
    },
    { rejectWithValue }
  ) => {
    try {
      return await analysisRealeaseLetter(obraId)(payload)
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)
