import { fetchSingle, save } from 'common/api/apiHelper'

import { Upload } from 'upload/api/uploadApi'

export interface UploadLetter {
  id: string
  aprovado?: boolean
  homologado?: boolean
  upload: Upload
}

export interface RealeaseLetter {
  id: string
  obraId: string
  aprovada: string
  cartasUpload: UploadLetter[]
}

export interface SendRealeaseLetter {
  obraId: string
  mensagem: string
  uploadCartaId: string
}

export interface AnalysisRealeaseLetter {
  mensagem: string
  aprovado: boolean
  possuiResalva: boolean
  cartaLiberacaoId: string
  uploadCartaHomologadaId: string
}

export const getReleaseLetter = fetchSingle<RealeaseLetter[]>(
  (obraId: string) => `Obra/${obraId}/CartasLiberacao`
)

export const sendRealeaseLetter = (obraId: string) =>
  save<SendRealeaseLetter>(`Obra/${obraId}/EnviarCartaLiberacao`)

export const analysisRealeaseLetter = (obraId: string) =>
  save<AnalysisRealeaseLetter>(`Obra/${obraId}/AnalisarCartaLiberacao`)
