import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import { NonComplianceEvent } from 'nonComplianceEvent/api/nonComplianceEventApi'
import { NonComplianceType } from 'nonComplianceType/api/nonComplianceTypeApi'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'
import openApi from 'api/openApi'

export enum NonComplianceStatus {
  Opened = 'EmAberto',
  PendingCI = 'PendenteCI',
  PendingFine = 'PendenteMulta',
  RepeatFine = 'MultaRepete',
  WaitingApproval = 'PendenteSindico',
  WaitingInspection = 'PendenteVistoria',
  FineSended = 'MultaEnviada',
  CIfiled = 'CIProtocolada',
  Done = 'Encerrada'
}

/**
 * Não-conformidade
 */
export interface NonCompliance {
  id: string
  numero: number
  status: NonComplianceStatus
  observacao: string
  rvt: number
  tipoNaoConformidadeId: string
  tipoNaoConformidade?: NonComplianceType
  obraId: string
  eventosNaoConformidade?: NonComplianceEvent[]
}
/**
 * listagem rotinas por obra
 */
export interface ListRoutineWork {
  id: string
  nome: string
  numeroRotina: number
  ultimaVistoria: string
  rvt: number
  tipoRotina: number
  observacao: string
  tipoItemChecklistRotina?: [
    {
      id?: string
      nome?: string
      itemsChecklistRotina?: [
        {
          nome?: string
          status?: string | number
        }
      ]
    }
  ]
}

export type FetchNonCompliancesOptions = PaginatedEndpointOptions<NonCompliance>

export type FetchNonComplianceResponse = PaginatedEndpointResponse<
  NonCompliance
>

export type FetchListRoutineWorkOptions = PaginatedEndpointOptions<
  ListRoutineWork
>

export type FetchListRoutineWorkResponse = PaginatedEndpointResponse<
  ListRoutineWork
>

/**
 * Carrega lista de Não-Conformidades paginadas e filtradas pela query.
 */
export async function fetchNonCompliances(
  options?: FetchNonCompliancesOptions
) {
  try {
    const { data } = await api.get<FetchNonComplianceResponse>(
      'NaoConformidade',
      { params: options }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de Não-Conformidade de uma Obra
 */
export async function fetchNonCompliancesFromWork(obraId: string) {
  try {
    const endpoint = `/Obra/${obraId}/naoConformidades`

    const { data } = await api.get<NonCompliance[]>(endpoint, {
      params: {
        isMobile: false
      }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
/**
 * Carrega lista de Rotinas de uma Obra por Id
 */
export async function fetchListRoutineWork(obraId: string) {
  try {
    const endpoint = `/Rotina/BuscaRotinasPorObra/${obraId}`

    const { data } = await api.get<ListRoutineWork[]>(endpoint, {
      params: {
        isMobile: false
      }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
/**
 * Carrega uma Não-Conformidade pelo id.
 */
export async function fetchNonCompliance(id: string) {
  try {
    const { data } = await api.get<NonCompliance>(`NaoConformidade/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega uma Não-Conformidade pelo id.
 */
export async function fetOpenchNonCompliance(id: string) {
  try {
    const { data } = await openApi.get<NonCompliance>(`NaoConformidade/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveNonCompliancePayload = SaveResourcePayload<NonCompliance>

/**
 * Salva uma Salva uma Não-Conformidade, se houver id altera o existente.
 */
export async function saveNonCompliance(
  nonCompliance: SaveNonCompliancePayload
) {
  try {
    const request = nonCompliance.id ? api.put : api.post

    const { data } = await request<NonCompliance>(
      'NaoConformidade',
      nonCompliance
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma Não-Conformidade pelo id.
 */
export async function deleteNonCompliance(id: string) {
  try {
    const { data } = await api.delete<NonCompliance>(`NaoConformidade/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
