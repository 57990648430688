import {
  FetchEmailSendGridOptions,
  EmailSendGrid,
  SaveEmailSendGridPayload,
  //fetchEmailSendGrid,
  saveEmailSendGrid,
  fetchConfigSendGrid
} from 'condominium/api/ConfigSendGridApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  // deleteLotFail,
  // deleteLotStarted,
  // deleteLotSuccess,
  // loadLotsFail,
  loadEmailSendGridFromEmailTypeFail,
  loadEmailSendGridFromEmailTypeStarted,
  loadEmailSendGridFromEmailTypeSuccess,
  // loadLotsStarted,
  // loadLotsSuccess,
  // loadSingleLotFail,
  // loadSingleLotStarted,
  // loadSingleLotSuccess,
  saveEmailSendGridFail,
  saveEmailSendGridStarted,
  saveEmailSendGridSuccess
} from 'condominium/reducers/ConfigSendGridReducer'
import { normalize, schema } from 'normalizr'

//import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const emailSendGridSchema = new schema.Entity<EmailSendGrid>('email')

// export function* handleLoadEmailSendGrid(
//   action: PayloadAction<FetchEmailSendGridOptions | undefined>
// ) {
//   try {
//     const response = yield call(fetchEmailSendGrid, action.payload)

//     const { entities } = normalize(response.result, [lotSchema])

//     const pagination: Pagination = {
//       current: response.page,
//       total: response.totalItemCount
//     }

//     return yield put(
//       loadLotsSuccess({
//         result: entities.lot ?? [],
//         pagination
//       })
//     )
//   } catch (error) {
//     return yield put(loadLotsFail(error))
//   }
// }

export function* handleLoadEmailSendGridFromEmailType(
  action: PayloadAction<FetchEmailSendGridOptions>
) {
  try {
    const response = yield call(fetchConfigSendGrid, action.payload)

    const { entities } = normalize(response, [emailSendGridSchema])

    return yield put(
      loadEmailSendGridFromEmailTypeSuccess({
        result: entities.email,
        forEmailSendgrid: action.payload
      })
    )
  } catch (error) {
    return yield put(loadEmailSendGridFromEmailTypeFail(error))
  }
}

// export function* handleLoadSingleLot(action: PayloadAction<string>) {
//   try {
//     const response = yield call(fetchLot, action.payload)
//     const { entities } = normalize(response, lotSchema)

//     return yield put(loadSingleLotSuccess(entities.lot[response.id]))
//   } catch (error) {
//     return yield put(
//       loadSingleLotFail({
//         forItem: action.payload,
//         error
//       })
//     )
//   }
// }

export function* handleSaveEmailSendGrid({
  payload
}: PayloadAction<SaveEmailSendGridPayload>) {
  try {
    const response = yield call(saveEmailSendGrid, payload)

    const { entities } = normalize(response, emailSendGridSchema)

    return yield put(
      saveEmailSendGridSuccess({
        forItem: payload.id || 'new',
        result: entities.emailSendGrid[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveEmailSendGridFail({
        forItem: payload.id || 'new',
        error: error?.toString()
      })
    )
  }
}

// export function* handleDeleteLot({ payload }: PayloadAction<string>) {
//   try {
//     yield call(deleteLot, payload)

//     return yield put(deleteLotSuccess(payload))
//   } catch (error) {
//     return yield put(
//       deleteLotFail({
//         forItem: payload,
//         error
//       })
//     )
//   }
// }

function* emailSendGridSaga() {
  // yield takeLatest(loadLotsStarted.type, handleLoadLots)
  yield takeLatest(
    loadEmailSendGridFromEmailTypeStarted.type,
    handleLoadEmailSendGridFromEmailType
  )
  // yield takeLatest(loadSingleLotStarted.type, handleLoadSingleLot)
  yield takeLeading(saveEmailSendGridStarted.type, handleSaveEmailSendGrid)
  // yield takeLeading(deleteLotStarted.type, handleDeleteLot)
}

export default emailSendGridSaga
