import {
  ItemChecklistApproveAnalisis,
  SaveItemChecklistApproveAnalisiskPayload,
  saveItemChecklistApproveAnalisisk
} from 'ItemsChecklist/api/itemChecklistApproveAnalisisApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveItemChecklistApproveAnalisisFail,
  saveItemChecklistApproveAnalisisStarted,
  saveItemChecklistApproveAnalisisSuccess
} from 'ItemsChecklist/reducers/itemChecklistApproveAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const itemChecklistApproveAnalisisSchema = new schema.Entity<
  ItemChecklistApproveAnalisis
>('itemChecklistApproveAnalisis')

export function* handleSaveItemChecklistApproveAnalisis({
  payload
}: PayloadAction<SaveItemChecklistApproveAnalisiskPayload>) {
  try {
    const response = yield call(saveItemChecklistApproveAnalisisk, payload)

    const { entities } = normalize(response, itemChecklistApproveAnalisisSchema)

    return yield put(
      saveItemChecklistApproveAnalisisSuccess({
        forItem: payload.id || 'new',
        result: entities.itemChecklistApproveAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveItemChecklistApproveAnalisisFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* ItemChecklistApproveAnalisisSaga() {
  yield takeLeading(
    saveItemChecklistApproveAnalisisStarted.type,
    handleSaveItemChecklistApproveAnalisis
  )
}

export default ItemChecklistApproveAnalisisSaga
