import { ForgotPassword, ResetPassword } from 'user/api/userApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceFailPayload, ResourceStatus } from 'types/global'

export interface ForgotPasswordState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
}

const initialState: ForgotPasswordState = {
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: forgotPasswordReducer } = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    saveForgotPasswordStarted(
      state,
      { payload }: PayloadAction<ForgotPassword>
    ) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveForgotPasswordSuccess(state) {
      state.status['new'] = 'Done'
      state.errors['new'] = undefined

      return state
    },
    saveForgotPasswordFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    saveResetPasswordStarted(state, { payload }: PayloadAction<ResetPassword>) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveResetPasswordSuccess(state) {
      state.status['new'] = 'Done'
      state.errors['new'] = undefined

      return state
    },
    saveResetPasswordFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveForgotPasswordFail,
  saveForgotPasswordStarted,
  saveForgotPasswordSuccess,
  saveResetPasswordFail,
  saveResetPasswordStarted,
  saveResetPasswordSuccess
} = actions

export default forgotPasswordReducer
