import {
  RequestFinalSurvey,
  SaveRequestFinalSurveyPayload,
  saveRequestFinalSurvey
} from 'work/api/requestFinalSurveyApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveRequestFinalSurveyFail,
  saveRequestFinalSurveyStarted,
  saveRequestFinalSurveySuccess
} from 'work/reducers/requestFinalSurveyReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const requestFinalSurveySchema = new schema.Entity<RequestFinalSurvey>(
  'requestFinalSurvey'
)

export function* handleSaveRequestFinalSurvey({
  payload
}: PayloadAction<SaveRequestFinalSurveyPayload>) {
  try {
    const response = yield call(saveRequestFinalSurvey, payload)

    const { entities } = normalize(response, requestFinalSurveySchema)

    return yield put(
      saveRequestFinalSurveySuccess({
        forItem: payload.id || 'new',
        result: entities.requestFinalSurvey[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveRequestFinalSurveyFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* requestFinalSurveySaga() {
  yield takeLeading(
    saveRequestFinalSurveyStarted.type,
    handleSaveRequestFinalSurvey
  )
}

export default requestFinalSurveySaga
