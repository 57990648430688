import {
  Book,
  FetchWorksOptions,
  SaveWorkPayload,
  Work
} from 'work/api/workApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface WorkState {
  status: { [key: string]: ResourceStatus | undefined }
  statusBook: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  errorsBook: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  byCondominium: { [page: string]: string[] | undefined }
  items: { [key: string]: Work | undefined }
  book: { [key: string]: Book | undefined }
}

const initialState: WorkState = {
  items: {},
  pages: {},
  byCondominium: {},
  status: {},
  errors: {},
  statusBook: {},
  errorsBook: {},
  book: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: workReducer } = createSlice({
  name: 'work',
  initialState,
  reducers: {
    loadWorksStarted(
      state,
      { payload }: PayloadAction<FetchWorksOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadWorksSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Work }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadWorksFail(state, { payload }: PayloadAction<RequestError | undefined>) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadWorksFromCondominiumStarted(state, action: PayloadAction<string>) {
      const key = createByCondominiumKey(action.payload)
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadWorksFromCondominiumSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forCondominium: string
        result: { [id: string]: Work }
      }>
    ) {
      const { result, forCondominium } = payload
      const key = createByCondominiumKey(forCondominium)

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.byCondominium[forCondominium] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadWorksFromCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = createByCondominiumKey(payload.forItem)

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadBookStarted(state, action: PayloadAction<string>) {
      const key = createByCondominiumKey(action.payload)
      state.statusBook[key] = 'Pending'
      state.errorsBook[key] = undefined

      return state
    },
    loadBookSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: Book
      }>
    ) {
      const { result, forWork } = payload
      const key = createByCondominiumKey(forWork)

      state.statusBook[key] = 'Done'
      state.errorsBook[key] = undefined

      state.book[forWork] = result

      return state
    },
    loadBookFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const key = createByCondominiumKey(payload.forItem)

      state.statusBook[key] = 'Error'
      state.errorsBook[key] = payload.error

      return state
    },
    loadSingleWorkStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleWorkSuccess(state, { payload }: PayloadAction<Work>) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveWorkStarted(state, { payload }: PayloadAction<SaveWorkPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveWorkSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Work>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byBlock = state.byCondominium[result.condominioId]

      if (byBlock) {
        !byBlock.includes(result.id) && byBlock.push(result.id)
      } else state.byCondominium[result.condominioId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteWorkStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteWorkSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      Object.keys(state.byCondominium).forEach(page => {
        const items = state.byCondominium[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    deleteRoutineWorkStarted(state, { payload }: PayloadAction<string>) {
      const key = `routine`
      state.status[key] = 'Deleting'
      state.errors[key] = undefined

      return state
    },
    deleteRoutineWorkSuccess(state, { payload }: PayloadAction<string>) {
      const key = `routine`
      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.items[key] = undefined

      // remove qualquer referencias ao item
      for (const page in state.pages) {
        const items = state.pages[page]
        items && remove(items, key)
      }

      return state
    },
    deleteRoutineWorkFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `routine`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Selectors
 * ==================================================
 */

/**
 * Cria key para acessar status e errors quando item é carregado por um relacionamento
 */
export const createByCondominiumKey = (condominiumId: string) =>
  `condominium.${condominiumId}`

/**
 * Seleciona items na page atual
 */
export const getWorksOnCurrentPage = createSelector(({ work }) => {
  const { pagination, pages } = work

  return pages[pagination.current] || []
})

export const getWorkPagination = createSelector(({ work }) => {
  const { pagination } = work

  return pagination
})

export const getWorkById = (id: string) =>
  createSelector(({ work }) => work.items[id])

export const getWorkStatusById = (id: string) =>
  createSelector(({ work }) => work.status[id])

export const getWorksByCondominium = (condominiumId: string) =>
  createSelector(state => state.work.byCondominium[condominiumId])

export const getWorksStatusByCondominium = (condominiumId: string) =>
  createSelector(
    state => state.work.status[createByCondominiumKey(condominiumId)]
  )

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  loadWorksStarted,
  loadWorksSuccess,
  loadWorksFail,
  loadWorksFromCondominiumStarted,
  loadWorksFromCondominiumSuccess,
  loadWorksFromCondominiumFail,
  loadSingleWorkStarted,
  loadSingleWorkSuccess,
  loadSingleWorkFail,
  saveWorkStarted,
  saveWorkSuccess,
  saveWorkFail,
  deleteWorkStarted,
  deleteWorkSuccess,
  deleteWorkFail,
  deleteRoutineWorkStarted,
  deleteRoutineWorkSuccess,
  deleteRoutineWorkFail,
  loadBookFail,
  loadBookStarted,
  loadBookSuccess
} = actions

export default workReducer
