import Axios, { AxiosRequestConfig } from 'axios'

import awaitUntilTokenIsRenewed from './awaitUntilTokenIsRenewed'
import isTokenValid from 'shared/isTokenValid'
import qs from 'qs'
import store from 'store'

const addAuthorizationHeader = async (config: AxiosRequestConfig) => {
  const token = store.getState().auth.token
  const tokenPayload = store.getState().auth.tokenPayload
  const currentCondominium = store.getState().auth.currentCondominium

  if (!tokenPayload) return config

  if (!isTokenValid(tokenPayload)) {
    const newToken = await awaitUntilTokenIsRenewed()

    if (!newToken) return config
    config.headers['Authorization'] = `bearer ${newToken}`
    return config
  }

  config.headers['Authorization'] = `bearer ${token}`
  config.headers['X-CondominioId'] = currentCondominium

  return config
}

const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
})

api.interceptors.request.use(addAuthorizationHeader)

export default api
