import {
  FetchNonCompliancesOptions,
  NonCompliance,
  SaveNonCompliancePayload,
  deleteNonCompliance,
  fetOpenchNonCompliance,
  fetchNonCompliance,
  fetchNonCompliances,
  fetchNonCompliancesFromWork,
  fetchListRoutineWork,
  saveNonCompliance,
  ListRoutineWork
} from 'nonCompliance/api/nonComplianceApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteNonComplianceFail,
  deleteNonComplianceStarted,
  deleteNonComplianceSuccess,
  loadNonCompliancesFail,
  loadNonCompliancesFromWorkFail,
  loadNonCompliancesFromWorkStarted,
  loadNonCompliancesFromWorkSuccess,
  loadNonCompliancesStarted,
  loadNonCompliancesSuccess,
  loadListRoutineWorkStarted,
  loadListRoutineWorkSuccess,
  loadListRoutineWorkFail,
  loadOpenSingleNonComplianceFail,
  loadOpenSingleNonComplianceStarted,
  loadOpenSingleNonComplianceSuccess,
  loadSingleNonComplianceFail,
  loadSingleNonComplianceStarted,
  loadSingleNonComplianceSuccess,
  saveNonComplianceFail,
  saveNonComplianceStarted,
  saveNonComplianceSuccess
} from 'nonCompliance/reducers/nonComplianceReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const nonComplianceSchema = new schema.Entity<NonCompliance>(
  'nonCompliance'
)

export const listRoutineWorkSchema = new schema.Entity<ListRoutineWork>(
  'listRoutineWork'
)

export function* handleLoadNonCompliances(
  action: PayloadAction<FetchNonCompliancesOptions | undefined>
) {
  try {
    const response = yield call(fetchNonCompliances, action.payload)

    const { entities } = normalize(response.result, [nonComplianceSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      loadNonCompliancesSuccess({
        result: entities.nonCompliance || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadNonCompliancesFail(error))
  }
}

export function* handleLoadNonCompliancesFromWork(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchNonCompliancesFromWork, action.payload)

    const { entities } = normalize(response, [nonComplianceSchema])

    return yield put(
      loadNonCompliancesFromWorkSuccess({
        result: entities.nonCompliance || [],
        forWork: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadNonCompliancesFromWorkFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleListRoutineWork(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchListRoutineWork, action.payload)

    const { entities } = normalize(response, [listRoutineWorkSchema])

    return yield put(
      loadListRoutineWorkSuccess({
        result: entities.listRoutineWork || [],
        forWork: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadListRoutineWorkFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleNonCompliance(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchNonCompliance, action.payload)
    const { entities } = normalize(response, nonComplianceSchema)

    return yield put(
      loadSingleNonComplianceSuccess(entities.nonCompliance[response.id])
    )
  } catch (error) {
    return yield put(
      loadSingleNonComplianceFail({
        forItem: action.payload,
        error
      })
    )
  }
}

// TODO(Fábio): solução temporária!!
export function* handleOpenLoadSingleNonCompliance(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetOpenchNonCompliance, action.payload)
    const { entities } = normalize(response, nonComplianceSchema)

    return yield put(
      loadOpenSingleNonComplianceSuccess(entities.nonCompliance[response.id])
    )
  } catch (error) {
    return yield put(
      loadOpenSingleNonComplianceFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveNonCompliance({
  payload
}: PayloadAction<SaveNonCompliancePayload>) {
  try {
    const response = yield call(saveNonCompliance, payload)

    const { entities } = normalize(response, nonComplianceSchema)

    return yield put(
      saveNonComplianceSuccess({
        forItem: payload.id || 'new',
        result: entities.nonCompliance[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveNonComplianceFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteNonCompliance({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteNonCompliance, payload)

    return yield put(deleteNonComplianceSuccess(payload))
  } catch (error) {
    return yield put(
      deleteNonComplianceFail({
        forItem: payload,
        error
      })
    )
  }
}

function* nonComplianceSaga() {
  yield takeLatest(loadNonCompliancesStarted.type, handleLoadNonCompliances)
  yield takeLatest(
    loadNonCompliancesFromWorkStarted.type,
    handleLoadNonCompliancesFromWork
  )
  yield takeLatest(loadListRoutineWorkStarted.type, handleListRoutineWork)
  yield takeLatest(
    loadSingleNonComplianceStarted.type,
    handleLoadSingleNonCompliance
  )
  yield takeLatest(
    loadOpenSingleNonComplianceStarted.type,
    handleOpenLoadSingleNonCompliance
  )
  yield takeLeading(saveNonComplianceStarted.type, handleSaveNonCompliance)
  yield takeLeading(deleteNonComplianceStarted.type, handleDeleteNonCompliance)
}

export default nonComplianceSaga
