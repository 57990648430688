import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface PaymentAttachmentAnalisis {
  id: string
  obraId: string
  comprovanteId: string
  aprovado: boolean
  mensagem?: string
  uploadIds: string[]
  podeDeletar?: boolean
}

export type SavePaymentAttachmentAnalisisPayload = SaveResourcePayload<
  PaymentAttachmentAnalisis
>

export const savePaymentAttachmentAnalisis = save<PaymentAttachmentAnalisis>(
  'Obra/avaliarComprovante'
)
