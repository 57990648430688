import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

/**
 * Pode ser uma Quadra (condomínio horizontal)
 * ou um Pavimento (condomínio vertical)
 */
export interface Lot {
  id: string
  numero: string
  complemento?: string
  quadraId: string
  ruaId?: string
  ruaNome?: string
  condominioId: string
  quadraNome: string
  area: string
  proprietarios: {
    pessoaId: string
    pessoaNome: string
    donoAtual: boolean
    autor: boolean
  }[]
  autoresProjeto: {
    pessoaId: string
    pessoaNome: string
    donoAtual: boolean
    autor: boolean
  }[]
}

export type FetchLotsOptions = PaginatedEndpointOptions<
  Lot & { proprietarioNome: string; autorNome: string }
>
export type FetchLotResponse = PaginatedEndpointResponse<Lot>

/**
 * Carrega lista de Lote/Sala/Apartamento paginados e filtradas pela query.
 */
export async function fetchLots(options?: FetchLotsOptions) {
  try {
    const { data } = await api.get<FetchLotResponse>('Lote', {
      params: options
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de Lote/Sala/Apartamento paginadas e filtradas pela query.
 */
export async function fetchLotsFromBlock(blockId: string) {
  try {
    const endpoint = `/Quadra/${blockId}/lotes`

    const { data } = await api.get<Lot[]>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega uma Lote/Sala/Apartamento pelo id.
 */
export async function fetchLot(id: string) {
  try {
    const { data } = await api.get<Lot>(`Lote/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveLotPayload = SaveResourcePayload<
  Omit<Lot, 'condominioId' | 'proprietarios' | 'autoresProjeto' | 'quadraNome'>
>

/**
 * Salva uma Lote/Sala/Apartamento, se houver id altera o existente.
 */
export async function saveLot(Lot: SaveLotPayload) {
  try {
    const request = Lot.id ? api.put : api.post

    const { data } = await request<Lot>('Lote', Lot)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma Lote/Sala/Apartamento pelo id.
 */
export async function deleteLot(id: string) {
  try {
    const { data } = await api.delete<Lot>(`Lote/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
