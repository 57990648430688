import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FetchPaymentAttachmentsOptions,
  PaymentAttachment,
  PaymentDeleteOptions,
  SavePaymentAttachmentPayload
} from 'paymentAttachment/api/paymentAttachmentApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do comprovante de pagamento
 */
export interface PaymentAttachmentState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: PaymentAttachment | undefined }
  byWork: { [page: string]: PaymentAttachment[] | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: PaymentAttachmentState = {
  items: {},
  pages: {},
  status: {},
  byWork: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: paymentAttachmentReducer } = createSlice({
  name: 'paymentAttachment',
  initialState,
  reducers: {
    loadPaymentAttachmentStarted(
      state,
      action: PayloadAction<FetchPaymentAttachmentsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadPaymentAttachmentSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: PaymentAttachment }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadPaymentAttachmentFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadSinglePaymentAttachmentStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSinglePaymentAttachmentSuccess(
      state,
      { payload }: PayloadAction<PaymentAttachment>
    ) {
      state.status[payload.obraId] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSinglePaymentAttachmentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    savePaymentAttachmentStarted(
      state,
      { payload }: PayloadAction<SavePaymentAttachmentPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    savePaymentAttachmentSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<PaymentAttachment>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    savePaymentAttachmentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    loadPaymentAttachmentFromWorkStarted(state, action: PayloadAction<string>) {
      const key = `work.${action.payload}`
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadPaymentAttachmentFromWorkSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: PaymentAttachment[]
      }>
    ) {
      const { result, forWork } = payload
      const key = `Work.${forWork}`

      const paymentAttachment = result

      state.status[key] = 'Done'
      state.errors[key] = undefined

      state.byWork[forWork] = paymentAttachment

      if (paymentAttachment !== undefined) {
        // const item = { paymentAttachment: paymentAttachment }

        state.items = {
          ...state.items
        }
      }

      return state
    },
    loadPaymentAttachmentFromWorkFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `Work.${payload.forItem}`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    deletePaymentAttachmentStarted(
      state,
      { payload }: PayloadAction<PaymentDeleteOptions>
    ) {
      state.status['upload.new'] = 'Deleting'
      state.errors['upload.new'] = undefined

      return state
    },
    deletePaymentAttachmentSuccess(state, { payload }: PayloadAction<string>) {
      state.status['upload.new'] = 'Done'
      state.errors['upload.new'] = undefined
      state.items['upload.new'] = undefined

      return state
    },
    deletePaymentAttachmentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status['upload.new'] = 'Error'
      state.errors['upload.new'] = payload.error

      return state
    }
  }
})

export const {
  deletePaymentAttachmentFail,
  deletePaymentAttachmentStarted,
  deletePaymentAttachmentSuccess,
  loadPaymentAttachmentFail,
  loadPaymentAttachmentStarted,
  loadPaymentAttachmentSuccess,
  loadSinglePaymentAttachmentFail,
  loadSinglePaymentAttachmentStarted,
  loadSinglePaymentAttachmentSuccess,
  savePaymentAttachmentFail,
  savePaymentAttachmentStarted,
  savePaymentAttachmentSuccess,
  loadPaymentAttachmentFromWorkFail,
  loadPaymentAttachmentFromWorkStarted,
  loadPaymentAttachmentFromWorkSuccess
} = actions

export default paymentAttachmentReducer
