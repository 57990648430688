import {
  City,
  FetchCitiesOptions,
  fetchCities,
  fetchCitiesByStateId
} from 'city/api/cityApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  loadCitiesByStateFail,
  loadCitiesByStateStarted,
  loadCitiesByStateSuccess,
  loadCitiesFail,
  loadCitiesStarted,
  loadCitiesSuccess
} from 'city/reducers/cityReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const citySchema = new schema.Entity<City>('city')

export function* handleLoadCities(
  action: PayloadAction<FetchCitiesOptions | undefined>
) {
  try {
    const response = yield call(fetchCities, action.payload)

    const { entities } = normalize(response.result, [citySchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadCitiesSuccess({
        result: entities.city || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadCitiesFail(error))
  }
}

export function* handleLoadCitiesByState(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchCitiesByStateId, action.payload)
    const { entities } = normalize(response, [citySchema])

    return yield put(
      loadCitiesByStateSuccess({
        forState: action.payload,
        result: entities.city || []
      })
    )
  } catch (error) {
    return yield put(
      loadCitiesByStateFail({
        forItem: action.payload,
        error
      })
    )
  }
}

function* CitySaga() {
  yield takeLatest(loadCitiesStarted.type, handleLoadCities)
  yield takeLatest(loadCitiesByStateStarted.type, handleLoadCitiesByState)
}

export default CitySaga
