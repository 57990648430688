import { save } from 'common/api/apiHelper'

export interface InitReanalysis {
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export const saveInitReanalysis = (ObraId: string) =>
  save<InitReanalysis>(`Obra/${ObraId}/IniciarReanalise`)
