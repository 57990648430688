import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import {
  SaveSubmitToAnalisisPayload,
  SubmitToAnalisis
} from 'project/api/submitToAnalisisApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface SubmitToAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: SubmitToAnalisis | undefined }
}

const initialState: SubmitToAnalisisState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: submitToAnalisisReducer } = createSlice({
  name: 'submitToAnalisis',
  initialState,
  reducers: {
    saveSubmitToAnalisisStarted(
      state,
      { payload }: PayloadAction<SaveSubmitToAnalisisPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveSubmitToAnalisisSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<SubmitToAnalisis>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveSubmitToAnalisisFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveSubmitToAnalisisStarted,
  saveSubmitToAnalisisSuccess,
  saveSubmitToAnalisisFail
} = actions

export default submitToAnalisisReducer
