import { Dictionary, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StagingUpload, Upload } from 'upload/api/uploadApi'

import { RequestError } from 'types/global'
import { RootState } from 'store'

/**
 * Store de Upload
 */

export interface UploadState {
  status: { [key: string]: 'Pending' | 'Saving' | 'Done' | 'Error' }
  errors: { [key: string]: RequestError | undefined }
  pages: { [page: number]: string[] }
  items: { [key: string]: Upload | undefined }
}

const initialState: UploadState = {
  items: {},
  pages: {},
  status: {},
  errors: {}
}

//=================================================
// Slice
//=================================================

const { actions, reducer: uploadReducer } = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    saveUploadStarted(state, { payload }: PayloadAction<StagingUpload>) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveUploadSuccess(
      state,
      { payload }: PayloadAction<{ forItem: string | 'new'; result: Upload }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveUploadsSuccess(state, { payload }: PayloadAction<Upload[]>) {
      payload.forEach(upload => {
        state.status[upload.id] = 'Done'
        state.errors[upload.id] = undefined
        state.items[upload.id] = upload
      })

      return state
    },
    saveUploadFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    addIncludedUploads(state, { payload }: PayloadAction<Dictionary<Upload>>) {
      state.items = { ...state.items, ...payload }
    }
  }
})

//=================================================
// Actions
//=================================================

export const {
  saveUploadFail,
  saveUploadStarted,
  saveUploadSuccess,
  saveUploadsSuccess,
  addIncludedUploads
} = actions

//=================================================
// Selectors
//=================================================

export const createSelectUploadById = (id: string) => {
  return (state: RootState) => state.upload.items[id]
}

export const createSelectUploadsByIds = (ids: string[]) => {
  return (state: RootState) => {
    return ids
      .map(id => state.upload.items[id])
      .filter(item => item !== undefined) as Upload[]
  }
}

export default uploadReducer
