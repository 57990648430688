import {
  FetchStreetsOptions,
  SaveStreetPayload,
  Street,
  deleteStreet,
  fetchStreet,
  fetchStreets,
  fetchStreetsFromCondominium,
  saveStreet
} from 'street/api/streetApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteStreetFail,
  deleteStreetStarted,
  deleteStreetSuccess,
  loadSingleStreetFail,
  loadSingleStreetStarted,
  loadSingleStreetSuccess,
  loadStreetsFail,
  loadStreetsFromCondominiumFail,
  loadStreetsFromCondominiumStarted,
  loadStreetsFromCondominiumSuccess,
  loadStreetsStarted,
  loadStreetsSuccess,
  saveStreetFail,
  saveStreetStarted,
  saveStreetSuccess
} from 'street/reducers/streetReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const streetSchema = new schema.Entity<Street>('street')

export function* handleLoadStreets(
  action: PayloadAction<FetchStreetsOptions | undefined>
) {
  try {
    const response = yield call(fetchStreets, action.payload)

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    const { entities } = normalize(response.result, [streetSchema])

    return yield put(
      loadStreetsSuccess({
        result: entities.street || {},
        pagination
      })
    )
  } catch (error) {
    return yield put(loadStreetsFail(error))
  }
}

export function* handleLoadStreetsFromCondominium(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchStreetsFromCondominium, action.payload)

    const { entities } = normalize(response, [streetSchema])

    return yield put(
      loadStreetsFromCondominiumSuccess({
        result: entities.street || {},
        forCondominium: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadStreetsFromCondominiumFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleStreet(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchStreet, action.payload)
    const { entities } = normalize(response, streetSchema)

    return yield put(loadSingleStreetSuccess(entities.street[response.id]))
  } catch (error) {
    return yield put(
      loadSingleStreetFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveStreet({
  payload
}: PayloadAction<SaveStreetPayload>) {
  try {
    const response = yield call(saveStreet, payload)

    const { entities } = normalize(response, streetSchema)

    return yield put(
      saveStreetSuccess({
        forItem: payload.id || 'new',
        result: entities.street[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveStreetFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteStreet({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteStreet, payload)

    return yield put(deleteStreetSuccess(payload))
  } catch (error) {
    return yield put(
      deleteStreetFail({
        forItem: payload,
        error
      })
    )
  }
}

function* streetSaga() {
  yield takeLatest(loadStreetsStarted.type, handleLoadStreets)
  yield takeLatest(
    loadStreetsFromCondominiumStarted.type,
    handleLoadStreetsFromCondominium
  )
  yield takeLatest(loadSingleStreetStarted.type, handleLoadSingleStreet)
  yield takeLeading(saveStreetStarted.type, handleSaveStreet)
  yield takeLeading(deleteStreetStarted.type, handleDeleteStreet)
}

export default streetSaga
