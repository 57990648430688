import { FetchStatesOptions, State, fetchStates } from 'state/api/stateApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  loadStatesFail,
  loadStatesStarted,
  loadStatesSuccess
} from 'state/reducers/stateReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const stateSchema = new schema.Entity<State>('state')

export function* handleLoadStates(
  action: PayloadAction<FetchStatesOptions | undefined>
) {
  try {
    const response = yield call(fetchStates, action.payload)

    const { entities } = normalize(response.result, [stateSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadStatesSuccess({
        result: entities.state || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadStatesFail(error))
  }
}

function* StateSaga() {
  yield takeLatest(loadStatesStarted.type, handleLoadStates)
}

export default StateSaga
