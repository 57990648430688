import {
  WorkEvent,
  WorkEventOptions,
  fetchEvents,
  fetchWorkEvents
} from 'workEvent/api/workEventApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  loadWorksEventByFilterStarted,
  loadWorksEventFail,
  loadWorksEventStarted,
  loadWorksEventSuccess
} from 'workEvent/reducer/workEventReducer'
import { normalize, schema } from 'normalizr'

import { PayloadAction } from '@reduxjs/toolkit'

export const workEventSchema = new schema.Entity<WorkEvent>('workEvent')

export function* handleLoadWorksEvent(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchWorkEvents, action.payload)

    const { entities } = normalize(response, [workEventSchema])

    return yield put(
      loadWorksEventSuccess({
        result: entities.workEvent || [],
        forWork: action.payload
      })
    )
  } catch (error) {
    return yield put(loadWorksEventFail(error))
  }
}

export function* handleLoadWorksEventByFilter(
  action: PayloadAction<WorkEventOptions | undefined>
) {
  try {
    const response = yield call(fetchEvents, action.payload)

    const { entities } = normalize(response.result, [workEventSchema])

    return yield put(
      loadWorksEventSuccess({
        result: entities.workEvent || [],
        forWork: action.payload?.obraId ?? 'new'
      })
    )
  } catch (error) {
    return yield put(loadWorksEventFail(error))
  }
}

function* workEventSaga() {
  yield takeLatest(loadWorksEventStarted.type, handleLoadWorksEvent)
  yield takeLatest(
    loadWorksEventByFilterStarted.type,
    handleLoadWorksEventByFilter
  )
}

export default workEventSaga
