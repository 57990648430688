import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import {
  InitReanalysis,
  saveInitReanalysis
} from 'reanalysis/api/initReanalysis'

import { ResourceStatus } from 'types/global'
import { RootState } from 'store'

/**
 * Interfaces
 */

export interface InitReanalysisState {
  status: Dictionary<ResourceStatus>
}

/**
 * Thunks
 */

export const loadInitReanalysis = createAsyncThunk(
  'initReanalysis/loadInitReanalysis',
  async (value: InitReanalysis, { rejectWithValue }) => {
    try {
      return await saveInitReanalysis(value.obraId)(value)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

/**
 * Slice
 */

const initReanalysisAdapter = createEntityAdapter<InitReanalysis>()

const { reducer } = createSlice({
  name: 'InitReanalysis',
  initialState: initReanalysisAdapter.getInitialState<InitReanalysisState>({
    status: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadInitReanalysis.pending, (state, action) => {
      const value = action.meta.arg
      state.status[value.obraId] = 'Pending'
    })

    builder.addCase(loadInitReanalysis.fulfilled, (state, action) => {
      const value = action.meta.arg
      initReanalysisAdapter.upsertOne(state, action.payload)
      state.status[value.obraId] = 'Done'
    })

    builder.addCase(loadInitReanalysis.rejected, (state, action) => {
      const value = action.meta.arg
      state.status[value.obraId] = 'Error'
    })
  }
})

export const createSelectStatusOfInitReanalysis = (obraId: string) => {
  return ({ initReanalysis }: RootState) => initReanalysis.status[obraId]
}

export default reducer
