import ItemChecklistApprovalSaga from 'ItemsChecklist/sagas/ItemChecklistApprovalSaga'
import { all } from 'redux-saga/effects'
import authSaga from 'auth/sagas/authSaga'
import blockSaga from 'block/sagas/blockSaga'
import citySaga from 'city/sagas/citySaga'
import condominiumSaga from 'condominium/sagas/condominiumSaga'
import eventSaga from 'nonComplianceEvent/sagas/NonComplianceEventSaga'
import finishProcessSaga from 'work/sagas/finishProcessSaga'
import finishWorkSaga from 'work/sagas/finishWorkSaga'
import inhabitedWithReservations from 'work/sagas/inhabitedWithReservations'
import itemChecklistApproveAnalisisSaga from 'ItemsChecklist/sagas/itemChecklistApproveAnalisisSaga'
import itemChecklistDisapproveAnalisisSaga from 'ItemsChecklist/sagas/itemChecklistDisapproveAnalisisSaga'
import itemChecklistSaga from 'ItemsChecklist/sagas/itemChecklistSaga'
import itemTypeChecklistSaga from 'itemTypeChecklist/sagas/itemTypeChecklistSaga'
import itemsChecklistAnalisisSaga from 'ItemsChecklist/sagas/itemsChecklistAnalisisSaga'
import itemsChecklistSubmitionSaga from 'ItemsChecklist/sagas/itemsChecklistSubmitionSaga'
import typeChecklistRoutineSaga from 'routineChecklistTypes/sagas/typeChecklistDeRotinasSagas'
import lotSaga from 'lot/sagas/lotSaga'
import nonComplianceSaga from 'nonCompliance/sagas/nonComplianceSaga'
import nonComplianceTypeSaga from 'nonComplianceType/sagas/nonComplianceTypeSaga'
import nonComplianceCategorySaga from 'nonComplianceCategory/sagas/nonComplianceCategorySaga'
import passwordSaga from 'user/sagas/passwordSaga'
import paymentAttachmentAnalisisSaga from 'paymentAttachment/sagas/paymentAttachmentAnalisisSaga'
import paymentAttachmentSaga from 'paymentAttachment/sagas/paymentAttachmentSaga'
import projectApprovalSaga from 'project/sagas/projectApprovalSaga'
import projectSaga from 'project/sagas/projectSaga'
import reactiveProjectSaga from 'project/sagas/reactiveProjectSaga'
import reanalyzeProccessSaga from 'work/sagas/reanalyzeProccess'
import requestFinalSurveySaga from 'work/sagas/requestFinalSurveySaga'
import reviewProjectSaga from 'project/sagas/reviewProjectSaga'
import sendTaxSaga from 'work/sagas/sendTaxSaga'
import stateSaga from 'state/sagas/stateSaga'
import streetSaga from 'street/sagas/streetSaga'
import subjectSaga from 'technicalDocument/sagas/subject'
import submitToAnalisisSaga from 'project/sagas/submitToAnalisisSaga'
import submitWorkToAnalisisSaga from 'submitWorkToAnalisis/sagas/submitWorkToAnalisisSaga'
import supportMaterialSaga from 'supportMaterial/sagas/supportMaterialSaga'
import technicalDocumentSaga from 'technicalDocument/sagas/technicalDocumentSaga'
import updateAccessSaga from 'work/sagas/updateAccessSaga'
import uploadSaga from 'upload/sagas/uploadSaga'
import userCondominiumSaga from 'condominium/sagas/userCondominiumSaga'
import userLotSaga from 'userLot/sagas/userLotSaga'
import userPermissionsSaga from 'userPermissions/sagas/userPermissionsSaga'
import userSaga from 'user/sagas/userSaga'
import userWorkSaga from 'user/sagas/userWorkSaga'
import workCommentSaga from 'workComment/sagas/workCommentSaga'
import workEventSaga from 'workEvent/sagas/workEventSaga'
import workInitSaga from 'work/sagas/workInitSaga'
import workSaga from 'work/sagas/workSaga'
import addRoutineExistingWorkSaga from 'work/sagas/addRoutineExistingWorkSaga'
import typeRoutineSaga from 'typeRoutine/sagas/typeRoutineSagas'
import emailSendGridSaga from 'condominium/sagas/configSendGridSaga'

function* rootSaga() {
  yield all([
    authSaga(),
    condominiumSaga(),
    streetSaga(),
    blockSaga(),
    lotSaga(),
    nonComplianceSaga(),
    nonComplianceTypeSaga(),
    workSaga(),
    eventSaga(),
    uploadSaga(),
    userSaga(),
    userWorkSaga(),
    userPermissionsSaga(),
    itemTypeChecklistSaga(),
    itemChecklistSaga(),
    workEventSaga(),
    workCommentSaga(),
    ItemChecklistApprovalSaga(),
    paymentAttachmentSaga(),
    userLotSaga(),
    submitWorkToAnalisisSaga(),
    projectSaga(),
    projectApprovalSaga(),
    workInitSaga(),
    reactiveProjectSaga(),
    finishWorkSaga(),
    requestFinalSurveySaga(),
    finishProcessSaga(),
    sendTaxSaga(),
    itemChecklistApproveAnalisisSaga(),
    itemChecklistDisapproveAnalisisSaga(),
    typeChecklistRoutineSaga(),
    userCondominiumSaga(),
    paymentAttachmentAnalisisSaga(),
    submitToAnalisisSaga(),
    reviewProjectSaga(),
    supportMaterialSaga(),
    technicalDocumentSaga(),
    passwordSaga(),
    updateAccessSaga(),
    reanalyzeProccessSaga(),
    itemsChecklistAnalisisSaga(),
    itemsChecklistSubmitionSaga(),
    citySaga(),
    stateSaga(),
    inhabitedWithReservations(),
    subjectSaga(),
    nonComplianceCategorySaga(),
    typeRoutineSaga(),
    addRoutineExistingWorkSaga(),
    emailSendGridSaga()
  ])
}

export default rootSaga
