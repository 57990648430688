import {
  UserPermissionsOptions,
  fetchUserPermissions
} from 'userPermissions/api/userPermissionApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  loadUserPermissionsFail,
  loadUserPermissionsStarted,
  loadUserPermissionsSuccess
} from 'userPermissions/reducers/userPermissionsReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export function* handleLoaUserPermissions(
  action: PayloadAction<UserPermissionsOptions>
) {
  try {
    const response = yield call(fetchUserPermissions, action.payload)

    return yield put(
      loadUserPermissionsSuccess({
        result: response,
        workId: action.payload.ObraId
      })
    )
  } catch (error) {
    return yield put(loadUserPermissionsFail(error))
  }
}

function* userPermissionsSaga() {
  yield takeLatest(loadUserPermissionsStarted.type, handleLoaUserPermissions)
}

export default userPermissionsSaga
