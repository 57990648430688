import { Box, Flex, Text } from '@chakra-ui/core'
import React, { FC } from 'react'

import Breadcrumbs from 'carbono/Breadcrumbs'
import useTypedSelector from 'common/hooks/useTypedSelector'

const PageHeader: FC = ({ children }) => {
  const breadcrumbsItems = useTypedSelector(({ layout }) => layout.breadcrumbs)
  const title = useTypedSelector(({ layout }) => layout.pageTitle)

  return (
    <Box>
      <Breadcrumbs items={breadcrumbsItems} />
      <Flex align='center' wrap='wrap' mb={3}>
        <Text fontSize='4xl' fontWeight='bold' my={4} mr={3}>
          {title}
        </Text>
        {children}
      </Flex>
    </Box>
  )
}

export default PageHeader
