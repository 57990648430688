import {
  Notification,
  PaginatedNotificationOptions,
  SaveNotificationPayload,
  createNotification,
  deleteNotification,
  getNotification,
  getNotifications,
  updateNotification
} from 'notifications/api/notification'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const getNotificationsThunk = createAsyncThunk(
  'notification/all',
  async (options: PaginatedNotificationOptions, { rejectWithValue }) => {
    try {
      return await getNotifications(options)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const createNotificationThunk = createAsyncThunk(
  'notification/create',
  async (
    data: SaveNotificationPayload & { condominioId: string },
    { rejectWithValue }
  ) => {
    const { condominioId } = data

    try {
      return await createNotification(condominioId)(data)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateNotificationThunk = createAsyncThunk(
  'notification/update',
  async (data: Notification, { rejectWithValue }) => {
    try {
      return await updateNotification(data.id)(data)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteNotificationThunk = createAsyncThunk(
  'notification/delte',
  async (props: { id: string; condominioId: string }, { rejectWithValue }) => {
    try {
      return await deleteNotification(props.condominioId)(props.id)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getNotificationThunk = createAsyncThunk(
  'notification/byId',
  async (props: { id: string; condominioId: string }, { rejectWithValue }) => {
    try {
      return await getNotification(props.condominioId)(props.id)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
