import {
  Block,
  FetchBlocksOptions,
  SaveBlockPayload,
  deleteBlock,
  fetchBlock,
  fetchBlocks,
  fetchBlocksFromStreet,
  saveBlock
} from 'block/api/blockApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteBlockFail,
  deleteBlockStarted,
  deleteBlockSuccess,
  loadBlocksFail,
  loadBlocksFromStreetFail,
  loadBlocksFromStreetStarted,
  loadBlocksFromStreetSuccess,
  loadBlocksStarted,
  loadBlocksSuccess,
  loadSingleBlockFail,
  loadSingleBlockStarted,
  loadSingleBlockSuccess,
  saveBlockFail,
  saveBlockStarted,
  saveBlockSuccess
} from 'block/reducers/blockReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const blockSchema = new schema.Entity<Block>('block')

export function* handleLoadBlocks(
  action: PayloadAction<FetchBlocksOptions | undefined>
) {
  try {
    const response = yield call(fetchBlocks, action.payload)

    const { entities } = normalize(response.result, [blockSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      loadBlocksSuccess({
        result: entities.block || {},
        pagination
      })
    )
  } catch (error) {
    return yield put(loadBlocksFail(error))
  }
}

export function* handleLoadBlocksFromStreet(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchBlocksFromStreet, action.payload)

    const { entities } = normalize(response, [blockSchema])

    return yield put(
      loadBlocksFromStreetSuccess({
        result: entities.block,
        forStreet: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadBlocksFromStreetFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleBlock(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchBlock, action.payload)
    const { entities } = normalize(response, blockSchema)

    return yield put(loadSingleBlockSuccess(entities.block[response.id]))
  } catch (error) {
    return yield put(
      loadSingleBlockFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveBlock({ payload }: PayloadAction<SaveBlockPayload>) {
  try {
    const response = yield call(saveBlock, payload)

    const { entities } = normalize(response, blockSchema)

    return yield put(
      saveBlockSuccess({
        forItem: payload.id || 'new',
        result: entities.block[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveBlockFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteBlock({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteBlock, payload)

    return yield put(deleteBlockSuccess(payload))
  } catch (error) {
    return yield put(
      deleteBlockFail({
        forItem: payload,
        error
      })
    )
  }
}

function* blockSaga() {
  yield takeLatest(loadBlocksStarted.type, handleLoadBlocks)
  yield takeLatest(loadBlocksFromStreetStarted.type, handleLoadBlocksFromStreet)
  yield takeLatest(loadSingleBlockStarted.type, handleLoadSingleBlock)
  yield takeLeading(saveBlockStarted.type, handleSaveBlock)
  yield takeLeading(deleteBlockStarted.type, handleDeleteBlock)
}

export default blockSaga
