import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FetchItemsChecklistOptions,
  ItemChecklistDeleteOptions,
  ItemChecklistResponse,
  SaveItemChecklistPayload
} from 'ItemsChecklist/api/itemsChecklistApi'
import createSelector from 'shared/createSelector'
import { Pagination, RequestError, ResourceFailPayload } from 'types/global'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state de item de checklist
 */
export interface ItemChecklistState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
  byWork: { [page: string]: string[] | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: ItemChecklistResponse | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: ItemChecklistState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  byWork: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: itemChecklistReducer } = createSlice({
  name: 'itemChecklist',
  initialState,
  reducers: {
    fetchItemsChecklistStarted(
      state,
      action: PayloadAction<FetchItemsChecklistOptions>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchItemsChecklistSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        isObra?: boolean
        result: { [id: string]: ItemChecklistResponse }
      }>
    ) {
      const { result, forWork } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      const keys = [...(state.byWork[forWork] || []), ...Object.keys(result)]

      state.byWork[forWork] = keys.filter(
        (key, index) => keys.indexOf(key) === index
      )

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchItemsChecklistFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    saveItemChecklistStarted(
      state,
      { payload }: PayloadAction<SaveItemChecklistPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveItemChecklistSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: ItemChecklistResponse
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = result

      return state
    },
    saveItemChecklistFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },

    // TODO(Fábio): precisamos mover isso futuramente
    deleteItemChecklistUploadStarted(
      state,
      { payload }: PayloadAction<ItemChecklistDeleteOptions>
    ) {
      const key = `upload.new`

      state.status[key] = 'Deleting'
      state.errors[key] = undefined

      return state
    },
    deleteItemChecklistUploadSuccess(
      state,
      { payload }: PayloadAction<string>
    ) {
      const key = `upload.new`

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.items[key] = undefined

      return state
    },
    deleteItemChecklistUploadFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `upload.new`

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    }
  }
})

const getItemsChecklistBy = (
  condition: (i: ItemChecklistResponse | undefined) => boolean | undefined,
  work: string
) =>
  createSelector(({ itemChecklist }) => {
    const items = itemChecklist.byWork[work]

    const itemsWorkKeys =
      items &&
      items.filter(key => {
        const item = itemChecklist.items[key]

        return condition(item)
      })

    return itemsWorkKeys || []
  })

export const getItemsChecklist = (workId: string, isObra: boolean) =>
  getItemsChecklistBy(item => item && item.isObra === isObra, workId)

export const itemsChecklistWork = (workId: string) =>
  getItemsChecklistBy(item => item && item.isObra === true, workId)

export const itemsChecklistProcess = (workId: string) =>
  getItemsChecklistBy(item => item && !item.isObra, workId)

export const getItemById = (itemChecklistId: string) =>
  createSelector(({ itemChecklist }) => {
    const id = Object.keys(itemChecklist.items).find(id => {
      const item = itemChecklist.items[id]
      return item && item.itemChecklists?.[0].id === itemChecklistId
    })

    return itemChecklist.items[id || '']
  })

export const getItemStatusById = (itemChecklistId: string) =>
  createSelector(({ itemChecklist }) => {
    const id = Object.keys(itemChecklist.items).find(id => {
      const item = itemChecklist.items[id]
      return item && item.itemChecklists?.[0].id === itemChecklistId
    })

    return itemChecklist.status[id || '']
  })

export const {
  fetchItemsChecklistFail,
  fetchItemsChecklistStarted,
  fetchItemsChecklistSuccess,
  saveItemChecklistFail,
  saveItemChecklistStarted,
  saveItemChecklistSuccess,
  deleteItemChecklistUploadFail,
  deleteItemChecklistUploadStarted,
  deleteItemChecklistUploadSuccess
} = actions

export default itemChecklistReducer
