enum workStatus {
  Invalid = 'Invalido',
  Created = 'Cadastrada',
  inAnalysis = 'EmAnalise',
  ProcessApproved = 'ProcessoAprovado',
  workInAnalysis = 'EmAnaliseObra',
  running = 'EmExecucao',
  workEnded = 'ObraEncerrada',
  ended = 'Encerrada',
  Archived = 'Arquivada'
}

export const statusDisplayName = (status?: workStatus) => {
  switch (status) {
    case workStatus.Invalid:
      return 'Inválido'
    case workStatus.Created:
      return 'Processo cadastrado'
    case workStatus.inAnalysis:
      return 'Processo em análise'
    case workStatus.ProcessApproved:
      return 'Processo aprovado'
    case workStatus.workInAnalysis:
      return 'Obra em análise'
    case workStatus.running:
      return 'Obra em execução'
    case workStatus.workEnded:
      return 'Obra encerrada'
    case workStatus.ended:
      return 'Encerrado'
    case workStatus.Archived:
      return 'Arquivado'
    default:
      return 'Outro'
  }
}

export const getNumericValue = (_?: workStatus) => {
  return NaN
}

export const workStatusOptions = [
  {
    value: workStatus.Created,
    status: statusDisplayName(workStatus.Created)
  },
  {
    value: workStatus.inAnalysis,
    status: statusDisplayName(workStatus.inAnalysis)
  },
  {
    value: workStatus.workInAnalysis,
    status: statusDisplayName(workStatus.workInAnalysis)
  },
  {
    value: workStatus.ProcessApproved,
    status: statusDisplayName(workStatus.ProcessApproved)
  },
  {
    value: workStatus.running,
    status: statusDisplayName(workStatus.running)
  },
  {
    value: workStatus.workEnded,
    status: statusDisplayName(workStatus.workEnded)
  },
  {
    value: workStatus.ended,
    status: statusDisplayName(workStatus.ended)
  },
  {
    value: workStatus.Archived,
    status: statusDisplayName(workStatus.Archived)
  }
]

export default workStatus
