import { City, FetchCitiesOptions } from 'city/api/cityApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface CityState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  items: { [key: string]: City | undefined }
  byState: { [page: string]: string[] }
}

const initialState: CityState = {
  items: {},
  byState: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */
const { actions, reducer: cityReducer } = createSlice({
  name: 'city',
  initialState,
  reducers: {
    loadCitiesStarted(
      state,
      { payload }: PayloadAction<FetchCitiesOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadCitiesSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: City }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadCitiesFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadCitiesByStateStarted(state, action: PayloadAction<string>) {
      const key = action.payload
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadCitiesByStateSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forState: string
        result: { [id: string]: City }
      }>
    ) {
      const { result, forState } = payload
      const key = forState

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.byState[forState] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadCitiesByStateFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = payload.forItem

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    }
  }
})

export const {
  loadCitiesFail,
  loadCitiesStarted,
  loadCitiesSuccess,
  loadCitiesByStateFail,
  loadCitiesByStateStarted,
  loadCitiesByStateSuccess
} = actions

export default cityReducer
