import { FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/core'
import { GridProps } from 'carbono/Grid'
import React, { FC } from 'react'

export interface FormItemProps extends Omit<GridProps, 'mode'> {
  label?: string
  hasError?: boolean
  hasWarning?: boolean
  message?: string
  obsMessage?: string
}

const FormItem: FC<FormItemProps> = ({ label, message, ...props }) => {
  return (
    <FormControl isInvalid={props.hasError} my={2}>
      <FormLabel htmlFor='email'>{label}</FormLabel>
      {props.children}
      <FormErrorMessage>{message}</FormErrorMessage>
      {props.obsMessage && (
        <Text as='sub' color='gray.500'>
          {props.obsMessage}
        </Text>
      )}
    </FormControl>
  )
}

export default FormItem
