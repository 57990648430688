import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface EmailSendGrid {
  id: string
  condominioId: string
  tipoEmail: number | string
  assunto: string
  templateId: string
}

export type FetchEmailSendGridOptions = PaginatedEndpointOptions<EmailSendGrid>
export type FetchEmailSendGridResponse = PaginatedEndpointResponse<
  EmailSendGrid
>

/**
 * Carrega lista de Lote/Sala/Apartamento paginados e filtradas pela query.
 */
export async function fetchEmailSendGrid(options?: FetchEmailSendGridOptions) {
  try {
    const { data } = await api.get<FetchEmailSendGridResponse>(
      'EmailSendGrid',
      {
        params: options
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de Lote/Sala/Apartamento paginadas e filtradas pela query.
 */
export async function fetchConfigSendGrid(options: FetchEmailSendGridOptions) {
  try {
    const { data } = await api.get<EmailSendGrid[]>(
      `/Condominio/${options?.condominioId}/configuracaoEmail/${options?.tipoEmail}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveEmailSendGridPayload = SaveResourcePayload<EmailSendGrid>

export async function saveEmailSendGrid(
  EmailSendGrid: SaveEmailSendGridPayload
) {
  try {
    const request = api.post

    const { data } = await request<EmailSendGrid>(
      'Condominio/configuracaoEmail',
      EmailSendGrid
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
