import {
  ProjectApproval,
  SaveProjectApprovalPayload,
  saveProjectApproval
} from 'project/api/projectApprovalApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveProjectApprovalFail,
  saveProjectApprovalStarted,
  saveProjectApprovalSuccess
} from 'project/reducers/projectApprovalReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const projectApprovalSchema = new schema.Entity<ProjectApproval>(
  'projectAppoval'
)

export function* handleSaveProjectApproval({
  payload
}: PayloadAction<SaveProjectApprovalPayload>) {
  try {
    const response = yield call(saveProjectApproval, payload)

    const { entities } = normalize(response, projectApprovalSchema)

    return yield put(
      saveProjectApprovalSuccess({
        forItem: payload.id || 'new',
        result: entities.projectAppoval[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveProjectApprovalFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* projectApprovalSaga() {
  yield takeLeading(saveProjectApprovalStarted.type, handleSaveProjectApproval)
}

export default projectApprovalSaga
