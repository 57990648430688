import {
  FetchTypeChecklistRoutineOptions,
  SaveTypeChecklistRoutinePayload,
  deleteTypeChecklistRoutine,
  fetchTypesChecklistRoutine,
  fetchTypeChecklistRoutine,
  fetchTypesChecklistRoutineByName,
  saveTypeChecklistRoutine,
  TypeChecklistRoutine,
  editTypeChecklistRoutine,
  EditTypeChecklistRoutinePayload,
  deleteItemTypeChecklistRoutine,
  EventsExecutionRoutinesByWorkId,
  fetchEventsExecutionRoutinesByWorkId
} from 'routineChecklistTypes/api/typeChecklistRoutineApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteTypeChecklistRoutineFail,
  deleteTypeChecklistRoutineStarted,
  deleteTypeChecklistRoutineSuccess,
  fetchTypeChecklistRoutineFail,
  fetchTypeChecklistRoutineStarted,
  fetchTypeChecklistRoutineSuccess,
  fetchTypeChecklistRoutineByNameFail,
  fetchTypeChecklistRoutineByNameStarted,
  fetchTypeChecklistRoutineByNameSuccess,
  fetchSingleTypeChecklistRoutineFail,
  fetchSingleTypeChecklistRoutineStarted,
  fetchSingleTypeChecklistRoutineSuccess,
  fetchEventsExecutionRoutinesByWorkIdFail,
  fetchEventsExecutionRoutinesByWorkIdStarted,
  fetchEventsExecutionRoutinesByWorkIdSuccess,
  saveTypeChecklistRoutineFail,
  saveTypeChecklistRoutineStarted,
  saveTypeChecklistRoutineSuccess,
  editTypeChecklistRoutineStarted,
  editTypeChecklistRoutineSuccess,
  editTypeChecklistRoutineFail,
  deleteItemTypeChecklistRoutineSuccess,
  deleteItemTypeChecklistRoutineFail,
  deleteItemTypeChecklistRoutineStarted
} from 'routineChecklistTypes/reducers/typeChecklistDeRotinasReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const typeChecklistRoutineSchema = new schema.Entity<
  TypeChecklistRoutine
>('typeChecklistRoutine')

export const eventsExecutionRoutinesByWorkIdSchema = new schema.Entity<
  EventsExecutionRoutinesByWorkId
>('eventsExecutionRoutinesByWorkId')

export function* handleFetchTypeChecklistRoutine(
  action: PayloadAction<FetchTypeChecklistRoutineOptions | undefined>
) {
  try {
    const response = yield call(fetchTypesChecklistRoutine, action.payload)

    const { entities } = normalize(response.result, [
      typeChecklistRoutineSchema
    ])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      fetchTypeChecklistRoutineSuccess({
        result: entities.typeChecklistRoutine || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchTypeChecklistRoutineFail(error))
  }
}

export function* handleFetchTypeChecklistRoutineByName(
  action: PayloadAction<FetchTypeChecklistRoutineOptions | undefined>
) {
  try {
    const response = yield call(
      fetchTypesChecklistRoutineByName,
      action.payload
    )

    const { entities } = normalize(response.result, [
      typeChecklistRoutineSchema
    ])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      fetchTypeChecklistRoutineByNameSuccess({
        result: entities.typeChecklistRoutine || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchTypeChecklistRoutineByNameFail(error))
  }
}

export function* handleFetchSingleTypeChecklistRoutine(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchTypeChecklistRoutine, action.payload)
    const { entities } = normalize(response, typeChecklistRoutineSchema)

    return yield put(
      fetchSingleTypeChecklistRoutineSuccess(
        entities.typeChecklistRoutine[response.id]
      )
    )
  } catch (error) {
    return yield put(
      fetchSingleTypeChecklistRoutineFail({
        id: action.payload,
        error
      })
    )
  }
}

export function* handleSaveTypeChecklistRoutine({
  payload
}: PayloadAction<SaveTypeChecklistRoutinePayload>) {
  try {
    const response = yield call(saveTypeChecklistRoutine, payload)

    const { entities } = normalize(response, typeChecklistRoutineSchema)

    return yield put(
      saveTypeChecklistRoutineSuccess({
        forItem: payload.id || 'new',
        result: entities.typeChecklistRoutine[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveTypeChecklistRoutineFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}
export function* handleEventsExecutionRoutinesByWorkId(
  action: PayloadAction<EventsExecutionRoutinesByWorkId>
) {
  try {
    const response = yield call(
      fetchEventsExecutionRoutinesByWorkId,
      action.payload
    )

    const { entities } = normalize(response, [
      eventsExecutionRoutinesByWorkIdSchema
    ])

    return yield put(
      fetchEventsExecutionRoutinesByWorkIdSuccess({
        result: entities.eventsExecutionRoutinesByWorkId || [],
        forWork: action.payload.id
      })
    )
  } catch (error) {
    return yield put(
      fetchEventsExecutionRoutinesByWorkIdFail({
        forItem: action.payload.id,
        error
      })
    )
  }
}

export function* handleEditTypeChecklistRoutine({
  payload
}: PayloadAction<EditTypeChecklistRoutinePayload>) {
  try {
    const response = yield call(editTypeChecklistRoutine, payload)

    const { entities } = normalize(response, typeChecklistRoutineSchema)

    return yield put(
      editTypeChecklistRoutineSuccess({
        forItem: payload.id || 'new',
        result: entities.typeChecklistRoutine[response.id]
      })
    )
  } catch (error) {
    return yield put(
      editTypeChecklistRoutineFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteTypeChecklistRoutine({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteTypeChecklistRoutine, payload)

    return yield put(deleteTypeChecklistRoutineSuccess(payload))
  } catch (error) {
    return yield put(
      deleteTypeChecklistRoutineFail({
        forItem: payload,
        error
      })
    )
  }
}
export function* handleDeleteItemTypeChecklistRoutine({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteItemTypeChecklistRoutine, payload)

    return yield put(deleteItemTypeChecklistRoutineSuccess(payload))
  } catch (error) {
    return yield put(
      deleteItemTypeChecklistRoutineFail({
        forItem: payload,
        error
      })
    )
  }
}

function* typeChecklistRoutineSaga() {
  yield takeLatest(
    fetchTypeChecklistRoutineStarted.type,
    handleFetchTypeChecklistRoutine
  )
  yield takeLatest(
    fetchTypeChecklistRoutineByNameStarted.type,
    handleFetchTypeChecklistRoutineByName
  )
  yield takeLatest(
    fetchSingleTypeChecklistRoutineStarted.type,
    handleFetchSingleTypeChecklistRoutine
  )
  yield takeLatest(
    fetchEventsExecutionRoutinesByWorkIdStarted.type,
    handleEventsExecutionRoutinesByWorkId
  )
  yield takeLeading(
    saveTypeChecklistRoutineStarted.type,
    handleSaveTypeChecklistRoutine
  )
  yield takeLatest(
    editTypeChecklistRoutineStarted.type,
    handleEditTypeChecklistRoutine
  )
  yield takeLeading(
    deleteTypeChecklistRoutineStarted.type,
    handleDeleteTypeChecklistRoutine
  )
  yield takeLeading(
    deleteItemTypeChecklistRoutineStarted.type,
    handleDeleteItemTypeChecklistRoutine
  )
}

export default typeChecklistRoutineSaga
