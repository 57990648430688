import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface SendTax {
  id: string
  obraId: string
  mensagem?: string
  uploadsId?: string[]
}

export type SaveSendTaxPayload = SaveResourcePayload<SendTax>

export const saveSendTax = save<SendTax>('Obra/EnviarTaxa')
