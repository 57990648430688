import reducerEntity from 'common/types/reducerEntity'
import { useEffect } from 'react'
import usePrevious from 'common/hooks/usePrevious'
import useTypedSelector from 'common/hooks/useTypedSelector'

const useWhenDone = (
  entity: reducerEntity,
  key: string,
  action: (args: {
    status: 'Done' | 'Error'
    previousStatus: 'Saving' | 'Deleting'
  }) => void,
  stage: 'Done' | 'Error' = 'Done'
) => {
  const status = useTypedSelector(({ [entity]: e }) => e.status[key])
  const previousStatus = usePrevious(status)

  useEffect(() => {
    if (
      status === stage &&
      (previousStatus === 'Saving' || previousStatus === 'Deleting')
    )
      action({ status, previousStatus })
  }, [status])
}

export default useWhenDone
