import {
  FetchEmailSendGridOptions,
  EmailSendGrid,
  SaveEmailSendGridPayload
} from 'condominium/api/ConfigSendGridApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createSelector from 'shared/createSelector'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus
} from 'types/global'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface EmailSendGridState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  byEmailType: { [page: string]: string[] }
  items: { [key: string]: EmailSendGrid | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: EmailSendGridState = {
  items: {},
  pages: {},
  byEmailType: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: emailSendGridReducer } = createSlice({
  name: 'emailSendGrid',
  initialState,
  reducers: {
    EmailSendGridStarted(
      state,
      action: PayloadAction<FetchEmailSendGridOptions>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    // loadEmailSendGridSuccess(
    //   state,
    //   {
    //     payload
    //   }: PayloadAction<{
    //     result: { [id: string]: EmailSendGrid }
    //     pagination: Pagination
    //   }>
    // ) {
    //   const { result, pagination } = payload
    //   state.status['page'] = 'Done'
    //   state.errors['page'] = undefined

    //   state.pagination = {
    //     ...state.pagination,
    //     ...pagination
    //   }

    //   state.pages[pagination.current] = Object.keys(result)

    //   state.items = {
    //     ...state.items,
    //     ...result
    //   }

    //   return state
    // },
    // loadEmailSendGridFail(
    //   state,
    //   { payload }: PayloadAction<RequestError | undefined>
    // ) {
    //   state.status['page'] = 'Error'
    //   state.errors['page'] = payload

    //   return state
    // },
    loadEmailSendGridFromEmailTypeStarted(
      state,
      action: PayloadAction<FetchEmailSendGridOptions>
    ) {
      const key = `emailSendGrid.${action.payload}`
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadEmailSendGridFromEmailTypeSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forEmailSendgrid: string | any
        result: { [templateId: string]: EmailSendGrid }
      }>
    ) {
      const { result, forEmailSendgrid } = payload
      const key = `emailSendGrid.${forEmailSendgrid}`

      state.status[key] = 'Done'
      state.errors[key] = undefined

      if (result) {
        // Verifica se result não é nulo ou indefinido antes de usar Object.keys()
        state.byEmailType[forEmailSendgrid] = Object.keys(result)
        state.items = {
          ...state.items,
          ...result
        }
      }

      return state
    },
    loadEmailSendGridFromEmailTypeFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `emailSendGrid.${payload.forItem}`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    // loadSingleLotStarted(state, { payload }: PayloadAction<string>) {
    //   state.errors[payload] = undefined
    //   state.status[payload] = 'Pending'

    //   return state
    // },
    // loadSingleLotSuccess(state, { payload }: PayloadAction<EmailSendGrid>) {
    //   state.status[payload.id] = 'Done'
    //   state.errors[payload.id] = undefined
    //   state.items[payload.id] = payload

    //   return state
    // },
    // loadSingleLotFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
    //   state.status[payload.forItem] = 'Error'
    //   state.errors[payload.forItem] = payload.error

    //   return state
    // },
    saveEmailSendGridStarted(
      state,
      { payload }: PayloadAction<SaveEmailSendGridPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveEmailSendGridSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: EmailSendGrid
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.tipoEmail] = 'Done'
      state.errors[result.tipoEmail] = undefined
      state.items[result.tipoEmail] = payload.result

      return state
    },
    saveEmailSendGridFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const getEmailSendGridOnCurrentPage = createSelector(
  ({ emailSendGrid }) => {
    const { pagination, pages } = emailSendGrid

    return pages[pagination.current] || []
  }
)

export const getEmailSendGridById = (id: string) =>
  createSelector(({ emailSendGrid }) => emailSendGrid.items[id])

export const getEmailSendGridStatusById = (id: string) =>
  createSelector(({ emailSendGrid }) => emailSendGrid.status[id])

export const {
  // loadLotsStarted,
  // loadLotsSuccess,
  // loadLotsFail,
  loadEmailSendGridFromEmailTypeStarted,
  loadEmailSendGridFromEmailTypeSuccess,
  loadEmailSendGridFromEmailTypeFail,
  //loadSingleLotStarted,
  // loadSingleLotSuccess,
  // loadSingleLotFail,
  saveEmailSendGridStarted,
  saveEmailSendGridSuccess,
  saveEmailSendGridFail
  // deleteLotStarted,
  // deleteLotSuccess,
  // deleteLotFail
} = actions

export default emailSendGridReducer
