import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SaveUserWorkPayload, UserWork } from 'work/api/workApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface WorkState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  byWork: { [key: string]: string[] | undefined }
  pagination: Pagination
  items: { [key: string]: UserWork | undefined }
}

const initialState: WorkState = {
  items: {},
  status: {},
  errors: {},
  byWork: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: userWorkReducer } = createSlice({
  name: 'userWork',
  initialState,
  reducers: {
    saveUserWorkStarted(
      state,
      { payload }: PayloadAction<SaveUserWorkPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveUserWorkSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<UserWork>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined

      return state
    },
    saveUserWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    loadUserWorkFromWorkStarted(state, action: PayloadAction<string>) {
      const key = createByWorkKey(action.payload)
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadUserWorkFromWorkSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: { [id: string]: UserWork }
        pagination: Pagination
      }>
    ) {
      const { result, forWork, pagination } = payload
      const key = createByWorkKey(forWork)

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.items = {
        ...state.items,
        ...result
      }

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.byWork[forWork] = Object.keys(result)

      return state
    },
    loadUserWorkFromWorkFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = createByWorkKey(payload.forItem)

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    deleteUserWorkStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteUserWorkSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      return state
    },
    deleteUserWorkFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const createByWorkKey = (workId: string) => `work.${workId}`

export const {
  saveUserWorkStarted,
  saveUserWorkSuccess,
  saveUserWorkFail,
  loadUserWorkFromWorkFail,
  loadUserWorkFromWorkStarted,
  loadUserWorkFromWorkSuccess,
  deleteUserWorkFail,
  deleteUserWorkStarted,
  deleteUserWorkSuccess
} = actions

export default userWorkReducer
