import {
  SaveUserWorkPayload,
  UserWork,
  deleteUserWork,
  fetchUserWork,
  saveUserWork
} from 'work/api/workApi'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import {
  deleteUserWorkFail,
  deleteUserWorkStarted,
  deleteUserWorkSuccess,
  loadUserWorkFromWorkFail,
  loadUserWorkFromWorkStarted,
  loadUserWorkFromWorkSuccess,
  saveUserWorkFail,
  saveUserWorkStarted,
  saveUserWorkSuccess
} from 'user/reducers/userWorkReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const userWorkSchema = new schema.Entity<UserWork>('userWork')

export function* handleSaveUserWork({
  payload
}: PayloadAction<SaveUserWorkPayload>) {
  try {
    const response = yield call(saveUserWork, payload)

    const { entities } = normalize(response, userWorkSchema)

    return yield put(
      saveUserWorkSuccess({
        forItem: payload.id || 'new',
        result: entities.userWork[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUserWorkFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleLoadUserWorkFromWork(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchUserWork, action.payload)

    const { entities } = normalize(response.result, [userWorkSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadUserWorkFromWorkSuccess({
        result: entities.userWork || [],
        forWork: action.payload,
        pagination
      })
    )
  } catch (error) {
    return yield put(
      loadUserWorkFromWorkFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleDeleteUserWork({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteUserWork, payload)

    return yield put(deleteUserWorkSuccess(payload))
  } catch (error) {
    return yield put(
      deleteUserWorkFail({
        forItem: payload,
        error
      })
    )
  }
}

function* userWorkSaga() {
  yield takeLeading(saveUserWorkStarted.type, handleSaveUserWork)
  yield takeEvery(loadUserWorkFromWorkStarted.type, handleLoadUserWorkFromWork)
  yield takeLeading(deleteUserWorkStarted.type, handleDeleteUserWork)
}

export default userWorkSaga
