import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { JoinLots, saveJoinLots } from 'lot/api/joinLots'
import { RequestError, ResourceStatus } from 'types/global'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const saveJoinLotsThunk = createAsyncThunk(
  'joinLots',
  async (payload: JoinLots, { rejectWithValue }) => {
    try {
      return await saveJoinLots(payload)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

export interface SendJoinLotsState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const itemAdapter = createEntityAdapter<JoinLots>()

const initialState: SendJoinLotsState = {
  status: {},
  errors: {}
}

const { reducer: sendJoinLotsReducer } = createSlice({
  name: 'joinLots',
  initialState: itemAdapter.getInitialState(initialState),
  reducers: {
    addIncludedSendJoinLotsItems: itemAdapter.upsertMany
  },
  extraReducers(builder) {
    builder.addCase(saveJoinLotsThunk.pending, (state, action) => {
      const itemId = 'new'

      state.status[itemId] = 'Saving'
      state.errors[itemId] = undefined
    })

    builder.addCase(saveJoinLotsThunk.fulfilled, (state, action) => {
      const itemId = 'new'

      state.status[itemId] = 'Done'
      state.errors[itemId] = undefined

      itemAdapter.upsertOne(state, action.payload)
    })

    builder.addCase(saveJoinLotsThunk.rejected, (state, action) => {
      const itemId = 'new'
      state.status[itemId] = 'Error'
      state.errors[itemId] = action.payload as any
    })
  }
})

export default sendJoinLotsReducer
