import { save } from 'common/api/apiHelper'

export interface SubmitInitialWork {
  obraId: string
  mensagem: string
  uploadIds: string[]
}

export const saveWorkInit = (ObraId: string) =>
  save<SubmitInitialWork>(`Obra/${ObraId}/SubmeterItemchecklistInicioObra`)
