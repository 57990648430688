import {
  FinishProcess,
  SaveFinishProcessPayload
} from 'work/api/finishProcessApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface FinishProcessState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: FinishProcess | undefined }
}

const initialState: FinishProcessState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: finishProcessReducer } = createSlice({
  name: 'finishProcess',
  initialState,
  reducers: {
    saveFinishProcessStarted(
      state,
      { payload }: PayloadAction<SaveFinishProcessPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveFinishProcessSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<FinishProcess>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveFinishProcessFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveFinishProcessStarted,
  saveFinishProcessSuccess,
  saveFinishProcessFail
} = actions

export default finishProcessReducer
