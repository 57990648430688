import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Project,
  ProjectDeleteOptions,
  SaveProjectPayload
} from 'project/api/projectApi'
import createSelector from 'shared/createSelector'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface ProjectState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  byWork: { [key: string]: Project | undefined }
  items: { [key: string]: Project | undefined }
}

const initialState: ProjectState = {
  items: {},
  status: {},
  byWork: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: projectReducer } = createSlice({
  name: 'project',
  initialState,
  reducers: {
    loadSingleProjectStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleProjectSuccess(state, { payload }: PayloadAction<Project>) {
      state.status[payload.obraId] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload
      state.byWork[payload.obraId] = payload

      return state
    },
    loadSingleProjectFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveProjectStarted(state, { payload }: PayloadAction<SaveProjectPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveProjectSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Project>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveProjectFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    saveProjectApprovedStarted(
      state,
      { payload }: PayloadAction<SaveProjectPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveProjectApprovedSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Project>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveProjectApprovedFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },

    // TODO(Fábio): precisamos mover isso futuramente
    deleteProjectUploadStarted(
      state,
      { payload }: PayloadAction<ProjectDeleteOptions>
    ) {
      const key = `upload.new`

      state.status[key] = 'Deleting'
      state.errors[key] = undefined

      return state
    },
    deleteProjectUploadSuccess(state, { payload }: PayloadAction<string>) {
      const key = `upload.new`

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.items[key] = undefined

      return state
    },
    deleteProjectUploadFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `upload.new`

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    }
  }
})

export const selectById = (id: string) =>
  createSelector(({ project }) => project.items[id])

export const selectByWorkId = (id: string) =>
  createSelector(({ project }) => project.byWork[id])

export const selectStatus = (id: string) =>
  createSelector(({ project }) => project.status[id])

export const {
  loadSingleProjectStarted,
  loadSingleProjectSuccess,
  loadSingleProjectFail,
  saveProjectStarted,
  saveProjectSuccess,
  saveProjectFail,
  deleteProjectUploadFail,
  deleteProjectUploadStarted,
  deleteProjectUploadSuccess,
  saveProjectApprovedFail,
  saveProjectApprovedStarted,
  saveProjectApprovedSuccess
} = actions

export default projectReducer
