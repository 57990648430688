import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { InfoAboutService } from 'serviceProvider/contracts/types'

const { reducer, actions } = createSlice({
  name: 'serviceProvider/info',
  initialState: { serviceInfo: {} as InfoAboutService },
  reducers: {
    addServiceInfo: (state, action: PayloadAction<InfoAboutService>) => {
      return { serviceInfo: action.payload }
    }
  }
})

export const { addServiceInfo } = actions

export default reducer
