import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface SubmitWorkToAnalisis {
  obraId: string
  mensagem: string
  uploadIds: string[]
}

export async function saveSubmitWorkToAnalisis(
  submitWorkToAnalisis: SubmitWorkToAnalisis
) {
  try {
    const { data } = await api.post<SubmitWorkToAnalisis>(
      'Obra/SubmeterParaAnalise',
      submitWorkToAnalisis
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
