import {
  ItemChecklistDisapproveAnalisis,
  SaveItemChecklistDisapproveAnalisiskPayload
} from 'ItemsChecklist/api/itemChecklistDisapproveAnalisisApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface ItemChecklistDisapproveAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ItemChecklistDisapproveAnalisis | undefined }
}

const initialState: ItemChecklistDisapproveAnalisisState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const {
  actions,
  reducer: itemChecklistDisapproveAnalisisReducer
} = createSlice({
  name: 'itemChecklistDisapproveAnalisis',
  initialState,
  reducers: {
    saveItemChecklistDisapproveAnalisisStarted(
      state,
      { payload }: PayloadAction<SaveItemChecklistDisapproveAnalisiskPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveItemChecklistDisapproveAnalisisSuccess(
      state,
      {
        payload
      }: PayloadAction<
        SaveResourceSuccessPayload<ItemChecklistDisapproveAnalisis>
      >
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveItemChecklistDisapproveAnalisisFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveItemChecklistDisapproveAnalisisStarted,
  saveItemChecklistDisapproveAnalisisSuccess,
  saveItemChecklistDisapproveAnalisisFail
} = actions

export default itemChecklistDisapproveAnalisisReducer
