import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ItemChecklistApproveAnalisis {
  id: string
  obraId: string
  mensagem: string
  uploadIds: string[]
}

export type SaveItemChecklistApproveAnalisiskPayload = SaveResourcePayload<
  ItemChecklistApproveAnalisis
>

export const saveItemChecklistApproveAnalisisk = save<
  ItemChecklistApproveAnalisis
>('Obra/aprovarAnalise')
