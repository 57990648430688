import {
  FetchTechnicalDocumentsOptions,
  SaveTechnicalDocumentPayload,
  TechnicalDocument
} from 'technicalDocument/api/technicalDocumentApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de documento técnico
 */
export interface TechnicalDocumentState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  items: { [key: string]: TechnicalDocument | undefined }
}

const initialState: TechnicalDocumentState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: technicalDocumentReducer } = createSlice({
  name: 'technicalDocument',
  initialState,
  reducers: {
    loadTechnicalDocumentsStarted(
      state,
      { payload }: PayloadAction<FetchTechnicalDocumentsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadTechnicalDocumentsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: TechnicalDocument }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadTechnicalDocumentsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadSingleTechnicalDocumentStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleTechnicalDocumentSuccess(
      state,
      { payload }: PayloadAction<TechnicalDocument>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleTechnicalDocumentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveTechnicalDocumentStarted(
      state,
      { payload }: PayloadAction<SaveTechnicalDocumentPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveTechnicalDocumentSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<TechnicalDocument>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveTechnicalDocumentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteTechnicalDocumentStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteTechnicalDocumentSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteTechnicalDocumentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const technicalDocumentOnPage = createSelector(
  ({ technicalDocument }) => {
    const { pagination, pages } = technicalDocument

    return pages[pagination.current] || []
  }
)

export const {
  loadTechnicalDocumentsStarted,
  loadTechnicalDocumentsSuccess,
  loadTechnicalDocumentsFail,
  loadSingleTechnicalDocumentStarted,
  loadSingleTechnicalDocumentSuccess,
  loadSingleTechnicalDocumentFail,
  saveTechnicalDocumentStarted,
  saveTechnicalDocumentSuccess,
  saveTechnicalDocumentFail,
  deleteTechnicalDocumentStarted,
  deleteTechnicalDocumentSuccess,
  deleteTechnicalDocumentFail
} = actions

export default technicalDocumentReducer
