import {
  FetchNonCompliancesOptions,
  ListRoutineWork,
  NonCompliance,
  SaveNonCompliancePayload
} from 'nonCompliance/api/nonComplianceApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface NonComplianceState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  byWork: { [page: string]: string[] | undefined }
  items: { [key: string]: NonCompliance | undefined }
  itemsList: { [key: string]: ListRoutineWork | undefined }
  routineByWork: { [page: string]: string[] | undefined }
}

const initialState: NonComplianceState = {
  items: {},
  pages: {},
  byWork: {},
  status: {},
  errors: {},
  itemsList: {},
  routineByWork: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: nonComplianceReducer } = createSlice({
  name: 'nonCompliance',
  initialState,
  reducers: {
    loadNonCompliancesStarted(
      state,
      action: PayloadAction<FetchNonCompliancesOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadNonCompliancesSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: NonCompliance }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadNonCompliancesFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadNonCompliancesFromWorkStarted(state, action: PayloadAction<string>) {
      const key = createByWorkKey(action.payload)
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadNonCompliancesFromWorkSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: { [id: string]: NonCompliance }
      }>
    ) {
      const { result, forWork } = payload
      const key = createByWorkKey(forWork)

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.byWork[forWork] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadNonCompliancesFromWorkFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = createByWorkKey(payload.forItem)

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadListRoutineWorkStarted(state, action: PayloadAction<string>) {
      const key = createByWorkKey(action.payload)
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadListRoutineWorkSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: { [id: string]: ListRoutineWork }
      }>
    ) {
      const { result, forWork } = payload
      const key = createByWorkKey(forWork)

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.routineByWork[forWork] = Object.keys(result)

      state.itemsList = {
        ...state.itemsList,
        ...result
      }

      return state
    },
    loadListRoutineWorkFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = createByWorkKey(payload.forItem)

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadSingleNonComplianceStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleNonComplianceSuccess(
      state,
      { payload }: PayloadAction<NonCompliance>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleNonComplianceFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    loadOpenSingleNonComplianceStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadOpenSingleNonComplianceSuccess(
      state,
      { payload }: PayloadAction<NonCompliance>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadOpenSingleNonComplianceFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveNonComplianceStarted(
      state,
      { payload }: PayloadAction<SaveNonCompliancePayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveNonComplianceSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<NonCompliance>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byBlock = state.byWork[result.obraId]

      if (byBlock) {
        !byBlock.includes(result.id) && byBlock.push(result.id)
      } else state.byWork[result.obraId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveNonComplianceFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteNonComplianceStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteNonComplianceSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      Object.keys(state.byWork).forEach(page => {
        const items = state.byWork[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteNonComplianceFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Selectors
 * ==================================================
 */

/**
 * Cria key para acessar status e errors quando item é carregado por um relacionamento
 */
export const createByWorkKey = (workId: string) => `work.${workId}`

/**
 * Seleciona items na page atual
 */
export const getNonCompliancesOnCurrentPage = createSelector(
  ({ nonCompliance }) => {
    const { pagination, pages } = nonCompliance

    return pages[pagination.current] || []
  }
)

export const getNonComplianceById = (id: string) =>
  createSelector(({ nonCompliance }) => nonCompliance.items[id])

export const getNonComplianceStatusById = (id: string) =>
  createSelector(({ nonCompliance }) => nonCompliance.status[id])

export const getNonCompliancesByWork = (workId: string) =>
  createSelector(state => state.nonCompliance.byWork[workId])

export const getListRoutineWork = (id: string) =>
  createSelector(state => state.listRoutineWork.itemsList[id])

export const getNonCompliancesStatusByWork = (workId: string) =>
  createSelector(state => state.nonCompliance.status[createByWorkKey(workId)])

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  loadNonCompliancesStarted,
  loadNonCompliancesSuccess,
  loadNonCompliancesFail,
  loadNonCompliancesFromWorkStarted,
  loadNonCompliancesFromWorkSuccess,
  loadNonCompliancesFromWorkFail,
  loadSingleNonComplianceStarted,
  loadSingleNonComplianceSuccess,
  loadListRoutineWorkStarted,
  loadListRoutineWorkSuccess,
  loadListRoutineWorkFail,
  loadSingleNonComplianceFail,
  loadOpenSingleNonComplianceStarted,
  loadOpenSingleNonComplianceSuccess,
  loadOpenSingleNonComplianceFail,
  saveNonComplianceStarted,
  saveNonComplianceSuccess,
  saveNonComplianceFail,
  deleteNonComplianceStarted,
  deleteNonComplianceSuccess,
  deleteNonComplianceFail
} = actions

export default nonComplianceReducer
