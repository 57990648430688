import { SaveResourcePayload } from 'types/global'
import { Upload } from 'upload/api/uploadApi'
import { User } from 'user/api/userApi'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export enum EventType {
  New = 'CriarNaoConformidade',
  Update = 'AlterarNaoConformidade',
  Evaluate = 'AvaliarNaoConformidade',
  Close = 'EncerrarNaoConformidade',
  Delete = 'ExcluirNaoConformidade',
  DispatchCI = 'EnviarCINaoConformidade',
  SendFine = 'EnviarMultaNaoConformidade',
  RemoveCI = 'RemoverCINaoConformidade',
  RemoveFine = 'RemoverMultaNaoConformidade'
}

export interface Event {
  id: string
  tipoEvento: EventType
  observacao?: string
  dataCriacao: Date
  dataNovaVisita?: Date
  pessoa: string
  uploads: string[]
}

export interface NonComplianceEvent {
  id: string
  observacao: string
  tipoEvento: string
  obraId: string
  tipoNaoConformidadeId: string
  naoConformidadeId: string
  uploads: Upload[]
  dataCriacao: string
  dataNovaVisita: string
  pessoa: User
}

interface SaveEventPayloadExtraFields {
  obraId: string
  tipoNaoConformidadeId?: string
  naoConformidadeId?: string
  dataCriacao?: Date
  dataNovaVisita?: Date
  uploadsIds: string[]
}

export type SaveEventPayload = Omit<
  SaveResourcePayload<Event>,
  'dataCriacao' | 'userId' | 'id' | 'uploads' | 'pessoa'
> &
  SaveEventPayloadExtraFields

export async function saveNonComplianceEvent(payload: SaveEventPayload) {
  try {
    const endpoint = `/Obra/eventonaoconformidade`
    const { data } = await api.post<Event>(endpoint, payload)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
