import {
  SubmitWorkToAnalisis,
  saveSubmitWorkToAnalisis
} from 'submitWorkToAnalisis/api/submitWorkToAnalisisApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveSubmitWorkToAnalisisFail,
  saveSubmitWorkToAnalisisStarted,
  saveSubmitWorkToAnalisisSuccess
} from 'submitWorkToAnalisis/reducers/submitWorkToAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const submitWorkToAnalisisSchema = new schema.Entity<
  SubmitWorkToAnalisis
>('submitWorkToAnalisis')

export function* handleSaveSubmitWorkToAnalisis({
  payload
}: PayloadAction<SubmitWorkToAnalisis>) {
  try {
    const response = yield call(saveSubmitWorkToAnalisis, payload)

    const { entities } = normalize(response, submitWorkToAnalisisSchema)

    return yield put(
      saveSubmitWorkToAnalisisSuccess({
        forItem: 'new',
        result: entities.submitWorkToAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveSubmitWorkToAnalisisFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* submitWorkToAnalisisSaga() {
  yield takeLeading(
    saveSubmitWorkToAnalisisStarted.type,
    handleSaveSubmitWorkToAnalisis
  )
}

export default submitWorkToAnalisisSaga
