import {
  FetchCategoriaNaoConformidadeOptions,
  SaveCategoriaNaoConformidadePayload,
  NonComplianceCategory
} from 'nonComplianceCategory/api/nonComplianceCategoryApi'
import { Pagination, RequestError, ResourceFailPayload } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface CategoriaNaoConformidadeState {
  status: {
    [key: string]:
      | 'Pending'
      | 'Saving'
      | 'Done'
      | 'Error'
      | 'Deleting'
      | undefined
  }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: NonComplianceCategory | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: CategoriaNaoConformidadeState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: categoriaNaoConformidadeReducer } = createSlice({
  name: 'categoriaNaoConformidade',
  initialState,
  reducers: {
    fetchCategoriaNaoConformidadeStarted(
      state,
      action: PayloadAction<FetchCategoriaNaoConformidadeOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchCategoriaNaoConformidadeSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: NonComplianceCategory }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchCategoriaNaoConformidadeFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    fetchSingleCategoriaNaoConformidadeStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    fetchSingleCategoriaNaoConformidadeSuccess(
      state,
      { payload }: PayloadAction<NonComplianceCategory>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    fetchSingleCategoriaNaoConformidadeFail(
      state,
      { payload }: PayloadAction<{ id: string; error?: RequestError }>
    ) {
      state.status[payload.id] = 'Error'
      state.errors[payload.id] = payload.error

      return state
    },
    saveCategoriaNaoConformidadeStarted(
      state,
      { payload }: PayloadAction<SaveCategoriaNaoConformidadePayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveCategoriaNaoConformidadeSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forItem: string | 'new'
        result: NonComplianceCategory
      }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveCategoriaNaoConformidadeFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteCategoriaNaoConformidadeStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteCategoriaNaoConformidadeSuccess(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      for (const page in state.pages) {
        const items = state.pages[page]
        items && remove(items, payload)
      }

      return state
    },
    deleteCategoriaNaoConformidadeFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

export const getCategoriaNaoConformidadeById = (id: string) =>
  createSelector(({ nonComplianceCategory }) => nonComplianceCategory.items[id])

export const categoriaNaoConformidadeOnPage = createSelector(
  ({ nonComplianceCategory }) => {
    const { pagination, pages } = nonComplianceCategory

    return pages[pagination.current] || []
  }
)

export const {
  fetchCategoriaNaoConformidadeFail,
  fetchCategoriaNaoConformidadeStarted,
  fetchCategoriaNaoConformidadeSuccess,
  fetchSingleCategoriaNaoConformidadeFail,
  fetchSingleCategoriaNaoConformidadeStarted,
  fetchSingleCategoriaNaoConformidadeSuccess,
  saveCategoriaNaoConformidadeFail,
  saveCategoriaNaoConformidadeStarted,
  saveCategoriaNaoConformidadeSuccess,
  deleteCategoriaNaoConformidadeFail,
  deleteCategoriaNaoConformidadeStarted,
  deleteCategoriaNaoConformidadeSuccess
} = actions

export default categoriaNaoConformidadeReducer
