import {
  FetchItemsChecklistOptions,
  ItemChecklistResponse,
  SaveItemChecklistPayload,
  deleteItemUpload,
  fetchItemsChecklistByWork,
  saveItemChecklist,
  ItemChecklistDeleteOptions
} from 'ItemsChecklist/api/itemsChecklistApi'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import {
  deleteItemChecklistUploadFail,
  deleteItemChecklistUploadStarted,
  deleteItemChecklistUploadSuccess,
  fetchItemsChecklistFail,
  fetchItemsChecklistStarted,
  fetchItemsChecklistSuccess,
  saveItemChecklistFail,
  saveItemChecklistStarted,
  saveItemChecklistSuccess
} from 'ItemsChecklist/reducers/itemChecklistReducer'
import { normalize, schema } from 'normalizr'

import { PayloadAction } from '@reduxjs/toolkit'
import { Upload } from 'upload/api/uploadApi'
import { saveUploadsSuccess } from 'upload/reducers/uploadReducer'

export const itemChecklistSchema = new schema.Entity('itemChecklist')

export function* handleFetchItemsChecklist(
  action: PayloadAction<FetchItemsChecklistOptions>
) {
  try {
    const response = yield call(fetchItemsChecklistByWork, action.payload)

    const { entities } = normalize(response, [itemChecklistSchema])

    const isObra = true && action && action.payload.isObra

    const items = (entities.itemChecklist || []) as {
      [key: string]: ItemChecklistResponse
    }

    const keys = Object.keys(items)
    const uploads: Upload[] = []

    keys.forEach((id: string) => {
      const item = items[id]
      const itemUploads = item?.itemChecklists?.[0].itemChecklistUploads

      itemUploads?.forEach(itemUpload => {
        uploads.push(itemUpload.upload)
      })
    })

    yield put(saveUploadsSuccess(uploads))

    return yield put(
      fetchItemsChecklistSuccess({
        result: entities.itemChecklist || [],
        forWork: (action && action.payload.obraId) || '',
        isObra
      })
    )
  } catch (error) {
    return yield put(fetchItemsChecklistFail(error))
  }
}

export function* handleSaveItemChecklist({
  payload
}: PayloadAction<SaveItemChecklistPayload>) {
  try {
    const response = yield call(saveItemChecklist, payload)

    const { entities } = normalize(response, itemChecklistSchema)

    return yield put(
      saveItemChecklistSuccess({
        forItem: payload.id || 'new',
        result: entities.itemChecklist[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveItemChecklistFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteItemChecklistUpload({
  payload
}: PayloadAction<ItemChecklistDeleteOptions>) {
  try {
    yield call(deleteItemUpload, payload)

    return yield put(deleteItemChecklistUploadSuccess(payload.id))
  } catch (error) {
    return yield put(
      deleteItemChecklistUploadFail({
        forItem: payload.id,
        error
      })
    )
  }
}

function* itemChecklistSaga() {
  yield takeEvery(fetchItemsChecklistStarted.type, handleFetchItemsChecklist)

  yield takeLeading(saveItemChecklistStarted.type, handleSaveItemChecklist)
  yield takeLeading(
    deleteItemChecklistUploadStarted.type,
    handleDeleteItemChecklistUpload
  )
}

export default itemChecklistSaga
