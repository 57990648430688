import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  PaymentAttachmentAnalisis,
  SavePaymentAttachmentAnalisisPayload
} from 'paymentAttachment/api/paymentAttachmenAnalisisApi'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface PaymentAttachmentAnalisisState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: PaymentAttachmentAnalisis | undefined }
}

const initialState: PaymentAttachmentAnalisisState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: paymentAttachmentAnalisisReducer } = createSlice({
  name: 'paymentAttachmentAnalisis',
  initialState,
  reducers: {
    savePaymentAttachmentAnalisisStarted(
      state,
      { payload }: PayloadAction<SavePaymentAttachmentAnalisisPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    savePaymentAttachmentAnalisisSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<PaymentAttachmentAnalisis>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    savePaymentAttachmentAnalisisFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  savePaymentAttachmentAnalisisStarted,
  savePaymentAttachmentAnalisisSuccess,
  savePaymentAttachmentAnalisisFail
} = actions

export default paymentAttachmentAnalisisReducer
