import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'
import {
  deleteResource,
  fetchAll,
  fetchSingle,
  save
} from 'common/api/apiHelper'

import Action from 'notifications/enums/action'
import Event from 'notifications/enums/events'

export interface Notification {
  id: string
  nome: string
  email: string
  condominiumId: string
  acoes: Record<Action, boolean>
  eventos: Record<Event, boolean>
}

export type SaveNotificationPayload = SaveResourcePayload<Notification>
export type PaginatedNotificationResponse = PaginatedEndpointResponse<
  Notification
>
export type PaginatedNotificationOptions = PaginatedEndpointOptions<
  Notification
>

export const getNotifications = fetchAll<Notification>(
  '/Condominio/EmailManutencao'
)

export const getNotification = (condominiumId: string) =>
  fetchSingle<Notification>(
    id => `/Condominio/${condominiumId}/emailmanutencao/${id}`
  )

export const createNotification = (condominiumId: string) =>
  save<Notification>(`/Condominio/${condominiumId}/emailmanutencao`)

export const updateNotification = (id: string) =>
  save<Notification>(`/Condominio/EmailManutencao/${id}`)

export const deleteNotification = (condominiumId: string) =>
  deleteResource<Notification>(
    id => `/Condominio/${condominiumId}/emailmanutencao/${id}`
  )
