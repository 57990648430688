import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import Typology from 'condominium/enums/typology'
import { Upload } from 'upload/api/uploadApi'
import UseType from 'condominium/enums/useType'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface Condominium {
  id: string
  nome: string
  cnpj: string
  inscricaoEstadual: string
  logradouro: string
  numero: number
  complemento: string
  bairro: string
  cep: string
  tipologia: Typology
  tipoUso: UseType
  uploadId: string
  cidadeId: string
  cidadeDescricao?: string
  upload?: Upload
  urlDashboard?: string
  urlDashboardNaoConformidade?: string
  urlDashboardManutencao?: string
  prazoAnalise: number | string
  prazoReanalise: number | string
  prazoAnaliseDias: boolean
  prazoReanaliseDias: boolean
  exigeComprovantePagamento: boolean
  exigeComprovantePagamentoReforma: boolean
  prazoAnaliseCondominios: {
    id: string
    prazoAnalise: number
    prazoReanalise: number
    prazoAnaliseDias: boolean
    prazoReanaliseDias: boolean
    tipoProcesso: number | string
  }[]
}

export type FetchCondominiumsOptions = PaginatedEndpointOptions<Condominium>

export type FetchCondominiumsResponse = PaginatedEndpointResponse<Condominium>

/**
 * Carrega lista de condominios paginadas e filtradas pela query.
 */
export async function fetchCondominiums(options?: FetchCondominiumsOptions) {
  try {
    const { data } = await api.get<FetchCondominiumsResponse>('Condominio', {
      params: { ...options, include: 'Upload' }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um condominio pelo id.
 */
export async function fetchCondominium(id: string) {
  try {
    const { data } = await api.get<Condominium>(`Condominio/${id}`, {
      params: {
        include: ['Cidade', 'Upload']
      }
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveCondominiumPayload = SaveResourcePayload<Condominium>

/**
 * Salva um condominio, se houver id altera o existente.
 */
export async function saveCondominium(condominium: SaveCondominiumPayload) {
  try {
    const request = condominium.id ? api.put : api.post

    const { data } = await request<Condominium>('Condominio', condominium)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
