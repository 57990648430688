import { PaginatedEndpointOptions, SaveResourcePayload } from 'types/global'
import {
  deleteResource,
  fetchAll,
  fetchSingle,
  save
} from 'common/api/apiHelper'

import { Upload } from 'upload/api/uploadApi'

export interface SupportMaterial {
  id: string
  condominioId: string
  uploadId: string
  upload: Upload
  nome: string
}

export type SaveSupportMaterialPayload = SaveResourcePayload<
  Omit<SupportMaterial, 'upload'>
>
export type FetchSupportMaterialsOptions = PaginatedEndpointOptions<
  SupportMaterial
>

export const fetchSupportMaterials = fetchAll<SupportMaterial>('MaterialApoio')

export const fetchSingleSupportMaterial = fetchSingle<SupportMaterial>(
  id => `MaterialApoio/${id}`
)

export const saveSupportMaterial = save<Omit<SupportMaterial, 'upload'>>(
  'MaterialApoio'
)

export const deleteSupportMaterial = deleteResource<SupportMaterial>(
  id => `MaterialApoio/${id}`
)
