import {
  FetchTechnicalDocumentsOptions,
  SaveTechnicalDocumentPayload,
  TechnicalDocument,
  deleteTechnicalDocument,
  fetchSingleTechnicalDocument,
  fetchTechnicalDocuments,
  saveTechnicalDocument
} from 'technicalDocument/api/technicalDocumentApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteTechnicalDocumentFail,
  deleteTechnicalDocumentStarted,
  deleteTechnicalDocumentSuccess,
  loadSingleTechnicalDocumentFail,
  loadSingleTechnicalDocumentStarted,
  loadSingleTechnicalDocumentSuccess,
  loadTechnicalDocumentsFail,
  loadTechnicalDocumentsStarted,
  loadTechnicalDocumentsSuccess,
  saveTechnicalDocumentFail,
  saveTechnicalDocumentStarted,
  saveTechnicalDocumentSuccess
} from 'technicalDocument/reducers/technicalDocumentReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const technicalDocumentSchema = new schema.Entity<TechnicalDocument>(
  'technicalDocument'
)

export function* handleLoadTechnicalDocuments(
  action: PayloadAction<FetchTechnicalDocumentsOptions | undefined>
) {
  try {
    const response = yield call(fetchTechnicalDocuments, action.payload)

    const { entities } = normalize(response.result, [technicalDocumentSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadTechnicalDocumentsSuccess({
        result: entities.technicalDocument || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadTechnicalDocumentsFail(error))
  }
}

export function* handleLoadSingleTechnicalDocument(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchSingleTechnicalDocument, action.payload)
    const { entities } = normalize(response, technicalDocumentSchema)

    return yield put(
      loadSingleTechnicalDocumentSuccess(
        entities.technicalDocument[response.id]
      )
    )
  } catch (error) {
    return yield put(
      loadSingleTechnicalDocumentFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveTechnicalDocument({
  payload
}: PayloadAction<SaveTechnicalDocumentPayload>) {
  try {
    const response = yield call(saveTechnicalDocument, payload)

    const { entities } = normalize(response, technicalDocumentSchema)

    return yield put(
      saveTechnicalDocumentSuccess({
        forItem: payload.id || 'new',
        result: entities.technicalDocument[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveTechnicalDocumentFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteTechnicalDocument({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteTechnicalDocument, payload)

    return yield put(deleteTechnicalDocumentSuccess(payload))
  } catch (error) {
    return yield put(
      deleteTechnicalDocumentFail({
        forItem: payload,
        error
      })
    )
  }
}

function* technicalDocumentSaga() {
  yield takeLatest(
    loadTechnicalDocumentsStarted.type,
    handleLoadTechnicalDocuments
  )
  yield takeLatest(
    loadSingleTechnicalDocumentStarted.type,
    handleLoadSingleTechnicalDocument
  )
  yield takeLeading(
    saveTechnicalDocumentStarted.type,
    handleSaveTechnicalDocument
  )
  yield takeLeading(
    deleteTechnicalDocumentStarted.type,
    handleDeleteTechnicalDocument
  )
}

export default technicalDocumentSaga
