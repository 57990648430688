import {
  FetchNonComplianceTypesOptions,
  NonComplianceType,
  SaveNonComplianceTypePayload
} from 'nonComplianceType/api/nonComplianceTypeApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface NonComplianceTypeState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  byCondominium: { [page: string]: string[] | undefined }
  items: { [key: string]: NonComplianceType | undefined }
}

const initialState: NonComplianceTypeState = {
  items: {},
  pages: {},
  byCondominium: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: nonComplianceTypeReducer } = createSlice({
  name: 'nonComplianceType',
  initialState,
  reducers: {
    loadNonComplianceTypesStarted(
      state,
      action: PayloadAction<FetchNonComplianceTypesOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadNonComplianceTypesSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: NonComplianceType }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadNonComplianceTypesFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadNonComplianceTypesFromCondominiumStarted(
      state,
      action: PayloadAction<string>
    ) {
      const key = createByCondominiumKey(action.payload)
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadNonComplianceTypesFromCondominiumSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forCondominium: string
        result: { [id: string]: NonComplianceType }
      }>
    ) {
      const { result, forCondominium } = payload
      const key = createByCondominiumKey(forCondominium)

      state.status[key] = 'Done'
      state.errors[key] = undefined
      state.byCondominium[forCondominium] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadNonComplianceTypesFromCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = createByCondominiumKey(payload.forItem)

      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadSingleNonComplianceTypeStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleNonComplianceTypeSuccess(
      state,
      { payload }: PayloadAction<NonComplianceType>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleNonComplianceTypeFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveNonComplianceTypeStarted(
      state,
      { payload }: PayloadAction<SaveNonComplianceTypePayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveNonComplianceTypeSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<NonComplianceType>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byBlock = state.byCondominium[result.condominioId]

      if (byBlock) {
        !byBlock.includes(result.id) && byBlock.push(result.id)
      } else state.byCondominium[result.condominioId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveNonComplianceTypeFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteNonComplianceTypeStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteNonComplianceTypeSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      Object.keys(state.byCondominium).forEach(page => {
        const items = state.byCondominium[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteNonComplianceTypeFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Selectors
 * ==================================================
 */

/**
 * Cria key para acessar status e errors quando item é carregado por um relacionamento
 */
export const createByCondominiumKey = (condominiumId: string) =>
  `condominium.${condominiumId}`

/**
 * Seleciona items na page atual
 */
export const getNonComplianceTypesOnCurrentPage = createSelector(
  ({ nonComplianceType }) => {
    const { pagination, pages } = nonComplianceType

    return pages[pagination.current] || []
  }
)

export const getNonComplianceTypeById = (id: string) =>
  createSelector(({ nonComplianceType }) => nonComplianceType.items[id])

export const getNonComplianceTypeStatusById = (id: string) =>
  createSelector(({ nonComplianceType }) => nonComplianceType.status[id])

export const getNonComplianceTypesByCondominium = (condominiumId: string) =>
  createSelector(state => state.nonComplianceType.byCondominium[condominiumId])

export const getNonComplianceTypesStatusByCondominium = (
  condominiumId: string
) =>
  createSelector(
    state =>
      state.nonComplianceType.status[createByCondominiumKey(condominiumId)]
  )

export const selectCurrentPage = createSelector(({ nonComplianceType }) => {
  const { pagination, pages } = nonComplianceType

  return pages[pagination.current]
})

export const selectPagination = createSelector(
  ({ nonComplianceType }) => nonComplianceType.pagination
)

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  loadNonComplianceTypesStarted,
  loadNonComplianceTypesSuccess,
  loadNonComplianceTypesFail,
  loadNonComplianceTypesFromCondominiumStarted,
  loadNonComplianceTypesFromCondominiumSuccess,
  loadNonComplianceTypesFromCondominiumFail,
  loadSingleNonComplianceTypeStarted,
  loadSingleNonComplianceTypeSuccess,
  loadSingleNonComplianceTypeFail,
  saveNonComplianceTypeStarted,
  saveNonComplianceTypeSuccess,
  saveNonComplianceTypeFail,
  deleteNonComplianceTypeStarted,
  deleteNonComplianceTypeSuccess,
  deleteNonComplianceTypeFail
} = actions

export default nonComplianceTypeReducer
