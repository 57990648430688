import ItemChecklistStatus from 'ItemsChecklist/enums/status'
import { UserWorkProfile } from 'user/api/userApi'
import { fetchSingle } from 'common/api/apiHelper'
import objetoRequerimento from 'itemTypeChecklist/enums/objetoRequerimento'
import workStatus from 'work/enums/workStatus'

export interface ProcessChecklistItems {
  id: string
  aprovado?: boolean
  tipoItemChecklistNome: string
  tipoItemChecklistObjetoRequerimento: objetoRequerimento
  tipoItemChecklistOpcional: boolean
  uploadsCount: number
  comentariosCount: number
  tipoItemChecklistEstagioDoProcesso: string
}

export interface Process {
  id: string
  codigo: string
  status: workStatus
  areaConstruida: number
  areaAConstruir: number
  areaTotal: number
  areaTerreno: number
  checklistProcesso: ItemChecklistStatus
  checklistInicioObra: ItemChecklistStatus
  checklistFimObra: ItemChecklistStatus
  comprovanteEnviado: boolean
  comprovanteAprovado?: boolean
  itensInicioObraEnviado: boolean
  itensFimObraEnviado: boolean
  itensProcessoEnviado: boolean
  processoArquivado: boolean
  projetoHomologadoEnviado?: boolean
  exigeComprovantePagamento?: boolean
  exigeComprovantePagamentoReforma?: boolean
  comprovanteAprovadoAvaliado?: boolean
  projetoAprovadoAvaliado?: boolean
  podeFinalizar?: boolean
  podeIniciar?: boolean
  emReanalise?: boolean
  reanalisado?: boolean
  VistoriaAprovada?: boolean
  habitadaComRessalva: boolean
  habitada?: boolean
  possueRessalvaObra?: boolean
  possueRessalvaDocumentacao?: boolean
  cartaLiberacaoEnviada: boolean
  cartaLiberacaoAprovada: boolean
  possuiCartaDeLiberacao: boolean
  comprovantePagamentoCount: number
  projetoAvaliado?: boolean
  obraIniciadaSemAutorizacao?: boolean
  cartaLiberacaoObrigatoria?: boolean
  itemChecklists: ProcessChecklistItems[]
  loteArea: string
  tipoProcesso: string | number
  comprovantePagamento: {
    id: string
    aprovado?: boolean
    uploadId: string
    comentariosCount: number
  }
  projeto: {
    id: string
    aprovado?: boolean
    uploadsCount: number
    comentariosCount: number
  }
  pessoasAssociadas: {
    id: string
    pessoaId: string
    pessoaNome: string
    pessoaEmail: string
    cargoObra: UserWorkProfile
  }[]
  lote: {
    numero: number
    complemento: string
    area: string
    quadraId: string
    quadra: {
      nome: number
      ruaId: string
      rua: {
        nome: string
      }
    }
  }
}

export const fetchProcessByWorkId = fetchSingle<Process>(
  id => `Obra/${id}/Processo`
)
