import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'

import { RealeaseLetter } from 'releaseLetter/api/releaseLetter'
import { RootState } from 'store'
import { getReleaseLetterThunk } from 'releaseLetter/thunks/releaseLetter'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

export interface ReleaseLetterState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const releaseLetterStateAdapter = createEntityAdapter<RealeaseLetter>()

const initialState: ReleaseLetterState = {
  status: {},
  errors: {}
}
const { actions, reducer: releaseLetterReducer } = createSlice({
  name: 'releaseLetter',
  initialState: releaseLetterStateAdapter.getInitialState(initialState),
  reducers: {
    addReleaseLetter: releaseLetterStateAdapter.upsertMany
  },
  extraReducers(builder) {
    builder.addCase(getReleaseLetterThunk.pending, (state, action) => {
      const itemId = action.meta.arg
      state.status[itemId] = 'Pending'
    })

    builder.addCase(getReleaseLetterThunk.fulfilled, (state, action) => {
      const itemId = action.meta.arg

      state.status[itemId] = 'Done'

      action.payload &&
        releaseLetterStateAdapter.upsertMany(state, action.payload)
    })

    builder.addCase(getReleaseLetterThunk.rejected, (state, action) => {
      const itemId = action.meta.arg
      state.status[itemId] = 'Error'
      state.errors[itemId] = action.payload as any
    })
  }
})

// --------------------------------------------------
// Selectors
// --------------------------------------------------
export const releaseLetterSelectors = releaseLetterStateAdapter.getSelectors()

export const getByWorkId = (workId: string) => ({
  releaseLetter
}: RootState) => {
  const { entities } = releaseLetter
  const keys = Object.keys(entities)
  const ids = keys.filter(id => entities[id]?.obraId === workId)

  return ids.map(id => entities[id])
}

export const getNotEvaluatedId = (workId: string) => ({
  releaseLetter
}: RootState) =>
  releaseLetterSelectors
    .selectAll(releaseLetter)
    .find(item => item.aprovada === undefined && item.obraId === workId)?.id

export const getReleaseLetterStatus = (id: string) => ({
  releaseLetter
}: RootState) => releaseLetter.status[id]

export const { addReleaseLetter } = actions

export default releaseLetterReducer
