import { Flex, Spinner } from '@chakra-ui/core'
import React, { FC } from 'react'

const LoadingLayout: FC = props => {
  return (
    <>
      <Flex
        justifyContent='center'
        alignItems='center'
        height='100vh'
        width='100vw'
        bg='white'
        style={{ position: 'absolute', zIndex: 999 }}
        {...props}
      >
        <Spinner />
      </Flex>
      {props.children}
    </>
  )
}

export default LoadingLayout
