import {
  PaymentAttachmentAnalisis,
  SavePaymentAttachmentAnalisisPayload,
  savePaymentAttachmentAnalisis
} from 'paymentAttachment/api/paymentAttachmenAnalisisApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  savePaymentAttachmentAnalisisFail,
  savePaymentAttachmentAnalisisStarted,
  savePaymentAttachmentAnalisisSuccess
} from 'paymentAttachment/reducers/PaymentAttachmentAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const paymentAttachmentAnalisisSchema = new schema.Entity<
  PaymentAttachmentAnalisis
>('paymentAttachmentAnalisis')

export function* handleSavePaymentAttachmentAnalisis({
  payload
}: PayloadAction<SavePaymentAttachmentAnalisisPayload>) {
  try {
    const response = yield call(savePaymentAttachmentAnalisis, payload)

    const { entities } = normalize(response, paymentAttachmentAnalisisSchema)

    return yield put(
      savePaymentAttachmentAnalisisSuccess({
        forItem: payload.id || 'new',
        result: entities.paymentAttachmentAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      savePaymentAttachmentAnalisisFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* paymentAttachmentAnalisisSaga() {
  yield takeLeading(
    savePaymentAttachmentAnalisisStarted.type,
    handleSavePaymentAttachmentAnalisis
  )
}

export default paymentAttachmentAnalisisSaga
