import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface NonComplianceCategory {
  id: string
  condominioId: string
  titulo: string
  rvtci: number
  rvtMulta: number
  multaRepete: boolean | string
}

export type FetchCategoriaNaoConformidadeOptions = PaginatedEndpointOptions<
  NonComplianceCategory
>

export type FetchCategoriaNaoConformidadeResponse = PaginatedEndpointResponse<
  NonComplianceCategory
>

/**
 * Carrega lista de categoria de não conformidade paginadas e filtradas pela query.
 */
export async function fetchNonComplianceCategories(
  options?: FetchCategoriaNaoConformidadeOptions
) {
  try {
    const { data } = await api.get<FetchCategoriaNaoConformidadeResponse>(
      'CategoriaNaoConformidade',
      {
        params: options
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um categoria de não conformidade pelo id.
 */
export async function fetchNonComplianceCategory(id: string) {
  try {
    const { data } = await api.get<NonComplianceCategory>(
      `CategoriaNaoConformidade/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveCategoriaNaoConformidadePayload = SaveResourcePayload<
  NonComplianceCategory
>

/**
 * Salva um categoria de não conformidade, se houver id altera o existente.
 */
export async function saveCategoriaNaoConformidade(
  categoriaNaoConformidade: SaveCategoriaNaoConformidadePayload
) {
  try {
    const request = categoriaNaoConformidade.id ? api.put : api.post

    const { data } = await request<NonComplianceCategory>(
      categoriaNaoConformidade.id
        ? `CategoriaNaoConformidade/${categoriaNaoConformidade.id}`
        : 'CategoriaNaoConformidade',
      categoriaNaoConformidade
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma categoria de não conformidade pelo id.
 */
export async function deleteCategoriaNaoConformidade(id: string) {
  try {
    const { data } = await api.delete<NonComplianceCategory>(
      `CategoriaNaoConformidade/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
