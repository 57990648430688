import { User, fetchUser, fetchUsers } from 'user/api/userApi'

import createSelectResource from 'app/components/selects/createSelectResource'

const SelectUser = createSelectResource<User>({
  idKey: 'id',
  labelKey: 'nome',
  resourceName: 'User',
  resourcesLoader: (query: string) => {
    return fetchUsers({
      TermoBusca: query
    })
  },
  singleResourceLoader: id => {
    return fetchUser(id)
  },
  isEmptyQueryValid: true
})

export default SelectUser
