import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ResourceStatus } from 'types/global'
import { workStartedIrregularlyThunk } from 'WorkStartedIrregularly/thunks/workStartedIrregularly'

/**
 * Interfaces
 */

export interface WorkStartedIrregularlyState {
  status: Dictionary<ResourceStatus>
}

/**
 * Slice
 */

const adapter = createEntityAdapter<{}>()

const { reducer } = createSlice({
  name: 'workStartedIrregularly',
  initialState: adapter.getInitialState<WorkStartedIrregularlyState>({
    status: {}
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(workStartedIrregularlyThunk.pending, state => {
      state.status['new'] = 'Pending'
    })

    builder.addCase(workStartedIrregularlyThunk.fulfilled, state => {
      state.status['new'] = 'Done'
    })

    builder.addCase(workStartedIrregularlyThunk.rejected, state => {
      state.status['new'] = 'Error'
    })
  }
})

export default reducer
