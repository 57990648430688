import { BoxProps, Flex, Grid as ChakraGrid } from '@chakra-ui/core'
import React, { FC } from 'react'

export interface GridProps extends BoxProps {
  mode: 'flex' | 'grid'
}

/**
 * Componente para simplificar uso de CSS Flexbox e CSS Grid
 */
export const Grid: FC<GridProps> = ({ mode, children, ...props }) => {
  if (mode === 'flex') return <Flex {...props}>{children}</Flex>
  return <ChakraGrid {...props}>{children}</ChakraGrid>
}

export default Grid
