import {
  AppLayout,
  clearBreadcrumbs,
  switchLayout,
  updateBreadcrumbs,
  updatePageTitle
} from 'layout/reducers/layoutReducer'

import { Breadcrumb } from 'carbono/Breadcrumbs'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import useTypedSelector from 'common/hooks/useTypedSelector'

export interface UseLayoutOptions {
  layout: AppLayout
  title: string
  breadcrumbs?: Breadcrumb[]
}

function useLayout(options: UseLayoutOptions) {
  const dispatch = useDispatch()
  const currentPageTitle = useTypedSelector(({ layout }) => layout.pageTitle)

  useEffect(() => {
    dispatch(switchLayout(options.layout))
  }, [options.layout])

  useEffect(() => {
    dispatch(updatePageTitle(options.title))
  }, [options.title])

  useEffect(() => {
    if (options.breadcrumbs) dispatch(updateBreadcrumbs(options.breadcrumbs))
    else dispatch(clearBreadcrumbs())
  }, [options.breadcrumbs])

  useEffect(() => {
    document.title = `Haprojet - ${currentPageTitle}`
  }, [currentPageTitle])
}

export default useLayout
