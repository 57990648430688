import { Button as ChakraButton, IconButton, Stack } from '@chakra-ui/core'
import React, { FunctionComponent } from 'react'

import Icon from './Icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SpaceProps } from 'styled-system'

type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'outline'
type ButtonShape = 'circle'

interface ButtonProps extends SpaceProps, React.HTMLProps<HTMLButtonElement> {
  variant?: ButtonVariant
  shape?: ButtonShape
  icon?: IconProp
  isLoading?: boolean
  type?: any
  as?: any
}

const mappedVariants = {
  primary: 'green',
  danger: 'red',
  secondary: 'gray',
  outline: undefined,
  other: undefined
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  variant,
  icon,
  isLoading,
  ref,
  size: _,
  shape,
  ...props
}) => {
  const iconProp = icon
    ? () => (
        <Stack justify='center' mr={children ? 2 : 0}>
          <Icon icon={icon} />
        </Stack>
      )
    : undefined

  if (shape === 'circle')
    return (
      <IconButton
        isRound
        variantColor={mappedVariants[variant ?? 'other']}
        variant={'outline'}
        size='sm'
        aria-label=''
        isLoading={isLoading}
        icon={iconProp}
        {...(props as any)}
      />
    )

  return (
    <ChakraButton
      variant={variant === 'primary' ? 'solid' : 'outline'}
      variantColor={mappedVariants[variant ?? 'other']}
      leftIcon={iconProp}
      size='sm'
      isLoading={isLoading}
      {...(props as any)}
    >
      {children}
    </ChakraButton>
  )
}

export default Button
