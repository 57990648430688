import store from 'store'

import { refreshStarted } from 'auth/reducers/authReducer'

/**
 * Envia um dispatch para iniciar refresh da token.
 *
 * Se inscreve na store e monitora mudanças no status e na token.
 * Quando status muda e também a token, resolve a promise e para de observar a store.
 */
const awaitUntilTokenIsRenewed = () => {
  return new Promise<string | undefined>(resolve => {
    let unsubscribe: any = undefined
    const previousToken = store.getState().auth.token

    store.dispatch(refreshStarted())
    unsubscribe = store.subscribe(() => {
      const status = store.getState().auth.status
      const isCompleted = status === 'AUTH' || status === 'GUEST'
      const token = store.getState().auth.token

      if (isCompleted && previousToken !== token) {
        unsubscribe()
        resolve(token)
      }
    })
  })
}

export default awaitUntilTokenIsRenewed
