import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface AddRoutineExistingWork {
  id: string
  obraId: string
  rotinaId: string
}

export type FetchAddRoutineExistingWorkOptions = PaginatedEndpointOptions<
  AddRoutineExistingWork
>

export type FetchAddRoutineExistingWorkResponse = PaginatedEndpointResponse<
  AddRoutineExistingWork
>

export type SaveAddRoutineExistingWorkPayload = SaveResourcePayload<
  AddRoutineExistingWork
>

export async function saveAddRoutineExistingWork(
  Options?: SaveAddRoutineExistingWorkPayload
) {
  try {
    const { data } = await api.post<AddRoutineExistingWork[]>(
      `Obra/${Options?.obraId}/Rotina/AdicionaRotinaNaObra`,
      Options
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
