import { SpaceProps, space } from 'styled-system'

import css from '@styled-system/css'
import styled from 'styled-components'

export const Divider = styled.div<SpaceProps>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1px;

  ${css({
    mt: 2,
    mb: 2,
    bg: 'gray.200'
  })}

  ${space}
`

Divider.defaultProps = {
  role: 'separator'
}

export default Divider
