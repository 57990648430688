import { fetchAll, fetchSingle } from 'common/api/apiHelper'

import { PaginatedEndpointOptions } from 'types/global'
import { State } from 'state/api/stateApi'

/**
 * Interfaces
 */
export interface City {
  id: string
  descricao: string
  estadoId: string
  estado: State
}

export type FetchCitiesOptions = PaginatedEndpointOptions<City>

export const fetchCities = fetchAll<City>('Cidade')

export const fetchCitiesByStateId = fetchSingle<City>(
  (id: string) => `Estado/${id}/cidades`
)
