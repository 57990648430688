import {
  SaveWorkInitPayload,
  WorkInit,
  saveWorkInit
} from 'work/api/workInitApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveWorkInitFail,
  saveWorkInitStarted,
  saveWorkInitSuccess
} from 'work/reducers/workInitReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const workInitSchema = new schema.Entity<WorkInit>('workInit')

export function* handleSaveWorkInit({
  payload
}: PayloadAction<SaveWorkInitPayload>) {
  try {
    const response = yield call(saveWorkInit, payload)

    const { entities } = normalize(response, workInitSchema)

    return yield put(
      saveWorkInitSuccess({
        forItem: payload.id || 'new',
        result: entities.workInit[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveWorkInitFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* workInitSaga() {
  yield takeLeading(saveWorkInitStarted.type, handleSaveWorkInit)
}

export default workInitSaga
