import {
  fetchTypesRoutine,
  fetchTypeRoutine,
  FetchTypeRoutineOptions,
  SaveTypeRoutinePayload,
  EditTypeRoutinePayload,
  editTypeRoutine,
  deleteItemTypeRoutine,
  deleteRoutineCondominium,
  saveTypeRoutine,
  TypeRoutine,
  DeleteItemTypeRoutine,
  fetchListRoutineByIdInWork,
  ListRoutineByIdInWorkOptions,
  fetchTypeRoutineByNameAndTypeRoutine,
  DeleteRoutineCondominiumOptions
} from 'typeRoutine/api/typeRoutineApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  saveTypeRoutineFail,
  saveTypeRoutineStarted,
  saveTypeRoutineSuccess,
  fetchTypeRoutineFail,
  fetchTypeRoutineStarted,
  fetchSingleTypeRoutineFail,
  fetchSingleTypeRoutineSuccess,
  fetchTypeRoutineSuccess,
  fetchSingleTypeRoutineStarted,
  editTypeRoutineSuccess,
  editTypeRoutineFail,
  editTypeRoutineStarted,
  deleteTypeRoutineStarted,
  deleteTypeRoutineSuccess,
  deleteTypeRoutineFail,
  deleteRoutineCondominiumStarted,
  deleteRoutineCondominiumSuccess,
  deleteRoutineCondominiumFail,
  fetchSingleListRoutinebyIdInWorkStarted,
  fetchSingleListRoutinebyIdInWorkSuccess,
  fetchSingleListRoutinebyIdInWorkFail,
  fetchTypeRoutineByNameAndTypeRoutineStarted,
  fetchTypeRoutineByNameAndTypeRoutineSuccess,
  fetchTypeRoutineByNameAndTypeRoutineFail
} from 'typeRoutine/reducers/typeRoutineReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const typeRoutineSchema = new schema.Entity<TypeRoutine>('typeRoutine')

export function* handleFetchTypeRoutine(
  action: PayloadAction<FetchTypeRoutineOptions | undefined>
) {
  try {
    const response = yield call(fetchTypesRoutine, action.payload)

    const { entities } = normalize(response.result, [typeRoutineSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      fetchTypeRoutineSuccess({
        result: entities.typeRoutine || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchTypeRoutineFail(error))
  }
}

export function* handleFetchSingleTypeRoutine(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchTypeRoutine, action.payload)
    const { entities } = normalize(response, typeRoutineSchema)

    return yield put(
      fetchSingleTypeRoutineSuccess(entities.typeRoutine[response.id])
    )
  } catch (error) {
    return yield put(
      fetchSingleTypeRoutineFail({
        id: action.payload,
        error
      })
    )
  }
}
export function* handleFetchSingleRoutineByIdInWork({
  payload
}: PayloadAction<ListRoutineByIdInWorkOptions>) {
  try {
    yield call(fetchListRoutineByIdInWork, payload)

    return yield put(fetchSingleListRoutinebyIdInWorkSuccess(payload))
  } catch (error) {
    return yield put(
      fetchSingleListRoutinebyIdInWorkFail({
        id: payload.id,
        error
      })
    )
  }
}
export function* handleFetchTypeRoutineByNameAndTypeRoutine(
  action: PayloadAction<FetchTypeRoutineOptions | undefined>
) {
  try {
    const response = yield call(
      fetchTypeRoutineByNameAndTypeRoutine,
      action.payload
    )

    const { entities } = normalize(response.result, [typeRoutineSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      fetchTypeRoutineByNameAndTypeRoutineSuccess({
        result: entities.typeRoutine || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchTypeRoutineByNameAndTypeRoutineFail(error))
  }
}
export function* handleSaveTypeRoutine({
  payload
}: PayloadAction<SaveTypeRoutinePayload>) {
  try {
    const response = yield call(saveTypeRoutine, payload)

    const { entities } = normalize(response, typeRoutineSchema)

    return yield put(
      saveTypeRoutineSuccess({
        forItem: payload.id || 'new',
        result: entities.typeRoutine[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveTypeRoutineFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleEditTypeRoutine({
  payload
}: PayloadAction<EditTypeRoutinePayload>) {
  try {
    const response = yield call(editTypeRoutine, payload)

    const { entities } = normalize(response, typeRoutineSchema)

    return yield put(
      editTypeRoutineSuccess({
        forItem: payload.id || 'new',
        result: entities.typeRoutine[response.id]
      })
    )
  } catch (error) {
    return yield put(
      editTypeRoutineFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}
export function* handleDeleteTypeRoutine({
  payload
}: PayloadAction<DeleteItemTypeRoutine>) {
  try {
    yield call(deleteItemTypeRoutine, payload)

    return yield put(deleteTypeRoutineSuccess(payload.id))
  } catch (error) {
    return yield put(
      deleteTypeRoutineFail({
        forItem: payload.id,
        error
      })
    )
  }
}
export function* handleDeleteRoutineCondominum({
  payload
}: PayloadAction<DeleteRoutineCondominiumOptions>) {
  try {
    yield call(deleteRoutineCondominium, payload)

    return yield put(deleteRoutineCondominiumSuccess(payload.id))
  } catch (error) {
    return yield put(
      deleteRoutineCondominiumFail({
        forItem: payload.id,
        error
      })
    )
  }
}

function* typeRoutineSaga() {
  yield takeLatest(fetchTypeRoutineStarted.type, handleFetchTypeRoutine)
  yield takeLatest(
    fetchSingleTypeRoutineStarted.type,
    handleFetchSingleTypeRoutine
  )
  yield takeLatest(
    fetchSingleListRoutinebyIdInWorkStarted.type,
    handleFetchSingleRoutineByIdInWork
  )
  yield takeLatest(
    fetchTypeRoutineByNameAndTypeRoutineStarted.type,
    handleFetchTypeRoutineByNameAndTypeRoutine
  )
  yield takeLeading(saveTypeRoutineStarted.type, handleSaveTypeRoutine)

  yield takeLatest(editTypeRoutineStarted.type, handleEditTypeRoutine)
  yield takeLeading(deleteTypeRoutineStarted.type, handleDeleteTypeRoutine)
  yield takeLeading(
    deleteRoutineCondominiumStarted.type,
    handleDeleteRoutineCondominum
  )
}
export default typeRoutineSaga
