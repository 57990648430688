import {
  SaveSubmitToAnalisisPayload,
  SubmitToAnalisis,
  saveSubmitToAnalisis
} from 'project/api/submitToAnalisisApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveSubmitToAnalisisFail,
  saveSubmitToAnalisisStarted,
  saveSubmitToAnalisisSuccess
} from 'project/reducers/submitToAnalisisReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const submitToAnalisisSchema = new schema.Entity<SubmitToAnalisis>(
  'submitToAnalisis'
)

export function* handleSaveSubmitToAnalisis({
  payload
}: PayloadAction<SaveSubmitToAnalisisPayload>) {
  try {
    const response = yield call(saveSubmitToAnalisis, payload)

    const { entities } = normalize(response, submitToAnalisisSchema)

    return yield put(
      saveSubmitToAnalisisSuccess({
        forItem: payload.id || 'new',
        result: entities.submitToAnalisis[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveSubmitToAnalisisFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* submitToAnalisisSaga() {
  yield takeLeading(
    saveSubmitToAnalisisStarted.type,
    handleSaveSubmitToAnalisis
  )
}

export default submitToAnalisisSaga
