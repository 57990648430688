import { ItemTypeChecklist } from 'itemTypeChecklist/api/itemTypeChecklistApi'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface ItemChecklistApproval {
  itemChecklistId: string
  mensagem: string
  aprovado: boolean
}

export async function saveItemChecklistApproval(
  itemChecklistApproval: ItemChecklistApproval
) {
  try {
    const { data } = await api.post<ItemTypeChecklist>(
      'Obra/itemsChecklist/avaliarItem',
      itemChecklistApproval
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
