import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ItemsChecklistAnalisis {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type ItemsChecklistAnalisisStage = 'Final' | 'Initial'

export type SaveInitialWorkItemsPayload = SaveResourcePayload<
  ItemsChecklistAnalisis
>

export type SaveCompletionWorkItemsPayload = SaveResourcePayload<
  ItemsChecklistAnalisis
>

export const saveInitialWorkItems = (obraId: string) =>
  save<ItemsChecklistAnalisis>(`Obra/${obraId}/SubmeterItemchecklistInicioObra`)

export const saveCompletionWorkItems = (obraId: string) =>
  save<ItemsChecklistAnalisis>(`Obra/${obraId}/SubmeterItemchecklistFimObra`)
