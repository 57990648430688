import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

import { InhabitedWithReservations } from 'work/api/inhabitedWithReservations'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface InhabitedWithReservationsState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: InhabitedWithReservations | undefined }
}

const initialState: InhabitedWithReservationsState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: InhabitedWithReservationsReducer } = createSlice({
  name: 'inhabitedWithReservations',
  initialState,
  reducers: {
    saveInhabitedWithReservationsStarted(
      state,
      { payload }: PayloadAction<InhabitedWithReservations>
    ) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveInhabitedWithReservationsSuccess(
      state,
      {
        payload
      }: PayloadAction<SaveResourceSuccessPayload<InhabitedWithReservations>>
    ) {
      const { forItem, result } = payload

      state.items[forItem] = result
      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveInhabitedWithReservationsFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveInhabitedWithReservationsStarted,
  saveInhabitedWithReservationsSuccess,
  saveInhabitedWithReservationsFail
} = actions

export default InhabitedWithReservationsReducer
