import {
  FetchLotsOptions,
  Lot,
  SaveLotPayload,
  deleteLot,
  fetchLot,
  fetchLots,
  fetchLotsFromBlock,
  saveLot
} from 'lot/api/lotApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteLotFail,
  deleteLotStarted,
  deleteLotSuccess,
  loadLotsFail,
  loadLotsFromBlockFail,
  loadLotsFromBlockStarted,
  loadLotsFromBlockSuccess,
  loadLotsStarted,
  loadLotsSuccess,
  loadSingleLotFail,
  loadSingleLotStarted,
  loadSingleLotSuccess,
  saveLotFail,
  saveLotStarted,
  saveLotSuccess
} from 'lot/reducers/lotReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const lotSchema = new schema.Entity<Lot>('lot')

export function* handleLoadLots(
  action: PayloadAction<FetchLotsOptions | undefined>
) {
  try {
    const response = yield call(fetchLots, action.payload)

    const { entities } = normalize(response.result, [lotSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      loadLotsSuccess({
        result: entities.lot ?? [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadLotsFail(error))
  }
}

export function* handleLoadLotsFromBlock(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchLotsFromBlock, action.payload)

    const { entities } = normalize(response, [lotSchema])

    return yield put(
      loadLotsFromBlockSuccess({
        result: entities.lot,
        forBlock: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadLotsFromBlockFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleLot(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchLot, action.payload)
    const { entities } = normalize(response, lotSchema)

    return yield put(loadSingleLotSuccess(entities.lot[response.id]))
  } catch (error) {
    return yield put(
      loadSingleLotFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveLot({ payload }: PayloadAction<SaveLotPayload>) {
  try {
    const response = yield call(saveLot, payload)

    const { entities } = normalize(response, lotSchema)

    return yield put(
      saveLotSuccess({
        forItem: payload.id || 'new',
        result: entities.lot[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveLotFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteLot({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteLot, payload)

    return yield put(deleteLotSuccess(payload))
  } catch (error) {
    return yield put(
      deleteLotFail({
        forItem: payload,
        error
      })
    )
  }
}

function* lotSaga() {
  yield takeLatest(loadLotsStarted.type, handleLoadLots)
  yield takeLatest(loadLotsFromBlockStarted.type, handleLoadLotsFromBlock)
  yield takeLatest(loadSingleLotStarted.type, handleLoadSingleLot)
  yield takeLeading(saveLotStarted.type, handleSaveLot)
  yield takeLeading(deleteLotStarted.type, handleDeleteLot)
}

export default lotSaga
