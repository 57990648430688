import { Dictionary, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RequestError, ResourceStatus } from 'types/global'

import { RootState } from 'store'
import { ShelveProcess } from 'process/api/shelveProcess'
import { shelveProcessThunk } from 'process/thunk/shelveProcess'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

export interface ShelveProcessState {
  status: Dictionary<ResourceStatus>
  errors: Dictionary<RequestError>
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------
const itemAdapter = createEntityAdapter<ShelveProcess>()

const initialState: ShelveProcessState = {
  status: {},
  errors: {}
}

const { actions, reducer: shelveProcessReducer } = createSlice({
  name: 'shelveProcess',
  initialState: itemAdapter.getInitialState(initialState),
  reducers: {
    addIncludedShelveProcessItems: itemAdapter.upsertMany
  },
  extraReducers(builder) {
    builder.addCase(shelveProcessThunk.pending, (state, action) => {
      const itemId = action.meta.arg.obraId

      state.status[itemId] = 'Saving'
      state.errors[itemId] = undefined
    })

    builder.addCase(shelveProcessThunk.fulfilled, (state, action) => {
      const itemId = action.meta.arg.obraId

      state.status[itemId] = 'Done'
      state.errors[itemId] = undefined

      itemAdapter.upsertOne(state, action.payload)
    })

    builder.addCase(shelveProcessThunk.rejected, (state, action) => {
      const itemId = action.meta.arg.obraId
      state.status[itemId] = 'Error'
      state.errors[itemId] = action.payload as any
    })
  }
})

// --------------------------------------------------
// Selectors
// --------------------------------------------------

export const { addIncludedShelveProcessItems } = actions

export const getStatusByWorkId = (id: string) => ({
  shelveProcess
}: RootState) => shelveProcess.status[id]

export default shelveProcessReducer
