import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import {
  SaveWorkCommentPayload,
  WorkComment
} from 'workComment/api/workCommentApi'

import createSelector from 'shared/createSelector'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Comentários
 */
export interface WorkCommentState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
}

const initialState: WorkCommentState = {
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: workCommentReducer } = createSlice({
  name: 'workComment',
  initialState,
  reducers: {
    saveWorkCommentStarted(
      state,
      { payload }: PayloadAction<SaveWorkCommentPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveWorkCommentSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<WorkComment>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined

      return state
    },
    saveWorkCommentFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const selectStatus = (id: string) =>
  createSelector(({ workComment }) => workComment.status[id])

export const {
  saveWorkCommentFail,
  saveWorkCommentStarted,
  saveWorkCommentSuccess
} = actions

export default workCommentReducer
