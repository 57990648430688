import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface RequestFinalSurvey {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveRequestFinalSurveyPayload = SaveResourcePayload<
  RequestFinalSurvey
>

export const saveRequestFinalSurvey = save<RequestFinalSurvey>(
  'Obra/SolicitarVistoriaFinal'
)
