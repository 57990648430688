import { PseudoBox } from '@chakra-ui/core'
import Box from 'carbono/Box'
import React, { FC } from 'react'
import styled from 'styled-components'

export const AuthLayout: FC = ({ children }) => {
  return (
    <PseudoBox
      display='grid'
      gridTemplateColumns='1fr minmax(300px, 400px)'
      bg='white'
      width='100vw'
      height='100vh'
      overflow='hidden'
    >
      {children}
    </PseudoBox>
  )
}

export const AuthHeroBg = styled(Box)<{ image: string }>`
  background: url(${require('./dark-dot.png')}), url(${props => props.image});
  background-size: auto, cover;
`

export const AuthContent: FC = ({ children }) => {
  return <PseudoBox overflow='auto'>{children}</PseudoBox>
}

// export const AuthContent = styled(Box)<FlexProps>`
//   overflow: auto;
//   ${css({
//     bg: 'panels.bg'
//   })}
//   ${flex}
// `
