import React, { FC } from 'react'

import { Rules } from 'userPermissions/api/userPermissionApi'
import usePermission from 'userPermissions/hooks/usePermission'

export interface CanProps {
  rules: Rules | Rules[]
  yes: React.ReactNode
  no?: React.ReactNode
}

const Can: FC<CanProps> = ({ rules, yes, no }) => (
  <>{usePermission(rules) ? yes : no}</>
)

export const CanContainer: FC<{ rules: Rules | Rules[] }> = ({
  children,
  rules
}) => {
  const hasPermission = usePermission(rules)
  if (!hasPermission) return null

  return <>{children}</>
}

export default Can
