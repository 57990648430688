/* eslint-disable */
import {
  Box,
  Divider,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  PseudoBox,
  Spinner,
  Text
} from '@chakra-ui/core'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import Option from './Option'
import Tippy from '@tippyjs/react/headless'
import useClickOutside from 'common/hooks/useClickOutsideNode'
import { useDebounce } from 'use-debounce'

const { useMenuListStyle } = require('@chakra-ui/core/dist/Menu/styles')

interface SelectProps<T> {
  idKey?: keyof T | string // Key que identifica o objeto. Ex: id
  labelKey?: keyof T | string // Key de descreve o objeto. Ex: name
  value?: T
  options: T[]
  renderOption?: FunctionComponent<{ item: T }>

  placeholder?: string
  isSearchable?: boolean
  disabled?: boolean
  searchPlaceholder?: string
  searchDelay?: number

  /**
   * State
   */
  isLoading?: boolean

  /**
   * Handlers
   */
  onChange?: (item?: T) => void
  onChangeQuery?: (query: string) => void
  onOpen?: () => void
  onClose?: () => void
  isSize?: 'small'
  onClear?: () => void
}

export function Select<T>({
  onChange,
  onChangeQuery,
  onOpen,
  onClose,
  onClear,
  renderOption: RenderOption,
  labelKey,
  idKey,
  disabled,
  ...props
}: SelectProps<T>) {
  const [isVisible, setVisibility] = useState(false)

  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, props.searchDelay || 500, {
    maxWait: 1000
  })

  const inputRef = useRef<HTMLInputElement | null>(null)
  const boxRef = useRef<HTMLInputElement | null>(null)
  useClickOutside(boxRef, (e: any) => {
    setVisibility(false)
  })
  const optionsListStyles = useMenuListStyle()

  /**
   * Renderiza uma option.
   *
   * Caso renderOption seja passado, é retornando componente com item como props.
   *
   * Caso a option seja uma string, retorna ela mesma.
   * Caso seja um object, usa-se o labekKey para acessar o valor a ser renderizado.
   */
  const getItemLabel = useCallback(
    (item: any) => {
      if (RenderOption) {
        return <RenderOption item={item} />
      }

      return typeof item === 'string' ? item : item?.[labelKey]
    },
    [labelKey, RenderOption]
  )

  const getItemId = useCallback(
    (item: any) => {
      if (typeof item === 'string') return item

      return item && idKey && item[idKey]
    },
    [idKey]
  )

  const carret = isVisible ? 'triangle-up' : 'triangle-down'

  const currentId = getItemId(props.value)
  const inputWidth = inputRef.current?.clientWidth ?? 0

  const optionsList = useMemo(() => {
    return props.options.map(item => {
      const itemId = getItemId(item)

      return (
        <Option
          key={getItemId(item)}
          isSelected={itemId === currentId}
          onClick={() => {
            onChange && onChange(item)
            setVisibility(false)
          }}
        >
          <Text fontSize='lg'>{getItemLabel(item)}</Text>
        </Option>
      )
    })
  }, [props.options, onChange, getItemLabel, getItemId, currentId])

  const searchInput = props.isSearchable && (
    <Input
      size='sm'
      value={query}
      onChange={({ target }: any) => setQuery(target.value)}
      placeholder={props.searchPlaceholder || 'Digite para procurar'}
    />
  )

  useEffect(() => {
    isVisible && onOpen && onOpen()
    !isVisible && onClose && onClose()
  }, [isVisible])

  useEffect(() => {
    onChangeQuery && onChangeQuery(debouncedQuery)
  }, [debouncedQuery])

  return (
    <div ref={boxRef}>
      <Tippy
        render={() => (
          <Box
            {...optionsListStyles}
            width={inputWidth}
            borderRadius='md'
            boxShadow='sm'
            maxHeight='40vh'
            overflow='auto'
            color='black !important'
          >
            {searchInput && <PseudoBox p={2}>{searchInput}</PseudoBox>}
            {props.isSearchable && <Divider m='0px' />}
            {optionsList}
          </Box>
        )}
        onTrigger={() => setVisibility(true)}
        onHide={() => setVisibility(false)}
        onHidden={() => setVisibility(false)}
        offset={[0, 4]}
        interactive
        visible={isVisible}
        disabled={disabled}
      >
        <div>
          <InputGroup>
            <Input
              color='black !important'
              cursor='pointer'
              as='div'
              ref={inputRef}
              onClick={() => {
                setVisibility(!isVisible)
              }}
            >
              {props.value ? getItemLabel(props.value) : props.placeholder}
            </Input>
            <InputRightElement
              children={
                props.isLoading ? (
                  <Spinner size='sm' />
                ) : (
                  <>
                    {onClear && props.value ? (
                      <IconButton
                        aria-label='clear'
                        fontSize='xs'
                        variant='ghost'
                        icon='small-close'
                        onClick={() => onClear && onClear()}
                      />
                    ) : (
                      <Icon
                        fontSize='xs'
                        name={carret}
                        onClick={() => setVisibility(!isVisible)}
                      />
                    )}
                  </>
                )
              }
            />
          </InputGroup>
        </div>
      </Tippy>
    </div>
  )
}

export default Select
