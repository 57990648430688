import { Upload, StagingUpload, saveStagingUpload } from 'upload/api/uploadApi'
import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLeading } from 'redux-saga/effects'
import { schema, normalize } from 'normalizr'
import {
  saveUploadFail,
  saveUploadStarted,
  saveUploadSuccess
} from 'upload/reducers/uploadReducer'

export const uploadSchema = new schema.Entity<Upload>('upload')

export function* handleSaveUpload({ payload }: PayloadAction<StagingUpload>) {
  try {
    const response = yield call(saveStagingUpload, payload)
    const { entities } = normalize(response, uploadSchema)

    return yield put(
      saveUploadSuccess({
        forItem: 'new',
        result: entities.upload[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUploadFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* uploadSaga() {
  yield takeLeading(saveUploadStarted.type, handleSaveUpload)
}

export default uploadSaga
