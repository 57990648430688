import React, { FC, useEffect } from 'react'
import useTypedSelector from 'common/hooks/useTypedSelector'
import { loadLotsFromBlockStarted } from 'lot/reducers/lotReducer'
import { useDispatch } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

export interface LotProps {
  blockId: string
  selected?: any
  setSelected: (item?: any) => void
  errorStatus?: boolean
  onModal?: boolean
}

const LotSelect: FC<LotProps> = ({
  blockId,
  selected,
  setSelected,
  errorStatus,
  onModal
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    setSelected()
    if (!blockId) return

    dispatch(loadLotsFromBlockStarted(blockId))
  }, [blockId])

  const lots = useTypedSelector(({ lot }) => {
    if (!blockId) return []

    const lots = lot.byBlock[blockId]
    return lots ? lots.map(item => lot.items[item]) : []
  })

  return (
    <Autocomplete
      disablePortal={onModal ? true : false}
      key={selected}
      value={selected || null}
      noOptionsText={'Não há lotes cadastrados para a quadra selecionada'}
      disabled={!blockId}
      id='lotSelect'
      size='small'
      onChange={(event, newValue) => {
        setSelected(newValue || '')
      }}
      options={lots}
      getOptionLabel={option => `${option?.numero}`}
      renderInput={params => (
        <TextField
          error={errorStatus}
          placeholder={
            !blockId
              ? 'Para selecionar um lote primeiro selecione uma quadra'
              : 'Selecione um lote'
          }
          {...params}
          variant='outlined'
        />
      )}
    ></Autocomplete>
  )
}

export default LotSelect
