import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ReviewProject {
  id: string
  obraId: string
  mensagem?: string
}

export type SaveReviewProjectPayload = SaveResourcePayload<ReviewProject>

export const saveReviewProject = save<ReviewProject>(
  'Obra/projeto/revisarProjeto'
)
