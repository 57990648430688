import { FetchLotsOptions, Lot, SaveLotPayload } from 'lot/api/lotApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createSelector from 'shared/createSelector'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface LotState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  byBlock: { [page: string]: string[] }
  items: { [key: string]: Lot | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: LotState = {
  items: {},
  pages: {},
  byBlock: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: lotReducer } = createSlice({
  name: 'lot',
  initialState,
  reducers: {
    loadLotsStarted(
      state,
      action: PayloadAction<FetchLotsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadLotsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Lot }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadLotsFail(state, { payload }: PayloadAction<RequestError | undefined>) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadLotsFromBlockStarted(state, action: PayloadAction<string>) {
      const key = `block.${action.payload}`
      state.status[key] = 'Pending'
      state.errors[key] = undefined

      return state
    },
    loadLotsFromBlockSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forBlock: string
        result: { [id: string]: Lot }
      }>
    ) {
      const { result, forBlock } = payload
      const key = `block.${forBlock}`

      state.status[key] = 'Done'
      state.errors[key] = undefined

      state.byBlock[forBlock] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadLotsFromBlockFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const key = `block.${payload.forItem}`
      state.status[key] = 'Error'
      state.errors[key] = payload.error

      return state
    },
    loadSingleLotStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleLotSuccess(state, { payload }: PayloadAction<Lot>) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleLotFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveLotStarted(state, { payload }: PayloadAction<SaveLotPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveLotSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Lot>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      const byBlock = state.byBlock[result.quadraId]

      if (byBlock) {
        !byBlock.includes(result.id) && byBlock.push(result.id)
      } else state.byBlock[result.quadraId] = [result.id]

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveLotFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteLotStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteLotSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = undefined
      state.errors[payload] = undefined
      state.items[payload] = undefined

      return state
    },
    deleteLotFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

export const getLotsOnCurrentPage = createSelector(({ lot }) => {
  const { pagination, pages } = lot

  return pages[pagination.current] || []
})

export const getLotById = (id: string) =>
  createSelector(({ lot }) => lot.items[id])

export const getLotStatusById = (id: string) =>
  createSelector(({ lot }) => lot.status[id])

export const {
  loadLotsStarted,
  loadLotsSuccess,
  loadLotsFail,
  loadLotsFromBlockStarted,
  loadLotsFromBlockSuccess,
  loadLotsFromBlockFail,
  loadSingleLotStarted,
  loadSingleLotSuccess,
  loadSingleLotFail,
  saveLotStarted,
  saveLotSuccess,
  saveLotFail,
  deleteLotStarted,
  deleteLotSuccess,
  deleteLotFail
} = actions

export default lotReducer
