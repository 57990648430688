import { MotionProps } from 'framer-motion'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  borderRadius,
  BorderRadiusProps,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'

export interface BoxProps
  extends ColorProps,
    LayoutProps,
    TypographyProps,
    SpaceProps,
    BorderProps,
    BorderRadiusProps,
    MotionProps {}
/**
 * Componente básico para criar layouts
 */
export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${compose(color, layout, border, borderRadius, typography, space)}
`

export default Box
