import {
  FetchUserCondominiumOptions,
  SaveUserCondominiumPayload,
  UserCondominium
} from 'condominium/api/UserCondominiumApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface UserCondominiumState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: UserCondominium | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
}

const initialState: UserCondominiumState = {
  items: {},
  status: {},
  errors: {},
  pages: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: userCondominiumReducer } = createSlice({
  name: 'userCondominium',
  initialState,
  reducers: {
    loadUserCondominiumStarted(
      state,
      { payload }: PayloadAction<FetchUserCondominiumOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadUserCondominiumSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: UserCondominium }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadUserCondominiumFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    saveUserCondominiumStarted(
      state,
      { payload }: PayloadAction<SaveUserCondominiumPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveUserCondominiumSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<UserCondominium>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveUserCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteUserCondominiumStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteUserCondominiumSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      remove(state.pages[state.pagination.current] ?? [], payload)

      return state
    },
    deleteUserCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    editUserCondominiumStarted(
      state,
      { payload }: PayloadAction<SaveUserCondominiumPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    editUserCondominiumSuccess(
      state,
      { payload }: PayloadAction<UserCondominium>
    ) {
      const { id } = payload

      state.status[id] = 'Done'
      state.errors[id] = undefined
      state.items[id] = payload

      return state
    },
    editUserCondominiumFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const userCondominiumOnPage = createSelector(({ userCondominium }) => {
  const { pagination, pages } = userCondominium

  return pages[pagination.current] || []
})

export const {
  saveUserCondominiumStarted,
  saveUserCondominiumSuccess,
  saveUserCondominiumFail,
  loadUserCondominiumFail,
  loadUserCondominiumStarted,
  loadUserCondominiumSuccess,
  deleteUserCondominiumFail,
  deleteUserCondominiumStarted,
  deleteUserCondominiumSuccess,
  editUserCondominiumStarted,
  editUserCondominiumSuccess,
  editUserCondominiumFail
} = actions

export default userCondominiumReducer
