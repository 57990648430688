import {
  FetchSubjectsOptions,
  SaveSubjectPayload,
  Subject
} from 'technicalDocument/api/subjects'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de documento técnico
 */
export interface SubjectState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  items: { [key: string]: Subject | undefined }
}

const initialState: SubjectState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: subjectReducer } = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    loadSubjectsStarted(
      state,
      { payload }: PayloadAction<FetchSubjectsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadSubjectsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Subject }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadSubjectsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadSingleSubjectStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleSubjectSuccess(state, { payload }: PayloadAction<Subject>) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleSubjectFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveSubjectStarted(state, { payload }: PayloadAction<SaveSubjectPayload>) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveSubjectSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<Subject>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveSubjectFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteSubjectStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteSubjectSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteSubjectFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const SubjectOnPage = createSelector(({ subject }) => {
  const { pagination, pages } = subject

  return pages[pagination.current] || []
})

export const {
  loadSubjectsStarted,
  loadSubjectsSuccess,
  loadSubjectsFail,
  loadSingleSubjectStarted,
  loadSingleSubjectSuccess,
  loadSingleSubjectFail,
  saveSubjectStarted,
  saveSubjectSuccess,
  saveSubjectFail,
  deleteSubjectStarted,
  deleteSubjectSuccess,
  deleteSubjectFail
} = actions

export default subjectReducer
