import { PseudoBox, PseudoBoxProps, FlexProps, Flex } from '@chakra-ui/core'
import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import Box from './Box'

export const Panel: FC<PseudoBoxProps> = ({ children, ...props }) => {
  return (
    <PseudoBox
      bg='white'
      display='flex'
      flexDirection='column'
      borderColor='gray.200'
      borderWidth={1}
      borderRadius='lg'
      boxShadow='sm'
      {...props}
    >
      {children}
    </PseudoBox>
  )
}
//  styled(Box)`
//   display: flex;
//   flex-direction: column;

//   ${css({
//     color: 'panels.text',
//     backgroundColor: 'panels.bg',
//     borderRadius: 'default',
//     boxShadow: 'default'
//   })}
// `

export const PanelHeader: FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      fontSize='lg'
      fontWeight='medium'
      justifyContent='space-between'
      alignItems='center'
      px={3}
      py={2}
      borderBottomWidth={1}
      borderBottomColor='gray.200'
      {...props}
    >
      {children}
    </Flex>
  )
}
export const PanelActions = styled(Box)`
  ${css({
    display: 'flex',
    columnGap: 2,
    justifyContent: 'flex-end'
  })}
`

export const PanelBody = styled(Box)`
  flex-grow: 1;
  ${css({
    px: 3,
    py: 2
  })}
`

export const PanelFooter: FC = styled(Box)`
  ${css({
    px: 3,
    py: 2,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderTopColor: 'panels.border'
  })}
`
