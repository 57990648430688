import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface ItemChecklistDisapproveAnalisis {
  id: string
  obraId: string
  mensagem: string
  uploadIds: string[]
}

export type SaveItemChecklistDisapproveAnalisiskPayload = SaveResourcePayload<
  ItemChecklistDisapproveAnalisis
>

export const saveItemChecklistDisapproveAnalisisk = save<
  ItemChecklistDisapproveAnalisis
>('Obra/reprovarAnalise')
