import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface RemoveCaveat {
  id: string
  obraId: string
  mensagem?: string
}

export type SaveRemoveCaveatPayload = SaveResourcePayload<RemoveCaveat>

export const removeCaveat = (obraId: string) =>
  save<RemoveCaveat>(`Obra/${obraId}/RemoveRessalvaDocumentacao`)
