import { ReanalyzeProccess, edit } from 'work/api/reanalyzeProccess'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  saveReanalyzeProccessFail,
  saveReanalyzeProccessStarted,
  saveReanalyzeProccessSuccess
} from 'work/reducers/reanalyzeProccessReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export function* handleSaveReanalyzeProccess({
  payload
}: PayloadAction<ReanalyzeProccess>) {
  try {
    const response = yield call(edit, payload)

    return yield put(
      saveReanalyzeProccessSuccess({
        forItem: payload.obraId || 'new',
        result: response
      })
    )
  } catch (error) {
    return yield put(
      saveReanalyzeProccessFail({
        forItem: payload.obraId || 'new',
        error
      })
    )
  }
}

function* reanalyzeProccessSaga() {
  yield takeLeading(
    saveReanalyzeProccessStarted.type,
    handleSaveReanalyzeProccess
  )
}

export default reanalyzeProccessSaga
