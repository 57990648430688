import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ReactiveProject,
  SaveReactiveProjectPayload
} from 'project/api/reactivateProject'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Não-Conformidades
 */
export interface ReactiveProjectState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ReactiveProject | undefined }
}

const initialState: ReactiveProjectState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: reactiveProjectReducer } = createSlice({
  name: 'reactiveProject',
  initialState,
  reducers: {
    saveReactiveProjectStarted(
      state,
      { payload }: PayloadAction<SaveReactiveProjectPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveReactiveProjectSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<ReactiveProject>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveReactiveProjectFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  saveReactiveProjectStarted,
  saveReactiveProjectSuccess,
  saveReactiveProjectFail
} = actions

export default reactiveProjectReducer
