import React, { FC, SVGProps } from 'react'

export interface AppLogoProps {
  fillColor?: string
}

const AppLogo: FC<SVGProps<SVGSVGElement> & AppLogoProps> = props => {
  return (
    <svg width={265.75} height={67.88} viewBox='0 0 265.75 67.88' {...props}>
      <title>{'Logo Otimizado'}</title>
      <path
        d='M30.81 49.77a2.7 2.7 0 01-2.71 2.7h-2.29a2.83 2.83 0 01-2.06-1 2.76 2.76 0 01-.64-1.74V21.66H7.7v28.11a2.71 2.71 0 01-2.7 2.7H2.71a2.77 2.77 0 01-2-.84A2.77 2.77 0 010 49.77v-47A2.8 2.8 0 01.72.87a2.83 2.83 0 012-.87H5a2.82 2.82 0 012 1 2.77 2.77 0 01.7 1.77V14h20.4a2.86 2.86 0 012.1 1 2.73 2.73 0 01.61 1.71zM77 49.77a2.71 2.71 0 01-2.71 2.7H72a2.71 2.71 0 01-2.71-2.7V21.66H48.93A2.71 2.71 0 0146.22 19v-2.33a2.75 2.75 0 01.71-1.83 2.81 2.81 0 012-.88h25.39a2.79 2.79 0 012.05.92 2.74 2.74 0 01.63 1.79z'
        fill={props.fillColor || '#9ec41a'}
      />
      <path
        d='M60.78 30.12a2.82 2.82 0 00-1.86-.76h-17.7a2.91 2.91 0 00-2.11 1 2.81 2.81 0 00-.6 1.68v17.7a2.71 2.71 0 002.71 2.7H59a2.69 2.69 0 001.74-.7 2.79 2.79 0 00.89-2V32a2.71 2.71 0 00-.85-1.88zm-6.86 14.65h-7.7v-7.7h7.7zM114.84 14.85a2.79 2.79 0 00-2-.89h-25.4a2.92 2.92 0 00-2.17 1.09 2.82 2.82 0 00-.54 1.61v48.51a2.84 2.84 0 00.86 2 2.76 2.76 0 001.85.74h2.29a2.82 2.82 0 002-.89 2.76 2.76 0 00.72-1.82V52.47h20.4a2.76 2.76 0 001.86-.75 2.79 2.79 0 00.85-1.95V16.66a2.79 2.79 0 00-.72-1.81zm-7 29.92h-15.4V21.66h15.41zM131 49.77a2.71 2.71 0 01-2.7 2.7H126a2.76 2.76 0 01-1.89-.77 2.79 2.79 0 01-.82-1.93V16.66A2.71 2.71 0 01126 14h17.5a2.76 2.76 0 011.83.72 2.81 2.81 0 01.87 2V19a2.73 2.73 0 01-2.7 2.71H131zM181.17 16.66a2.83 2.83 0 00-1-2.08 2.76 2.76 0 00-1.73-.62h-25.37a2.82 2.82 0 00-2 .84 2.78 2.78 0 00-.75 1.79v33.25a2.71 2.71 0 002.71 2.63h25.39a2.71 2.71 0 002.71-2.63zm-7.7 28.11h-15.41V21.66h15.41z'
        fill={props.fillColor || '#9ec41a'}
        fillRule='evenodd'
      />
      <path
        d='M168.47 67.88h25.4a2.73 2.73 0 002.71-2.71V16.66a2.75 2.75 0 00-.71-1.82 2.81 2.81 0 00-2-.88h-2.29a2.8 2.8 0 00-2 .93 2.83 2.83 0 00-.67 1.77v43.52h-20.4a2.71 2.71 0 00-2.7 2.7v2.29a2.8 2.8 0 00.71 1.84 2.84 2.84 0 001.95.87zM231.8 37.07a3 3 0 002.34-.66 2.93 2.93 0 001-2V16.66a2.82 2.82 0 00-.88-2 2.78 2.78 0 00-1.82-.7H207a2.73 2.73 0 00-2.71 2.63v33.18a2.71 2.71 0 002.71 2.7h25.4a2.72 2.72 0 002.7-2.7v-2.29a2.87 2.87 0 00-1.1-2.13 2.93 2.93 0 00-2.24-.58H212v-7.7zM212 21.66h15.4v7.7H212zM250.5 49.77a2.71 2.71 0 01-2.71 2.7h-2.28a2.71 2.71 0 01-2.71-2.7V2.71a2.81 2.81 0 01.89-2 2.82 2.82 0 011.82-.7h2.28a2.73 2.73 0 012.71 2.71V14h12.55a2.71 2.71 0 012.7 2.7V19a2.79 2.79 0 01-.71 1.82 2.83 2.83 0 01-2 .89H250.5z'
        fill={props.fillColor || '#9ec41a'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default AppLogo
