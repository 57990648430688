import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface FinishProcess {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveFinishProcessPayload = SaveResourcePayload<FinishProcess>

export const saveFinishProcess = save<FinishProcess>('Obra/EncerrarProcesso')
