import {
  ForgotPassword,
  ResetPassword,
  forgotPassword,
  resetPassword
} from 'user/api/userApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  saveForgotPasswordFail,
  saveForgotPasswordStarted,
  saveForgotPasswordSuccess,
  saveResetPasswordFail,
  saveResetPasswordStarted,
  saveResetPasswordSuccess
} from 'user/reducers/passwordReducer'

import { PayloadAction } from '@reduxjs/toolkit'
import { schema } from 'normalizr'

export const forgotPasswordSchema = new schema.Entity<ForgotPassword>(
  'forgotPassword'
)

export function* handleSaveForgotPassword({
  payload
}: PayloadAction<ForgotPassword>) {
  try {
    yield call(forgotPassword, payload)

    return yield put(saveForgotPasswordSuccess())
  } catch (error) {
    return yield put(
      saveForgotPasswordFail({
        forItem: 'new',
        error
      })
    )
  }
}

export const resetPasswordSchema = new schema.Entity<ResetPassword>(
  'resetPassword'
)

export function* handleSaveResetPassword({
  payload
}: PayloadAction<ResetPassword>) {
  try {
    yield call(resetPassword, payload)

    return yield put(saveResetPasswordSuccess())
  } catch (error) {
    return yield put(
      saveResetPasswordFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* forgotPasswordSaga() {
  yield takeLeading(saveForgotPasswordStarted.type, handleSaveForgotPassword)
  yield takeLeading(saveResetPasswordStarted.type, handleSaveResetPassword)
}

export default forgotPasswordSaga
