import {
  FetchSubjectsOptions,
  SaveSubjectPayload,
  Subject,
  deleteSubject,
  fetchSingleSubject,
  fetchSubjects,
  saveSubject
} from 'technicalDocument/api/subjects'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteSubjectFail,
  deleteSubjectStarted,
  deleteSubjectSuccess,
  loadSingleSubjectFail,
  loadSingleSubjectStarted,
  loadSingleSubjectSuccess,
  loadSubjectsFail,
  loadSubjectsStarted,
  loadSubjectsSuccess,
  saveSubjectFail,
  saveSubjectStarted,
  saveSubjectSuccess
} from 'technicalDocument/reducers/subject'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const subjectSchema = new schema.Entity<Subject>('subject')

export function* handleLoadSubjects(
  action: PayloadAction<FetchSubjectsOptions | undefined>
) {
  try {
    const response = yield call(fetchSubjects, action.payload)

    const { entities } = normalize(response.result, [subjectSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadSubjectsSuccess({
        result: entities.subject || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadSubjectsFail(error))
  }
}

export function* handleLoadSingleSubject(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchSingleSubject, action.payload)
    const { entities } = normalize(response, subjectSchema)

    return yield put(loadSingleSubjectSuccess(entities.subject[response.id]))
  } catch (error) {
    return yield put(
      loadSingleSubjectFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveSubject({
  payload
}: PayloadAction<SaveSubjectPayload>) {
  try {
    const response = yield call(saveSubject, payload)

    const { entities } = normalize(response, subjectSchema)

    return yield put(
      saveSubjectSuccess({
        forItem: payload.id || 'new',
        result: entities.subject[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveSubjectFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteSubject({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteSubject, payload)

    return yield put(deleteSubjectSuccess(payload))
  } catch (error) {
    return yield put(
      deleteSubjectFail({
        forItem: payload,
        error
      })
    )
  }
}

function* subjectSaga() {
  yield takeLatest(loadSubjectsStarted.type, handleLoadSubjects)
  yield takeLatest(loadSingleSubjectStarted.type, handleLoadSingleSubject)
  yield takeLeading(saveSubjectStarted.type, handleSaveSubject)
  yield takeLeading(deleteSubjectStarted.type, handleDeleteSubject)
}

export default subjectSaga
