import {
  FinishProcess,
  SaveFinishProcessPayload,
  saveFinishProcess
} from 'work/api/finishProcessApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveFinishProcessFail,
  saveFinishProcessStarted,
  saveFinishProcessSuccess
} from 'work/reducers/finishProcessReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const finishProcessSchema = new schema.Entity<FinishProcess>(
  'finishProcess'
)

export function* handleSaveFinishProcess({
  payload
}: PayloadAction<SaveFinishProcessPayload>) {
  try {
    const response = yield call(saveFinishProcess, payload)

    const { entities } = normalize(response, finishProcessSchema)

    return yield put(
      saveFinishProcessSuccess({
        forItem: payload.id || 'new',
        result: entities.finishProcess[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveFinishProcessFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* finishProcessSaga() {
  yield takeLeading(saveFinishProcessStarted.type, handleSaveFinishProcess)
}

export default finishProcessSaga
