import {
  ReviewProject,
  SaveReviewProjectPayload,
  saveReviewProject
} from 'project/api/reviewProjectApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveReviewProjectFail,
  saveReviewProjectStarted,
  saveReviewProjectSuccess
} from 'project/reducers/reviewProjectReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const reviewProjectSchema = new schema.Entity<ReviewProject>(
  'reviewProject'
)

export function* handleSaveReviewProject({
  payload
}: PayloadAction<SaveReviewProjectPayload>) {
  try {
    const response = yield call(saveReviewProject, payload)

    const { entities } = normalize(response, reviewProjectSchema)

    return yield put(
      saveReviewProjectSuccess({
        forItem: payload.id || 'new',
        result: entities.ReviewProject[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveReviewProjectFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* reviewProjectSaga() {
  yield takeLeading(saveReviewProjectStarted.type, handleSaveReviewProject)
}

export default reviewProjectSaga
