import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

import { ReanalyzeProccess } from 'work/api/reanalyzeProccess'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface ReanalyzeProccessState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  items: { [key: string]: ReanalyzeProccess | undefined }
}

const initialState: ReanalyzeProccessState = {
  items: {},
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: ReanalyzeProccessReducer } = createSlice({
  name: 'reanalyzeProccess',
  initialState,
  reducers: {
    saveReanalyzeProccessStarted(
      state,
      { payload }: PayloadAction<ReanalyzeProccess>
    ) {
      state.status[payload.obraId || 'new'] = 'Saving'
      state.errors[payload.obraId || 'new'] = undefined

      return state
    },
    saveReanalyzeProccessSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<ReanalyzeProccess>>
    ) {
      const { forItem, result } = payload

      state.items[forItem] = result
      state.status[forItem] = 'Done'
      state.errors[forItem] = undefined

      return state
    },
    saveReanalyzeProccessFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  saveReanalyzeProccessStarted,
  saveReanalyzeProccessSuccess,
  saveReanalyzeProccessFail
} = actions

export default ReanalyzeProccessReducer
