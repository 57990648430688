import {
  FetchUsersOptions,
  SaveUserPayload,
  User,
  fetchUser,
  fetchUsers,
  saveUser
} from 'user/api/userApi'
import {
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects'
import {
  loadSingleUserFail,
  loadSingleUserStarted,
  loadSingleUserSuccess,
  loadUsersFail,
  loadUsersStarted,
  loadUsersSuccess,
  saveUserFail,
  saveUserStarted,
  saveUserSuccess
} from 'user/reducers/userReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const userSchema = new schema.Entity<User>('user')

export function* handleSaveUser({ payload }: PayloadAction<SaveUserPayload>) {
  try {
    const response = yield call(saveUser, payload)

    const { entities } = normalize(response, userSchema)

    return yield put(
      saveUserSuccess({
        forItem: payload.id || 'new',
        result: entities.user[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUserFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleLoadSingleUser(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchUser, action.payload)

    const { entities } = normalize(response, userSchema)

    return yield put(loadSingleUserSuccess(entities.user[response.id]))
  } catch (error) {
    return yield put(
      loadSingleUserFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadUsers(action: PayloadAction<FetchUsersOptions>) {
  try {
    const response = yield call(fetchUsers, action.payload)

    const { entities } = normalize(response.result, [userSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadUsersSuccess({
        result: entities.user || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadUsersFail(error))
  }
}

function* userSaga() {
  yield takeLeading(saveUserStarted.type, handleSaveUser)
  yield takeEvery(loadSingleUserStarted.type, handleLoadSingleUser)
  yield takeLatest(loadUsersStarted.type, handleLoadUsers)
}

export default userSaga
