import { PaginatedEndpointOptions, SaveResourcePayload } from 'types/global'
import {
  deleteResource,
  fetchAll,
  fetchSingle,
  save
} from 'common/api/apiHelper'

export interface Subject {
  id: string
  descricao: string
  condominioId: string
}

export type SaveSubjectPayload = SaveResourcePayload<Omit<Subject, 'upload'>>
export type FetchSubjectsOptions = PaginatedEndpointOptions<Subject>

export const fetchSubjects = fetchAll<Subject>('Disciplina')

export const fetchSingleSubject = fetchSingle<Subject>(id => `Disciplina/${id}`)

export const saveSubject = save<Subject>('Disciplina')

export const deleteSubject = deleteResource<Subject>(id => `Disciplina/${id}`)
