import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

export interface ItemTypeChecklist {
  id: string
  nome: string
  opcional: boolean | string
  isObra?: boolean
  aprovado?: boolean
  condominioId: string
  estagioDoProcesso: number | string
  tipoProcesso: number | string
}

export type FetchItemsTypeChecklistOptions = PaginatedEndpointOptions<
  ItemTypeChecklist
>

export type FetchItemsTypeChecklistResponse = PaginatedEndpointResponse<
  ItemTypeChecklist
>

/**
 * Carrega lista de tipo de item checklist paginadas e filtradas pela query.
 */
export async function fetchItemsTypeChecklist(
  options?: FetchItemsTypeChecklistOptions
) {
  try {
    const { data } = await api.get<FetchItemsTypeChecklistResponse>(
      'TipoItemChecklist',
      {
        params: options
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega um tipo de item checklist pelo id.
 */
export async function fetchItemTypeChecklist(id: string) {
  try {
    const { data } = await api.get<ItemTypeChecklist>(`TipoItemChecklist/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveItemTypeChecklistPayload = SaveResourcePayload<
  ItemTypeChecklist
>

/**
 * Salva um tipo de item checklist, se houver id altera o existente.
 */
export async function saveItemTypeChecklist(
  itemTypeChecklist: SaveItemTypeChecklistPayload
) {
  try {
    const request = itemTypeChecklist.id ? api.put : api.post

    const { data } = await request<ItemTypeChecklist>(
      'TipoItemChecklist',
      itemTypeChecklist
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma Obra pelo id.
 */
export async function deleteItemTypeChecklist(id: string) {
  try {
    const { data } = await api.delete<ItemTypeChecklist>(
      `TipoItemChecklist/${id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
