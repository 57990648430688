import { PaginatedEndpointOptions } from 'types/global'
import { fetchAll } from 'common/api/apiHelper'

/**
 * Interfaces
 */
export interface State {
  id: string
  descricao: string
  sigla: string
}
export type FetchStatesOptions = PaginatedEndpointOptions<State>

export const fetchStates = fetchAll<State>('Estado')
