import { AtualizaAcessoBody, atualizaAcesso } from 'work/api/workApi'
import { call, put, takeEvery } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveUpdateAccessFail,
  saveUpdateAccessStarted,
  saveUpdateAccessSuccess
} from 'work/reducers/updateAccessReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const AtualizaAcessoBodySchema = new schema.Entity<AtualizaAcessoBody>(
  'AtualizaAcessoBody'
)

export function* handleSaveAtualizaAcessoBody({
  payload
}: PayloadAction<AtualizaAcessoBody>) {
  try {
    const response = yield call(atualizaAcesso, payload)

    const { entities } = normalize(response, AtualizaAcessoBodySchema)

    return yield put(
      saveUpdateAccessSuccess({
        forItem: 'new',
        result: entities.AtualizaAcessoBody[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUpdateAccessFail({
        forItem: 'new',
        error
      })
    )
  }
}

function* AtualizaAcessoBodySaga() {
  yield takeEvery(saveUpdateAccessStarted.type, handleSaveAtualizaAcessoBody)
}

export default AtualizaAcessoBodySaga
