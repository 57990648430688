import {
  Project,
  SaveProjectPayload,
  deleteProjectUpload,
  fetchProject,
  saveProject,
  saveProjectApproved,
  ProjectDeleteOptions
} from 'project/api/projectApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteProjectUploadFail,
  deleteProjectUploadStarted,
  deleteProjectUploadSuccess,
  loadSingleProjectFail,
  loadSingleProjectStarted,
  loadSingleProjectSuccess,
  saveProjectApprovedFail,
  saveProjectApprovedStarted,
  saveProjectApprovedSuccess,
  saveProjectFail,
  saveProjectStarted,
  saveProjectSuccess
} from 'project/reducers/projectReducer'
import { normalize, schema } from 'normalizr'

import { PayloadAction } from '@reduxjs/toolkit'
import { saveUploadsSuccess } from 'upload/reducers/uploadReducer'

export const projectSchema = new schema.Entity<Project>('project')

export function* handleLoadSingleProject(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchProject, action.payload)
    const { entities } = normalize(response, projectSchema)

    const project = entities.project[response.id]

    const uploads = project.projetoUploads?.map(upload => upload.upload) ?? []

    yield put(saveUploadsSuccess(uploads))

    return yield put(loadSingleProjectSuccess(entities.project[response.id]))
  } catch (error) {
    return yield put(
      loadSingleProjectFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveProject({
  payload
}: PayloadAction<SaveProjectPayload>) {
  try {
    const response = yield call(saveProject, payload)

    const { entities } = normalize(response, projectSchema)

    return yield put(
      saveProjectSuccess({
        forItem: payload.id || 'new',
        result: entities.project[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveProjectFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleSaveProjectApproved({
  payload
}: PayloadAction<SaveProjectPayload>) {
  try {
    const response = yield call(saveProjectApproved, payload)

    const { entities } = normalize(response, projectSchema)

    return yield put(
      saveProjectApprovedSuccess({
        forItem: payload.id || 'new',
        result: entities.project[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveProjectApprovedFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteProjectUpload({
  payload
}: PayloadAction<ProjectDeleteOptions>) {
  try {
    yield call(deleteProjectUpload, payload)

    return yield put(deleteProjectUploadSuccess(payload.id))
  } catch (error) {
    return yield put(
      deleteProjectUploadFail({
        forItem: payload.id,
        error
      })
    )
  }
}

function* projectSaga() {
  yield takeLatest(loadSingleProjectStarted.type, handleLoadSingleProject)
  yield takeLeading(saveProjectStarted.type, handleSaveProject)
  yield takeLeading(saveProjectApprovedStarted.type, handleSaveProjectApproved)
  yield takeLeading(deleteProjectUploadStarted.type, handleDeleteProjectUpload)
}

export default projectSaga
