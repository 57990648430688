import { Flex, Grid, PseudoBox, Stack } from '@chakra-ui/core'
import React, { FC } from 'react'

export const SidebarLayout: FC = ({ children }) => {
  return (
    <Grid
      height='100vh'
      bg='gray.50'
      templateColumns='max-content 1fr'
      templateRows='60px 1fr'
      templateAreas="'sidebar header' 'sidebar content'"
    >
      {children}
    </Grid>
  )
}

interface SidebarProps {
  isOpen?: boolean
}

export const Sidebar: FC<SidebarProps> = ({ children, isOpen }) => {
  return (
    <Stack
      bg='gray.700'
      gridArea='sidebar'
      width={isOpen ? '250px' : '80px'}
      p={2}
      borderRightColor='gray.200'
      borderRightWidth={1}
      overflowY='auto'
    >
      {children}
    </Stack>
  )
}

export const Content: FC = ({ children }) => {
  return (
    <PseudoBox m={2} p={2} overflowY='auto' bg='gray.50'>
      {children}
    </PseudoBox>
  )
}

export const Header: FC = ({ children }) => {
  return (
    <Flex
      p={2}
      gridArea='header'
      borderBottom='gray.200'
      borderBottomWidth={1}
      align='center'
      bg='white'
    >
      {children}
    </Flex>
  )
}
