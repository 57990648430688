import {
  FetchSupportMaterialsOptions,
  SaveSupportMaterialPayload,
  SupportMaterial
} from 'supportMaterial/api/supportMaterialApi'
import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'
import { remove } from 'lodash'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de material de apoio
 */
export interface SupportMaterialState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  items: { [key: string]: SupportMaterial | undefined }
}

const initialState: SupportMaterialState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: supportMaterialReducer } = createSlice({
  name: 'supportMaterial',
  initialState,
  reducers: {
    loadSupportMaterialsStarted(
      state,
      { payload }: PayloadAction<FetchSupportMaterialsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    loadSupportMaterialsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: SupportMaterial }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadSupportMaterialsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    loadSingleSupportMaterialStarted(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadSingleSupportMaterialSuccess(
      state,
      { payload }: PayloadAction<SupportMaterial>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    loadSingleSupportMaterialFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    },
    saveSupportMaterialStarted(
      state,
      { payload }: PayloadAction<SaveSupportMaterialPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveSupportMaterialSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<SupportMaterial>>
    ) {
      const { forItem, result } = payload

      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveSupportMaterialFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    },
    deleteSupportMaterialStarted(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Deleting'
      state.errors[payload] = undefined

      return state
    },
    deleteSupportMaterialSuccess(state, { payload }: PayloadAction<string>) {
      state.status[payload] = 'Done'
      state.errors[payload] = undefined
      state.items[payload] = undefined

      // remove qualquer referencias ao item
      Object.keys(state.pages).forEach(page => {
        const items = state.pages[page]
        items && remove(items, payload)
      })

      return state
    },
    deleteSupportMaterialFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const supportMaterialOnPage = createSelector(({ supportMaterial }) => {
  const { pagination, pages } = supportMaterial

  return pages[pagination.current] || []
})

export const {
  loadSupportMaterialsStarted,
  loadSupportMaterialsSuccess,
  loadSupportMaterialsFail,
  loadSingleSupportMaterialStarted,
  loadSingleSupportMaterialSuccess,
  loadSingleSupportMaterialFail,
  saveSupportMaterialStarted,
  saveSupportMaterialSuccess,
  saveSupportMaterialFail,
  deleteSupportMaterialStarted,
  deleteSupportMaterialSuccess,
  deleteSupportMaterialFail
} = actions

export default supportMaterialReducer
