import { MouseEvent, RefObject, useEffect } from 'react'

function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void
) {
  useEffect(() => {
    // FIXME(fabio)
    // Este hook foi criado para, principalmente, uso do Select do carbono
    // é necessário revisar a tipagem desta função de callback, não foi feita
    // pois não consegui encontrar um tipo que atenda a necessidade do handler e a
    // assinatura do eventListener
    function listener(event: any) {
      if (!ref?.current || ref?.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
