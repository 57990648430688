import ItemChecklistAnalisys from 'ItemsChecklist/reducers/ItemChecklistAnalisys'
import ItemChecklistApprovalReducer from 'ItemsChecklist/reducers/ItemChecklistApprovalReducer'
import ItemsChecklistAnalisisReducer from 'ItemsChecklist/reducers/itemsChecklistAnalisisReducer'
import PaymentAttachmentAnalisisReducer from 'paymentAttachment/reducers/PaymentAttachmentAnalisisReducer'
import UserCondominiumReducer from 'condominium/reducers/UserCondominiumReducer'
import analysisReleaseLetter from 'releaseLetter/reducer/analysisReleaseLetter'
import authReducer from 'auth/reducers/authReducer'
import blockReducer from 'block/reducers/blockReducer'
import cityReducer from 'city/reducers/cityReducer'
import { combineReducers } from 'redux'
import condominiumReducer from 'condominium/reducers/condominiumReducer'
import finishProcessReducer from 'work/reducers/finishProcessReducer'
import finishReanalysis from 'reanalysis/reducers/finishReanalysis'
import finishWorkReducer from 'work/reducers/finishWorkReducer'
import addRoutineExistingWork from 'work/reducers/addRoutineExistingWorReducer'
import infoService from 'serviceProvider/slices/infoService'
import inhabitedWithReservations from 'work/reducers/inhabitedWithReservations'
import initReanalysis from 'reanalysis/reducers/initReanalysis'
import itemChecklistApproveAnalisisReducer from 'ItemsChecklist/reducers/itemChecklistApproveAnalisisReducer'
import itemChecklistDisapproveAnalisisReducer from 'ItemsChecklist/reducers/itemChecklistDisapproveAnalisisReducer'
import itemChecklistReducer from 'ItemsChecklist/reducers/itemChecklistReducer'
import itemTypeChecklistReducer from 'itemTypeChecklist/reducers/itemTypeChecklistReducer'
import itemsChecklistSubmitionReducer from 'ItemsChecklist/reducers/itemsChecklistSubmitionReducer'
import typeChecklistRoutineReducer from 'routineChecklistTypes/reducers/typeChecklistDeRotinasReducer'
import joinLots from 'lot/reducers/joinLots'
import layoutReducer from 'layout/reducers/layoutReducer'
import lotReducer from 'lot/reducers/lotReducer'
import nonComplianceEventReducer from 'nonComplianceEvent/reducers/nonComplianceEventReducer'
import nonComplianceReducer from 'nonCompliance/reducers/nonComplianceReducer'
import nonComplianceTypeReducer from 'nonComplianceType/reducers/nonComplianceTypeReducer'
import nonComplianceCategoryReducer from 'nonComplianceCategory/reducers/nonComplianceCategoryReducer'
import notification from 'notifications/reducers/notifications'
import passwordReducer from 'user/reducers/passwordReducer'
import paymentAttachmentReducer from 'paymentAttachment/reducers/paymentAttachmentReducer'
import processReducer from 'process/reducers/process'
import projectApprovalReducer from 'project/reducers/projectApprovalReducer'
import projectReducer from 'project/reducers/projectReducer'
import reactiveProjectReducer from 'project/reducers/reactiveProjectReducer'
import reanalyzeProccessReducer from 'work/reducers/reanalyzeProccessReducer'
import releaseLetter from 'releaseLetter/reducer/releaseLetter'
import removeCaveat from 'removeCaveat/reducers/removeCaveat'
import requestFinalSurveyReducer from 'work/reducers/requestFinalSurveyReducer'
import reviewProjectReducer from 'project/reducers/reviewProjectReducer'
import sendReleaseLetter from 'releaseLetter/reducer/sendReleaseLetter'
import sendTaxReducer from 'work/reducers/sendTaxReducer'
import serviceProviderCompany from 'serviceProvider/slices/company'
import serviceProviderProviders from 'serviceProvider/slices/serviceProvider'
import shelveProcess from 'process/reducers/shelveProcess'
import stateReducer from 'state/reducers/stateReducer'
import streetReducer from 'street/reducers/streetReducer'
import subject from 'technicalDocument/reducers/subject'
import submitInitialWork from 'work/reducers/submitInitialWork'
import submitToAnalisisReducer from 'project/reducers/submitToAnalisisReducer'
import submitWorkToAnalisisReducer from 'submitWorkToAnalisis/reducers/submitWorkToAnalisisReducer'
import supportMaterialReducer from 'supportMaterial/reducers/supportMaterialReducer'
import technicalDocumentReducer from 'technicalDocument/reducers/technicalDocumentReducer'
import updateAccessReducer from 'work/reducers/updateAccessReducer'
import uploadReducer from 'upload/reducers/uploadReducer'
import userLotReducer from 'userLot/reducers/userLotReducer'
import userPermissionReducer from 'userPermissions/reducers/userPermissionsReducer'
import userReducer from 'user/reducers/userReducer'
import userWorkReducer from 'user/reducers/userWorkReducer'
import workCommentReducer from 'workComment/reducer/workCommentReducer'
import workEventReducer from 'workEvent/reducer/workEventReducer'
import workInitReducer from 'work/reducers/workInitReducer'
import workReducer from 'work/reducers/workReducer'
import typeRoutineReducer from 'typeRoutine/reducers/typeRoutineReducer'
import workStartedIrregularly from 'WorkStartedIrregularly/slices/workStartedIrregularly'
import emailSendGridReducer from 'condominium/reducers/ConfigSendGridReducer'

const rootReducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  condominium: condominiumReducer,
  street: streetReducer,
  block: blockReducer,
  lot: lotReducer,
  nonComplianceType: nonComplianceTypeReducer,
  nonComplianceCategory: nonComplianceCategoryReducer,
  nonCompliance: nonComplianceReducer,
  listRoutineWork: nonComplianceReducer,
  eventsExecutionRoutinesByWorkId: typeChecklistRoutineReducer,
  work: workReducer,
  addRoutineExistingWork: addRoutineExistingWork,
  nonComplianceEvent: nonComplianceEventReducer,
  upload: uploadReducer,
  user: userReducer,
  userWork: userWorkReducer,
  userPermissions: userPermissionReducer,
  itemTypeChecklist: itemTypeChecklistReducer,
  itemChecklist: itemChecklistReducer,
  workEvent: workEventReducer,
  workComment: workCommentReducer,
  itemChecklistApproval: ItemChecklistApprovalReducer,
  paymentAttachment: paymentAttachmentReducer,
  userLot: userLotReducer,
  submitWorkToAnalisis: submitWorkToAnalisisReducer,
  project: projectReducer,
  projectApproval: projectApprovalReducer,
  workInit: workInitReducer,
  reactiveProject: reactiveProjectReducer,
  finishWork: finishWorkReducer,
  requestFinalSurvey: requestFinalSurveyReducer,
  finishProcess: finishProcessReducer,
  sendTax: sendTaxReducer,
  itemChecklistApproveAnalisis: itemChecklistApproveAnalisisReducer,
  itemChecklistDisapproveAnalisis: itemChecklistDisapproveAnalisisReducer,
  paymentAnalisis: PaymentAttachmentAnalisisReducer,
  userCondominium: UserCondominiumReducer,
  submitToAnalisis: submitToAnalisisReducer,
  reviewProject: reviewProjectReducer,
  supportMaterial: supportMaterialReducer,
  technicalDocument: technicalDocumentReducer,
  password: passwordReducer,
  updateAccess: updateAccessReducer,
  reanalyzeProccess: reanalyzeProccessReducer,
  ItemsChecklistAnalisis: ItemsChecklistAnalisisReducer,
  itemsChecklistSubmition: itemsChecklistSubmitionReducer,
  city: cityReducer,
  state: stateReducer,
  inhabitedWithReservations: inhabitedWithReservations,
  process: processReducer,
  itemChecklistAnalisys: ItemChecklistAnalisys,
  typeChecklistRoutine: typeChecklistRoutineReducer,
  typeRoutine: typeRoutineReducer,
  emailSendGrid: emailSendGridReducer,
  submitInitialWork,
  initReanalysis,
  finishReanalysis,
  shelveProcess,
  analysisReleaseLetter,
  releaseLetter,
  sendReleaseLetter,
  joinLots,
  subject,
  removeCaveat,
  notification,
  workStartedIrregularly,
  serviceProviderCompany,
  serviceProviderProviders,
  infoService
})

export default rootReducer
