import {
  Pagination,
  RequestError,
  ResourceFailPayload,
  ResourceStatus
} from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { WorkEvent, WorkEventOptions } from 'workEvent/api/workEventApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

/**
 * Store de Eventos de obra
 */

export interface WorkEventState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: string]: string[] | undefined }
  byWork: { [page: string]: string[] | undefined }
  items: { [key: string]: WorkEvent | undefined }
}

const initialState: WorkEventState = {
  items: {},
  pages: {},
  byWork: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: workEventReducer } = createSlice({
  name: 'work',
  initialState,
  reducers: {
    loadWorksEventStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    loadWorksEventByFilterStarted(
      state,
      { payload }: PayloadAction<WorkEventOptions>
    ) {
      state.errors[payload.obraId ?? 'new'] = undefined
      state.status[payload.obraId ?? 'new'] = 'Pending'

      return state
    },
    loadWorksEventSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        forWork: string
        result: { [id: string]: WorkEvent }
      }>
    ) {
      const { result, forWork } = payload

      state.status[forWork] = 'Done'
      state.errors[forWork] = undefined
      state.byWork[forWork] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    loadWorksEventFail(state, { payload }: PayloadAction<ResourceFailPayload>) {
      state.status[payload.forItem] = 'Error'
      state.errors[payload.forItem] = payload.error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */

export const {
  loadWorksEventFail,
  loadWorksEventStarted,
  loadWorksEventSuccess,
  loadWorksEventByFilterStarted
} = actions

export default workEventReducer
