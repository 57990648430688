import createSelectResource from './createSelectResource'
import {
  Condominium,
  fetchCondominiums,
  fetchCondominium
} from 'condominium/api/condominiumApi'

const SelectCondominium = createSelectResource<Condominium>({
  idKey: 'id',
  labelKey: 'nome',
  resourceName: 'Condominium',
  resourcesLoader: (query: string) => {
    return fetchCondominiums({
      nome: query,
      pageSize: 0
    })
  },
  singleResourceLoader: id => {
    return fetchCondominium(id)
  }
})

export default SelectCondominium
