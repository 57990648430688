import {
  FetchCategoriaNaoConformidadeOptions,
  SaveCategoriaNaoConformidadePayload,
  deleteCategoriaNaoConformidade,
  fetchNonComplianceCategory,
  fetchNonComplianceCategories,
  saveCategoriaNaoConformidade
} from 'nonComplianceCategory/api/nonComplianceCategoryApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteCategoriaNaoConformidadeFail,
  deleteCategoriaNaoConformidadeStarted,
  deleteCategoriaNaoConformidadeSuccess,
  fetchCategoriaNaoConformidadeFail,
  fetchCategoriaNaoConformidadeStarted,
  fetchCategoriaNaoConformidadeSuccess,
  fetchSingleCategoriaNaoConformidadeFail,
  fetchSingleCategoriaNaoConformidadeStarted,
  fetchSingleCategoriaNaoConformidadeSuccess,
  saveCategoriaNaoConformidadeFail,
  saveCategoriaNaoConformidadeStarted,
  saveCategoriaNaoConformidadeSuccess
} from 'nonComplianceCategory/reducers/nonComplianceCategoryReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const nonComplianceCategorySchema = new schema.Entity(
  'nonComplianceCategory'
)

export function* handleFetchNonComplianceCategories(
  action: PayloadAction<FetchCategoriaNaoConformidadeOptions | undefined>
) {
  try {
    const response = yield call(fetchNonComplianceCategories, action.payload)

    const { entities } = normalize(response.result, [
      nonComplianceCategorySchema
    ])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      fetchCategoriaNaoConformidadeSuccess({
        result: entities.nonComplianceCategory || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchCategoriaNaoConformidadeFail(error))
  }
}

export function* handleFetchSingleNonComplianceCategory(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchNonComplianceCategory, action.payload)
    const { entities } = normalize(response, nonComplianceCategorySchema)

    return yield put(
      fetchSingleCategoriaNaoConformidadeSuccess(
        entities.condominium[response.id]
      )
    )
  } catch (error) {
    return yield put(
      fetchSingleCategoriaNaoConformidadeFail({
        id: action.payload,
        error
      })
    )
  }
}

export function* handleSaveNonComplianceCategory({
  payload
}: PayloadAction<SaveCategoriaNaoConformidadePayload>) {
  try {
    const response = yield call(saveCategoriaNaoConformidade, payload)

    const { entities } = normalize(response, nonComplianceCategorySchema)

    return yield put(
      saveCategoriaNaoConformidadeSuccess({
        forItem: payload.id || 'new',
        result: entities.nonComplianceCategory[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveCategoriaNaoConformidadeFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteNonComplianceCategory({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteCategoriaNaoConformidade, payload)

    return yield put(deleteCategoriaNaoConformidadeSuccess(payload))
  } catch (error) {
    return yield put(
      deleteCategoriaNaoConformidadeFail({
        forItem: payload,
        error
      })
    )
  }
}

function* nonComplianceCategorySaga() {
  yield takeLatest(
    fetchCategoriaNaoConformidadeStarted.type,
    handleFetchNonComplianceCategories
  )
  yield takeLatest(
    fetchSingleCategoriaNaoConformidadeStarted.type,
    handleFetchSingleNonComplianceCategory
  )
  yield takeLeading(
    saveCategoriaNaoConformidadeStarted.type,
    handleSaveNonComplianceCategory
  )

  yield takeLeading(
    deleteCategoriaNaoConformidadeStarted.type,
    handleDeleteNonComplianceCategory
  )
}

export default nonComplianceCategorySaga
