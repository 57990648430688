import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'
import { UserCondominiumProfile, UserType } from 'user/api/userApi'
import { deleteResource, fetchAll, save, update } from 'common/api/apiHelper'

export interface UserCondominium {
  id: string
  pessoaId: string
  condominioId: string
  cargo: UserCondominiumProfile | UserCondominiumProfile[]
  recebeEmail: boolean
  pessoa: {
    id: string
    userId: string
    nome: string
    cpf: string
    email: string
    telefone: string
    crea: string
    cau: string
    userType: UserType
    userName: string
  }
}

export type SaveUserCondominiumPayload = SaveResourcePayload<
  Omit<UserCondominium, 'pessoa'>
>
export type FetchUserCondominiumOptions = PaginatedEndpointOptions<
  UserCondominium & {
    PessoaNome: string
    PessoaUserName: string
    PessoaEmail: string
  }
>

export type FetchUserCondominiumResponse = PaginatedEndpointResponse<
  UserCondominium
>

export const saveUserCondominium = save<Omit<UserCondominium, 'pessoa'>>(
  'Condominio/PessoaCondominio'
)

export const fetchUsers = fetchAll<UserCondominium>(
  'Condominio/PessoaCondominio'
)

export const deleteUser = deleteResource<Omit<UserCondominium, 'pessoa'>>(
  (id: string) => `Condominio/PessoaCondominio/${id}`
)

export const editUser = (id: string) =>
  update<Omit<UserCondominium, 'pessoa'>>(`Condominio/PessoaCondominio/${id}`)
