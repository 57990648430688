import {
  SaveAddRoutineExistingWorkPayload,
  AddRoutineExistingWork,
  saveAddRoutineExistingWork
} from 'work/api/addRoutineExistingWorkApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  saveAddRoutineExistingWorkStarted,
  saveAddRoutineExistingWorkSuccess,
  saveAddRoutineExistingWorkFail
} from 'work/reducers/addRoutineExistingWorReducer'
import { normalize, schema } from 'normalizr'

import { PayloadAction } from '@reduxjs/toolkit'

export const saveAddRoutineExistingWorkSchema = new schema.Entity<
  AddRoutineExistingWork
>('addRoutineExistingWork')

export function* handleSaveAddRoutineExistingWork({
  payload
}: PayloadAction<SaveAddRoutineExistingWorkPayload>) {
  try {
    const response = yield call(saveAddRoutineExistingWork, payload)

    const { entities } = normalize(response, saveAddRoutineExistingWorkSchema)

    return yield put(
      saveAddRoutineExistingWorkSuccess({
        forItem: payload.id || 'new',
        result: entities.addRoutineExistingWork[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveAddRoutineExistingWorkFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* addRoutineExistingWorkSaga() {
  yield takeLeading(
    saveAddRoutineExistingWorkStarted.type,
    handleSaveAddRoutineExistingWork
  )
}

export default addRoutineExistingWorkSaga
