import { ItemChecklist } from 'ItemsChecklist/api/itemsChecklistApi'
import { ItemChecklistUpload } from 'ItemsChecklist/api/itemsChecklistApi'
import { ItemTypeChecklist } from 'itemTypeChecklist/api/itemTypeChecklistApi'
import { PaginatedEndpointOptions } from 'types/global'
import { Upload } from 'upload/api/uploadApi'
import { User } from 'user/api/userApi'
import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'
import { fetchAll } from 'common/api/apiHelper'
import tipoEvento from 'workEvent/enums/tipoEventoObraEnum'

export interface UserWorkEvent {
  id: string
  nome: string
  userName: string
}

export interface WorkEvent {
  data: string
  id: string
  obraId: string
  pessoaId: string
  pessoaNome?: string
  pessoaEmail?: string
  itemChecklistId: string
  projetoId: string
  comprovantePagamentoId: string
  itemChecklistUploadId: string
  mensagem: string
  tipoEventoObra: tipoEvento
  pessoa: User
  itemChecklist: ItemChecklist
  itemChecklistUpload: ItemChecklistUpload
  tipoItemChecklist: ItemTypeChecklist
  uploads: Upload[]
  pessoaAdicionadaNaObra?: UserWorkEvent
}

export async function fetchWorkEvents(obraId: string) {
  try {
    const { data } = await api.get<WorkEvent[]>(`Obra/${obraId}/eventosObra`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type WorkEventOptions = PaginatedEndpointOptions<WorkEvent>

export const fetchEvents = fetchAll<WorkEvent>('Obra/EventosObra')
