import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'

import api from 'common/api/api'
import createRequestError from 'shared/createRequestError'

/**
 * Pode ser uma Quadra (condomínio horizontal)
 * ou um Pavimento (condomínio vertical)
 */
export interface Block {
  id: string
  nome: string
  ruaId: string
  condominioId: string
  ruaNome: string
}

export type FetchBlocksOptions = PaginatedEndpointOptions<
  Omit<Block, 'ruaNome'>
>
export type FetchBlockResponse = PaginatedEndpointResponse<Block>

/**
 * Carrega lista de quadras/pavimentos paginados e filtradas pela query.
 */
export async function fetchBlocks(options?: FetchBlocksOptions) {
  try {
    const { data } = await api.get<FetchBlockResponse>('Quadra', {
      params: options
    })

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega lista de ruas paginadas e filtradas pela query.
 */
export async function fetchBlocksFromStreet(streetId: string) {
  try {
    const endpoint = `/Rua/${streetId}/quadras`

    const { data } = await api.get<Block[]>(endpoint)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Carrega uma quadra/pavimento pelo id.
 */
export async function fetchBlock(id: string) {
  try {
    const { data } = await api.get<Block>(`Quadra/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveBlockPayload = SaveResourcePayload<
  Omit<Block, 'condominioId' | 'ruaNome'>
>

/**
 * Salva uma quadra/pavimento, se houver id altera o existente.
 */
export async function saveBlock(block: SaveBlockPayload) {
  try {
    const request = block.id ? api.put : api.post

    const { data } = await request<Block>('Quadra', block)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

/**
 * Apaga uma quadra/pavimento pelo id.
 */
export async function deleteBlock(id: string) {
  try {
    const { data } = await api.delete<Block>(`Quadra/${id}`)

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
