import {
  FetchWorksOptions,
  SaveWorkPayload,
  Work,
  deleteWork,
  deleteRoutineWork,
  fetchBook,
  fetchWork,
  fetchWorks,
  fetchWorksFromCondominium,
  saveWork,
  RemoveRoutineWork
} from 'work/api/workApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteWorkFail,
  deleteWorkStarted,
  deleteWorkSuccess,
  deleteRoutineWorkFail,
  deleteRoutineWorkStarted,
  deleteRoutineWorkSuccess,
  loadBookFail,
  loadBookStarted,
  loadBookSuccess,
  loadSingleWorkFail,
  loadSingleWorkStarted,
  loadSingleWorkSuccess,
  loadWorksFail,
  loadWorksFromCondominiumFail,
  loadWorksFromCondominiumStarted,
  loadWorksFromCondominiumSuccess,
  loadWorksStarted,
  loadWorksSuccess,
  saveWorkFail,
  saveWorkStarted,
  saveWorkSuccess
} from 'work/reducers/workReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const workSchema = new schema.Entity<Work>('work')

export function* handleLoadWorks(
  action: PayloadAction<FetchWorksOptions | undefined>
) {
  try {
    const response = yield call(fetchWorks, action.payload)

    const { entities } = normalize(response.result, [workSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadWorksSuccess({
        result: entities.work || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadWorksFail(error))
  }
}

export function* handleLoadWorksFromCondominium(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchWorksFromCondominium, action.payload)

    const { entities } = normalize(response, [workSchema])

    return yield put(
      loadWorksFromCondominiumSuccess({
        result: entities.work || [],
        forCondominium: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadWorksFromCondominiumFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadSingleWork(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchWork, action.payload)
    const { entities } = normalize(response, workSchema)

    return yield put(loadSingleWorkSuccess(entities.work[response.id]))
  } catch (error) {
    return yield put(
      loadSingleWorkFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSaveWork({ payload }: PayloadAction<SaveWorkPayload>) {
  try {
    const response = yield call(saveWork, payload)

    const { entities } = normalize(response, workSchema)

    return yield put(
      saveWorkSuccess({
        forItem: payload.id || 'new',
        result: entities.work[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveWorkFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteWork({ payload }: PayloadAction<string>) {
  try {
    yield call(deleteWork, payload)

    return yield put(deleteWorkSuccess(payload))
  } catch (error) {
    return yield put(
      deleteWorkFail({
        forItem: payload,
        error
      })
    )
  }
}

export function* handleDeleteRoutineWork({
  payload
}: PayloadAction<RemoveRoutineWork>) {
  try {
    yield call(deleteRoutineWork, payload)

    return yield put(deleteRoutineWorkSuccess(payload.id))
  } catch (error) {
    return yield put(
      deleteRoutineWorkFail({
        forItem: payload.id,
        error
      })
    )
  }
}

export function* handleLoadBook(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchBook, action.payload)

    return yield put(
      loadBookSuccess({ forWork: action.payload, result: response })
    )
  } catch (error) {
    return yield put(loadBookFail({ forItem: action.payload }))
  }
}

function* workSaga() {
  yield takeLatest(loadWorksStarted.type, handleLoadWorks)
  yield takeLatest(loadBookStarted.type, handleLoadBook)
  yield takeLatest(
    loadWorksFromCondominiumStarted.type,
    handleLoadWorksFromCondominium
  )
  yield takeLatest(loadSingleWorkStarted.type, handleLoadSingleWork)
  yield takeLeading(saveWorkStarted.type, handleSaveWork)
  yield takeLeading(deleteWorkStarted.type, handleDeleteWork)
  yield takeLeading(deleteRoutineWorkStarted.type, handleDeleteRoutineWork)
}

export default workSaga
