import {
  FetchCondominiumsOptions,
  SaveCondominiumPayload,
  fetchCondominium,
  fetchCondominiums,
  saveCondominium
} from 'condominium/api/condominiumApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  fetchCondominiumsFail,
  fetchCondominiumsStarted,
  fetchCondominiumsSuccess,
  fetchSingleCondominiumFail,
  fetchSingleCondominiumStarted,
  fetchSingleCondominiumSuccess,
  saveCondominiumFail,
  saveCondominiumStarted,
  saveCondominiumSuccess
} from 'condominium/reducers/condominiumReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const condominiumSchema = new schema.Entity('condominium')

export function* handleFetchCondominiums(
  action: PayloadAction<FetchCondominiumsOptions | undefined>
) {
  try {
    const response = yield call(fetchCondominiums, action.payload)

    const { entities } = normalize(response.result, [condominiumSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      fetchCondominiumsSuccess({
        result: entities.condominium,
        pagination
      })
    )
  } catch (error) {
    return yield put(fetchCondominiumsFail(error))
  }
}

export function* handleFetchSingleCondominium(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchCondominium, action.payload)
    const { entities } = normalize(response, condominiumSchema)

    return yield put(
      fetchSingleCondominiumSuccess(entities.condominium[response.id])
    )
  } catch (error) {
    return yield put(
      fetchSingleCondominiumFail({
        id: action.payload,
        error
      })
    )
  }
}

export function* handleSaveCondominium({
  payload
}: PayloadAction<SaveCondominiumPayload>) {
  try {
    const response = yield call(saveCondominium, payload)

    const { entities } = normalize(response, condominiumSchema)

    return yield put(
      saveCondominiumSuccess({
        forItem: payload.id || 'new',
        result: entities.condominium[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveCondominiumFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* condominiumSaga() {
  yield takeLatest(fetchCondominiumsStarted.type, handleFetchCondominiums)
  yield takeLatest(
    fetchSingleCondominiumStarted.type,
    handleFetchSingleCondominium
  )
  yield takeLeading(saveCondominiumStarted.type, handleSaveCondominium)
}

export default condominiumSaga
