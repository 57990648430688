import { fetchAuthToken, fetchRenewedAuthToken } from 'auth/api/authApi'
import jwtDecode from 'jwt-decode'
import {
  condominiumProfileSelected,
  Credentials,
  loginFailed,
  loginStarted,
  loginSuccess,
  logout,
  refreshFailed,
  refreshStarted,
  restoreFailed,
  restoreStarted
} from 'auth/reducers/authReducer'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import isTokenValid from 'shared/isTokenValid'
import { TokenPayload } from 'types/global'

/**
 * Autentica usuário
 */
export function* handleLogin(action: PayloadAction<Credentials>) {
  try {
    const { token, refreshToken, isInvite } = yield call(
      fetchAuthToken,
      action.payload
    )
    const tokenPayload = jwtDecode<TokenPayload>(token)

    localStorage.setItem('auth.token', token)
    localStorage.setItem('auth.refreshToken', refreshToken)

    return yield put(
      loginSuccess({
        token,
        tokenPayload,
        isInvite
      })
    )
  } catch (error) {
    return yield put(loginFailed(error))
  }
}

/**
 * Restaura autenticação salva no localStorage
 */
export function* handleRestore() {
  try {
    const token = localStorage.getItem('auth.token')

    if (!token) return yield put(restoreFailed())
    const tokenPayload = jwtDecode<TokenPayload>(token)

    if (!isTokenValid(tokenPayload)) {
      return yield put(refreshStarted())
    }

    yield put(
      loginSuccess({
        token,
        tokenPayload
      })
    )

    const condominium = localStorage.getItem('auth.condominium')
    if (condominium) return yield put(condominiumProfileSelected(condominium))

    return
  } catch (e) {
    localStorage.removeItem('auth.token')
    localStorage.removeItem('auth.refreshToken')

    return yield put(loginFailed(e))
  }
}

/**
 * Obtém uma nova token utilizando a refreshToken salva no localhost
 * no login anterior
 */
export function* handleRefresh() {
  const localRefreshToken = localStorage.getItem('auth.refreshToken')
  if (!localRefreshToken) return yield put(refreshFailed())

  try {
    const { token, refreshToken } = yield call(
      fetchRenewedAuthToken,
      localRefreshToken
    )

    const tokenPayload = jwtDecode<TokenPayload>(token)

    localStorage.setItem('auth.token', token)
    localStorage.setItem('auth.refreshToken', refreshToken)

    return yield put(loginSuccess({ token, tokenPayload }))
  } catch (e) {
    localStorage.removeItem('auth.token')
    localStorage.removeItem('auth.refreshToken')

    return yield put(refreshFailed())
  }
}

export function handleLogout() {
  localStorage.removeItem('auth.token')
  localStorage.removeItem('auth.refreshToken')
}

export function handleCondominiumProfileSelected(
  action: PayloadAction<string>
) {
  localStorage.setItem('auth.condominium', action.payload)
}

function* authSaga() {
  yield takeLatest(loginStarted.type, handleLogin)
  yield takeLatest(restoreStarted.type, handleRestore)
  yield takeLatest(refreshStarted.type, handleRefresh)
  yield takeLeading(logout.type, handleLogout)
  yield takeLatest(
    condominiumProfileSelected.type,
    handleCondominiumProfileSelected
  )
}

export default authSaga
