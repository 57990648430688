import { SaveResourcePayload } from 'types/global'
import { save } from 'common/api/apiHelper'

export interface FinishWork {
  id: string
  obraId: string
  mensagem?: string
  uploadIds: string[]
}

export type SaveFinishWorkPayload = SaveResourcePayload<FinishWork>

export const saveFinishWork = save<FinishWork>('Obra/FinalizarObra')
