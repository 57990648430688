import {
  FetchPaymentAttachmentsOptions,
  SavePaymentAttachmentPayload,
  deletePaymentAttachment,
  fetchPaymentAttachments,
  fetchSinglePaymentAttachment,
  savePaymentAttachment,
  PaymentDeleteOptions
} from 'paymentAttachment/api/paymentAttachmentApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deletePaymentAttachmentFail,
  deletePaymentAttachmentStarted,
  deletePaymentAttachmentSuccess,
  loadPaymentAttachmentFail,
  loadPaymentAttachmentFromWorkFail,
  loadPaymentAttachmentFromWorkStarted,
  loadPaymentAttachmentFromWorkSuccess,
  loadPaymentAttachmentStarted,
  loadPaymentAttachmentSuccess,
  loadSinglePaymentAttachmentFail,
  loadSinglePaymentAttachmentStarted,
  loadSinglePaymentAttachmentSuccess,
  savePaymentAttachmentFail,
  savePaymentAttachmentStarted,
  savePaymentAttachmentSuccess
} from 'paymentAttachment/reducers/paymentAttachmentReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'
import { saveUploadSuccess } from 'upload/reducers/uploadReducer'
import { uploadSchema } from 'upload/sagas/uploadSaga'

export const paymentAttachmentSchema = new schema.Entity('paymentAttachment')

export function* handleLoadPaymentAttachments(
  action: PayloadAction<FetchPaymentAttachmentsOptions | undefined>
) {
  try {
    const response = yield call(fetchPaymentAttachments, action.payload)

    const { entities } = normalize(response.result, [paymentAttachmentSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount
    }

    return yield put(
      loadPaymentAttachmentSuccess({
        result: entities.paymentAttachment,
        pagination
      })
    )
  } catch (error) {
    return yield put(loadPaymentAttachmentFail(error))
  }
}

export function* handleLoadSinglePaymentAttachment(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchSinglePaymentAttachment, action.payload)
    const { entities } = normalize(response, paymentAttachmentSchema)

    return yield put(
      loadSinglePaymentAttachmentSuccess(
        entities.paymentAttachment[response.id]
      )
    )
  } catch (error) {
    return yield put(
      loadSinglePaymentAttachmentFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleLoadPaymentAttachmentFromWork(
  action: PayloadAction<string>
) {
  try {
    const response = yield call(fetchPaymentAttachments, {
      obraId: action.payload
    })

    const { entities } = normalize(response.result, [
      { paymentAttachment: paymentAttachmentSchema, upload: uploadSchema }
    ])

    const key = Object.keys(entities.upload)[0]

    yield put(
      saveUploadSuccess({
        forItem: key,
        result: entities.upload[key]
      })
    )

    return yield put(
      loadPaymentAttachmentFromWorkSuccess({
        result: response.result,
        forWork: action.payload
      })
    )
  } catch (error) {
    return yield put(
      loadPaymentAttachmentFromWorkFail({
        forItem: action.payload,
        error
      })
    )
  }
}

export function* handleSavePaymentAttachment({
  payload
}: PayloadAction<SavePaymentAttachmentPayload>) {
  try {
    const response = yield call(savePaymentAttachment, payload)

    const { entities } = normalize(response, paymentAttachmentSchema)

    return yield put(
      savePaymentAttachmentSuccess({
        forItem: payload.id || 'new',
        result: entities.paymentAttachment[response.id]
      })
    )
  } catch (error) {
    return yield put(
      savePaymentAttachmentFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeletePaymentAttachment({
  payload
}: PayloadAction<PaymentDeleteOptions>) {
  try {
    yield call(deletePaymentAttachment, payload)

    return yield put(deletePaymentAttachmentSuccess(payload.id))
  } catch (error) {
    return yield put(
      deletePaymentAttachmentFail({
        forItem: payload.id,
        error
      })
    )
  }
}

function* paymentAttachmentSaga() {
  yield takeLatest(
    loadPaymentAttachmentStarted.type,
    handleLoadPaymentAttachments
  )
  yield takeLatest(
    loadSinglePaymentAttachmentStarted.type,
    handleLoadSinglePaymentAttachment
  )
  yield takeLatest(
    loadPaymentAttachmentFromWorkStarted.type,
    handleLoadPaymentAttachmentFromWork
  )
  yield takeLeading(
    savePaymentAttachmentStarted.type,
    handleSavePaymentAttachment
  )
  yield takeLeading(
    deletePaymentAttachmentStarted.type,
    handleDeletePaymentAttachment
  )
}

export default paymentAttachmentSaga
