import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestError,
  ResourceFailPayload,
  ResourceStatus,
  SaveResourceSuccessPayload
} from 'types/global'

import { AtualizaAcessoBody } from 'work/api/workApi'

/**
 * ==================================================
 * Interfaces
 * ==================================================
 */

export interface UpdateAccessState {
  status: { [key: string]: ResourceStatus | undefined }
  errors: { [key: string]: RequestError | undefined }
}

const initialState: UpdateAccessState = {
  status: {},
  errors: {}
}

/**
 * ==================================================
 * Reducers
 * ==================================================
 */

const { actions, reducer: updateAccessReducer } = createSlice({
  name: 'updateAccess',
  initialState,
  reducers: {
    saveUpdateAccessStarted(
      state,
      { payload }: PayloadAction<AtualizaAcessoBody>
    ) {
      state.status['new'] = 'Saving'
      state.errors['new'] = undefined

      return state
    },
    saveUpdateAccessSuccess(
      state,
      { payload }: PayloadAction<SaveResourceSuccessPayload<AtualizaAcessoBody>>
    ) {
      state.status['new'] = 'Done'
      state.errors['new'] = undefined

      return state
    },
    saveUpdateAccessFail(
      state,
      { payload }: PayloadAction<ResourceFailPayload>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

/**
 * ==================================================
 * Actions
 * ==================================================
 */
export const {
  saveUpdateAccessStarted,
  saveUpdateAccessSuccess,
  saveUpdateAccessFail
} = actions

export default updateAccessReducer
