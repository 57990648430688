import api from 'common/api/api'
import { ItemTypeChecklist } from 'itemTypeChecklist/api/itemTypeChecklistApi'
import createRequestError from 'shared/createRequestError'
import {
  PaginatedEndpointOptions,
  PaginatedEndpointResponse,
  SaveResourcePayload
} from 'types/global'
import { Upload } from 'upload/api/uploadApi'

export interface ItemChecklistUpload {
  id: string
  itemChecklistId: string
  uploadId: string
  upload: Upload
  aprovado?: boolean
}

export interface ItemChecklist {
  id: string
  tipoItemChecklistId: string
  isObra: boolean
  obraId: string
  itemChecklistUploads: ItemChecklistUpload[]
  tipoItemChecklist?: ItemTypeChecklist
}

// TODO(Fábio): we need to refactor this!
export interface ItemChecklistResponse extends ItemTypeChecklist {
  itemChecklists?: ItemChecklist[]
}

export interface ItemChecklistRequest {
  id: string
  tipoItemChecklistId: string
  obraId: string
  uploads: string[]
}

export interface ItemChecklistDeleteOptions {
  obraId: string
  id: string
}

export type FetchItemsChecklistOptions = PaginatedEndpointOptions<ItemChecklist>

export type FetchItemsChecklistResponse = PaginatedEndpointResponse<
  ItemChecklistResponse
>

/**
 * Carrega lista de item checklist filtradas pela query.
 */
export async function fetchItemsChecklistByWork(
  options?: FetchItemsChecklistOptions
) {
  try {
    const obraId = (options && options.obraId) || ''

    if (obraId === '') return

    const { data } = await api.get<FetchItemsChecklistResponse[]>(
      `Obra/${obraId}/itemsChecklist`,
      {
        params: { ...options, obraId: undefined }
      }
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export type SaveItemChecklistPayload = SaveResourcePayload<ItemChecklistRequest>

/**
 * Salva um tipo de item checklist, se houver id altera o existente.
 */
export async function saveItemChecklist(
  itemChecklist: SaveItemChecklistPayload
) {
  try {
    const request = itemChecklist.id ? api.put : api.post

    const { data } = await request<ItemTypeChecklist>(
      'Obra/itemsChecklist',
      itemChecklist
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

export async function deleteItemUpload(options: ItemChecklistDeleteOptions) {
  try {
    const { data } = await api.delete<ItemChecklistUpload>(
      `Obra/ItemChecklistUpload/${options.obraId}/${options.id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}
