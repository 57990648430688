import { AuthContent, AuthHeroBg, AuthLayout } from 'carbono/layout/AuthLayout'
import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const bgImage = require('common/assets/bg_auth_1.jpg')

const AppAuthLayout: FC = ({ children }) => {
  return (
    <AuthLayout>
      <AuthHeroBg image={bgImage} />
      <AuthContent>{children}</AuthContent>
    </AuthLayout>
  )
}

export default AppAuthLayout
