import { SaveSendTaxPayload, SendTax, saveSendTax } from 'work/api/sendTaxApi'
import { call, put, takeLeading } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import {
  saveSendTaxFail,
  saveSendTaxStarted,
  saveSendTaxSuccess
} from 'work/reducers/sendTaxReducer'

import { PayloadAction } from '@reduxjs/toolkit'

export const sendTaxSchema = new schema.Entity<SendTax>('sendTax')

export function* handleSaveSendTax({
  payload
}: PayloadAction<SaveSendTaxPayload>) {
  try {
    const response = yield call(saveSendTax, payload)

    const { entities } = normalize(response, sendTaxSchema)

    return yield put(
      saveSendTaxSuccess({
        forItem: payload.id || 'new',
        result: entities.sendTax[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveSendTaxFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

function* sendTaxSaga() {
  yield takeLeading(saveSendTaxStarted.type, handleSaveSendTax)
}

export default sendTaxSaga
