import { Company, CreateCompany } from 'serviceProvider/contracts/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
  name: 'serviceProvider/company',
  initialState: {} as Company | CreateCompany,
  reducers: {
    addCompany: (state, action: PayloadAction<Company | CreateCompany>) => {
      return action.payload
    }
  }
})

export const { addCompany } = actions

export default reducer
