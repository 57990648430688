import {
  Condominium,
  FetchCondominiumsOptions,
  SaveCondominiumPayload
} from 'condominium/api/condominiumApi'
import { Pagination, RequestError } from 'types/global'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import createSelector from 'shared/createSelector'

// --------------------------------------------------
// Interfaces
// --------------------------------------------------

/**
 * Formato do state do condomínio
 */
export interface CondominiumState {
  status: { [key: string]: 'Pending' | 'Saving' | 'Done' | 'Error' }
  errors: { [key: string]: RequestError | undefined }
  pagination: Pagination
  pages: { [page: number]: string[] }
  items: { [key: string]: Condominium | undefined }
}

// --------------------------------------------------
// Reducer
// --------------------------------------------------

const initialState: CondominiumState = {
  items: {},
  pages: {},
  status: {},
  errors: {},
  pagination: {
    current: 1,
    pageSize: 15,
    total: 1
  }
}

const { actions, reducer: condominiumReducer } = createSlice({
  name: 'condominium',
  initialState,
  reducers: {
    fetchCondominiumsStarted(
      state,
      action: PayloadAction<FetchCondominiumsOptions | undefined>
    ) {
      state.status['page'] = 'Pending'
      state.errors['page'] = undefined

      return state
    },
    fetchCondominiumsSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        result: { [id: string]: Condominium }
        pagination: Pagination
      }>
    ) {
      const { result, pagination } = payload
      state.status['page'] = 'Done'
      state.errors['page'] = undefined

      state.pagination = {
        ...state.pagination,
        ...pagination
      }

      state.pages[pagination.current] = Object.keys(result)

      state.items = {
        ...state.items,
        ...result
      }

      return state
    },
    fetchCondominiumsFail(
      state,
      { payload }: PayloadAction<RequestError | undefined>
    ) {
      state.status['page'] = 'Error'
      state.errors['page'] = payload

      return state
    },
    fetchSingleCondominiumStarted(state, { payload }: PayloadAction<string>) {
      state.errors[payload] = undefined
      state.status[payload] = 'Pending'

      return state
    },
    fetchSingleCondominiumSuccess(
      state,
      { payload }: PayloadAction<Condominium>
    ) {
      state.status[payload.id] = 'Done'
      state.errors[payload.id] = undefined
      state.items[payload.id] = payload

      return state
    },
    fetchSingleCondominiumFail(
      state,
      { payload }: PayloadAction<{ id: string; error?: RequestError }>
    ) {
      state.status[payload.id] = 'Error'
      state.errors[payload.id] = payload.error

      return state
    },
    saveCondominiumStarted(
      state,
      { payload }: PayloadAction<SaveCondominiumPayload>
    ) {
      state.status[payload.id || 'new'] = 'Saving'
      state.errors[payload.id || 'new'] = undefined

      return state
    },
    saveCondominiumSuccess(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; result: Condominium }>
    ) {
      const { forItem, result } = payload
      if (forItem === 'new') {
        state.items[forItem] = payload.result
        state.status[forItem] = 'Done'
        state.errors[forItem] = undefined
      }

      state.status[result.id] = 'Done'
      state.errors[result.id] = undefined
      state.items[result.id] = payload.result

      return state
    },
    saveCondominiumFail(
      state,
      {
        payload
      }: PayloadAction<{ forItem: string | 'new'; error?: RequestError }>
    ) {
      const { forItem, error } = payload

      state.status[forItem] = 'Error'
      state.errors[forItem] = error

      return state
    }
  }
})

export const condominiumsOnPage = createSelector(({ condominium }) => {
  const { pagination, pages } = condominium

  return pages[pagination.current] || []
})

export const {
  fetchCondominiumsStarted,
  fetchCondominiumsSuccess,
  fetchCondominiumsFail,
  fetchSingleCondominiumStarted,
  fetchSingleCondominiumSuccess,
  fetchSingleCondominiumFail,
  saveCondominiumStarted,
  saveCondominiumSuccess,
  saveCondominiumFail
} = actions

export default condominiumReducer
