import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { PaginatedEndpointResponse } from 'types/global'
import Select from 'carbono/Forms/Select'
import useTypedSelector from 'common/hooks/useTypedSelector'

export interface CreateSelectResourceOptions<T> {
  resourceName: string
  idKey: keyof T
  labelKey: keyof T
  isEmptyQueryValid?: boolean
  resourcesLoader: (
    query: string,
    currentCondominium?: string
  ) => Promise<PaginatedEndpointResponse<T>>
  singleResourceLoader: (id: any) => Promise<T>
}

export interface SelectResourceProps<T> {
  placeholder?: string
  searchPlaceholder?: string
  value?: T | string
  onChange: (value?: T) => void
  onClear?: () => void
  isSize?: 'small'
  isDisabled?: boolean
  idValue?: string
  tipoHierarquia?: number
  Id?: string
}

function createSelectResource<T>(options: CreateSelectResourceOptions<T>) {
  let { isEmptyQueryValid } = options
  isEmptyQueryValid = !!isEmptyQueryValid

  const SelectResource: FC<SelectResourceProps<T>> = ({ value, ...props }) => {
    const currentCondominium = useTypedSelector(
      ({ auth }) => auth.currentCondominium
    )
    const [items, setItems] = useState<T[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const onLoadMany = useCallback(
      async (query: string) => {
        if (!query && isEmptyQueryValid) {
          setItems([])
          return
        }

        try {
          setIsLoading(true)
          const response = await options.resourcesLoader(
            query,
            currentCondominium
          )

          setItems(response.result)
          setIsLoading(false)
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      },
      [currentCondominium]
    )

    useEffect(() => {
      if (!value) return
      const isString = typeof value === 'string'
      const id = isString ? value : (value as any)[options.idKey]
      const isAlreadyLoaded = items.find(item => item[options.idKey] === id)

      if (isAlreadyLoaded) return

      const loadSingleResource = async (id: any) => {
        try {
          setIsLoading(true)
          const item = await options.singleResourceLoader(id)
          setItems(state => [...state, item])
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }

      loadSingleResource(id)
    }, [value])

    const currentItem = useMemo(() => {
      if (!value) return
      const isString = typeof value === 'string'

      const id = isString ? value : (value as any)[options.idKey]

      return items.find(item => {
        return item[options.idKey] === id
      })
    }, [value, items])

    return (
      <Select<T>
        isSize={props.isSize}
        isSearchable
        searchPlaceholder={props.searchPlaceholder}
        searchDelay={500}
        onChange={props.onChange}
        onOpen={() => onLoadMany('')}
        onChangeQuery={query => onLoadMany(query)}
        idKey={options.idKey}
        labelKey={options.labelKey}
        options={items}
        value={currentItem}
        placeholder={props.placeholder}
        isLoading={isLoading}
        disabled={props.isDisabled}
        onClear={props.onClear}
      />
    )
  }

  SelectResource.displayName = `Select${options.resourceName}`

  return SelectResource
}

export default createSelectResource
