import {
  SaveRemoveCaveatPayload,
  removeCaveat
} from 'removeCaveat/api/removeCaveat'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const removeCaveatThunk = createAsyncThunk(
  'removeCaveat/create',
  async (val: SaveRemoveCaveatPayload, { rejectWithValue }) => {
    try {
      return await removeCaveat(val.obraId)(val)
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)
