import api from 'common/api/api'
import { fetchSingle, save } from 'common/api/apiHelper'
import createRequestError from 'shared/createRequestError'
import { SaveResourcePayload } from 'types/global'
import { Upload } from 'upload/api/uploadApi'

export interface ProjectUpload {
  id: string
  projetoId: string
  upload: Upload
  aprovado?: boolean
  homologado: boolean
}

export interface Project {
  id: string
  obraId: string
  mensagem?: string
  uploads: string[]
  upload?: Upload[]
  projetoUploads?: ProjectUpload[]
  aprovado?: boolean
}

export interface ProjectDeleteOptions {
  obraId: string
  id: string
}

export type SaveProjectPayload = SaveResourcePayload<Omit<Project, 'aprovado'>>

export const saveProject = save<Project>('Obra/projeto')

export const saveProjectApproved = save<Project>('Obra/projetoHomologado')

export const fetchProject = fetchSingle(workId => `Obra/${workId}/projeto`)

export async function deleteProjectUpload(options: ProjectDeleteOptions) {
  try {
    const { data } = await api.delete<ProjectUpload>(
      `Obra/projeto/upload/${options.obraId}/${options.id}`
    )

    return data
  } catch (error) {
    throw createRequestError(error)
  }
}

// export const deleteProjectUpload = deleteResource(
//   id => `Obra/projeto/upload/${id}`
// )
