import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestError, TokenPayload } from 'types/global'

export type AuthStatus = 'GUEST' | 'AUTH' | 'RESTORING' | 'PENDING' | 'ERROR'

export interface AuthState {
  status: AuthStatus
  error?: RequestError
  token?: string
  tokenPayload?: TokenPayload
  hasAlreadyRestored: boolean
  currentCondominium?: string
  isInvite?: boolean
}

export interface LoggedInPayload {
  token: string
  tokenPayload: TokenPayload
  isInvite?: boolean
}

export interface Credentials {
  username: string
  password: string
}

const initialState: AuthState = {
  status: 'RESTORING',
  hasAlreadyRestored: false
}

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<LoggedInPayload>) {
      state.status = 'AUTH'
      state.token = action.payload.token
      state.tokenPayload = action.payload.tokenPayload
      state.isInvite = action.payload.isInvite
      state.hasAlreadyRestored = true

      return state
    },
    loginFailed(state, { payload }: PayloadAction<RequestError | undefined>) {
      state.status = 'ERROR'
      state.error = payload

      return state
    },
    loginStarted(state, _: PayloadAction<Credentials>) {
      state.status = 'PENDING'
      state.error = undefined

      return state
    },
    restoreStarted(state) {
      state.status = 'RESTORING'

      return state
    },
    restoreFailed(state) {
      state.status = 'GUEST'
      state.hasAlreadyRestored = true

      return state
    },
    refreshStarted(state) {
      return state
    },
    refreshFailed(state) {
      state.status = 'GUEST'
      state.token = undefined
      return state
    },
    logout(state) {
      state.token = undefined
      state.tokenPayload = undefined
      state.error = undefined
      state.status = 'GUEST'
    },
    condominiumProfileSelected(state, action: PayloadAction<string>) {
      state.currentCondominium = action.payload

      return state
    }
  }
})

export const {
  loginSuccess,
  loginFailed,
  loginStarted,
  refreshStarted,
  refreshFailed,
  restoreStarted,
  restoreFailed,
  logout,
  condominiumProfileSelected
} = actions

export default reducer
