import React, { FC } from 'react'
import Can, { CanContainer } from 'userPermissions/components/Can'

import { Box } from '@chakra-ui/core'
import Grid from 'carbono/Grid'
import { MenuItem } from 'carbono/layout/MenuItem'
import { Sidebar } from 'carbono/layout/SidebarLayout'
import api from 'common/api/api'
import useTypedSelector from 'common/hooks/useTypedSelector'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Rules } from 'userPermissions/api/userPermissionApi'
import AppLogo from './AppLogo'

const AppSidebar: FC = () => {
  const isSidebarCollapsed = useTypedSelector(
    ({ layout }) => layout.isSidebarCollapsed
  )

  const currentCondominium = useTypedSelector(
    ({ auth }) => auth.currentCondominium || ''
  )

  const condominium = useTypedSelector(
    ({ condominium }) => condominium.items[currentCondominium]
  )

  return (
    <Sidebar isOpen={!isSidebarCollapsed}>
      <Grid mode='flex' flexDirection='column' alignItems='center'>
        <Box maxWidth={150} width='100%' my={3}>
          <Link to={`/condominiums/${currentCondominium}`}>
            <AppLogo width='100%' />
          </Link>
        </Box>{' '}
        <Box width='100%'>
          <Box>
            {/* TODO(Fábio): Alterar permissao 'ViewCondominium' para 'ListCondominium' */}
            <Can
              rules={[Rules.CreateCondominium, Rules.ViewCondominium]}
              yes={
                <MenuItem icon='th' title='Condomínios'>
                  <Can
                    rules={Rules.ViewCondominium}
                    yes={
                      <Link to={`/condominiums`}>
                        <MenuItem icon='list' title='Listar condomínios' />
                      </Link>
                    }
                  />
                  <Can
                    rules={Rules.CreateCondominium}
                    yes={
                      <Link to={`/condominiums/create`}>
                        <MenuItem icon='plus' title='Cadastrar condomínios' />
                      </Link>
                    }
                  />
                </MenuItem>
              }
            />
            <CanContainer rules={[Rules.CreateUser, Rules.ViewUser]}>
              <MenuItem icon='user' title='Usuários'>
                <CanContainer rules={Rules.ViewUser}>
                  <Link to='/users'>
                    <MenuItem icon='list' title='Listar usuário' />
                  </Link>
                </CanContainer>

                <CanContainer rules={Rules.CreateUser}>
                  <Link to='/users/create'>
                    <MenuItem icon='plus' title='Cadastrar usuário' />
                  </Link>
                </CanContainer>
              </MenuItem>
            </CanContainer>
          </Box>

          {!!(
            condominium?.urlDashboard ||
            condominium?.urlDashboardManutencao ||
            condominium?.urlDashboardNaoConformidade
          ) && (
            <CanContainer
              rules={[
                Rules.ViewDashboardCondominium,
                Rules.VisualizarDashboardNaoConformidadeCondominio,
                Rules.VisualizarDashboardManutencaoCondominio
              ]}
            >
              <MenuItem icon='chart-pie' title='Dashboard'>
                {condominium?.urlDashboard ? (
                  <CanContainer rules={Rules.ViewDashboardCondominium}>
                    <Link to={'/dashboard/implantation'}>
                      <MenuItem icon='chart-area' title='Implantação' />
                    </Link>
                  </CanContainer>
                ) : null}

                {condominium?.urlDashboardNaoConformidade ? (
                  <CanContainer
                    rules={Rules.VisualizarDashboardNaoConformidadeCondominio}
                  >
                    <Link to={'/dashboard/nonCompliance'}>
                      <MenuItem icon='chart-line' title='Vistorias / Rotinas' />
                    </Link>
                  </CanContainer>
                ) : null}

                {condominium?.urlDashboardManutencao ? (
                  <CanContainer
                    rules={Rules.VisualizarDashboardManutencaoCondominio}
                  >
                    <Link to={'/dashboard/maintenance'}>
                      <MenuItem icon='chart-bar' title='Manutenção' />
                    </Link>
                  </CanContainer>
                ) : null}
                <Can
                  rules={Rules.VisualizarAcessoPessoasCondominio}
                  yes={
                    <Link
                      to={`/condominiums/${currentCondominium}/concierge/allowedPersons`}
                    >
                      <MenuItem icon='users' title='Pessoas Liberadas' />
                    </Link>
                  }
                />
              </MenuItem>
            </CanContainer>
          )}
          <CanContainer
            rules={[
              Rules.VisualizarTiposDeRotinas,
              Rules.ViewItemChecklistType,
              Rules.VisualizarTiposDeRotinas
            ]}
          >
            <MenuItem icon='tools' title='Configurações Gerais'>
              <Can
                rules={[
                  Rules.ViewItemChecklistType,
                  Rules.CreateItemChecklistType,
                  Rules.EditItemChecklistType,
                  Rules.DeleteItemChecklistType
                ]}
                yes={
                  <Link to={`/itemTypeChecklist/${currentCondominium}`}>
                    <MenuItem icon='list' title='Checklists do Processo' />
                  </Link>
                }
              />
              <RotinasMenu currentCondominium={currentCondominium} />
              <CanContainer rules={Rules.ViewNonComplianceType}>
                <MenuItem icon='list' title='Vistorias'>
                  <Link
                    to={`/condominiums/${currentCondominium}/nonComplianceCategory`}
                  >
                    <MenuItem icon='check' title='Categorias de vistorias' />
                  </Link>
                  <Link
                    to={`/condominiums/${currentCondominium}/nonComplianceType`}
                  >
                    <MenuItem icon='check' title='Tipos de vistorias' />
                  </Link>
                </MenuItem>
              </CanContainer>
            </MenuItem>
          </CanContainer>
          <Can
            rules={[Rules.ViewWork, Rules.CreateWork]}
            yes={
              <MenuItem icon='sync-alt' title='Processos'>
                <Can
                  rules={Rules.ViewWork}
                  yes={
                    <Link
                      to={`/condominiums/${currentCondominium}/works/1?OrderBy=DataUltimoEvento_descend`}
                    >
                      <MenuItem icon='list' title='Listar processos' />
                    </Link>
                  }
                />
                <Can
                  rules={Rules.CreateWork}
                  yes={
                    <Link
                      to={`/condominiums/${currentCondominium}/work/create`}
                    >
                      <MenuItem icon='plus' title='Cadastrar processo' />
                    </Link>
                  }
                />
              </MenuItem>
            }
          />
          <Can
            rules={[
              Rules.ViewSupporMaterial,
              Rules.CreateSupportMaterial,
              Rules.EditSupportMaterial,
              Rules.DeleteSupportMaterial
            ]}
            yes={
              <Link to='/supportMaterial'>
                <MenuItem icon='file-download' title='Materiais de Apoio' />
              </Link>
            }
          />

          <Can
            rules={[
              Rules.CreateDocument,
              Rules.ViewDocument,
              Rules.EditDocument,
              Rules.DeleteDocument
            ]}
            yes={
              <MenuItem icon='file-alt' title='Documentos Técnicos'>
                <CanContainer rules={Rules.VisualizarDocumentoTecnico}>
                  <Link to='/technicalDocument'>
                    <MenuItem icon='list' title='Documentos' />
                  </Link>
                </CanContainer>
                <CanContainer rules={Rules.VisualizarDisciplina}>
                  <Link to='/subjects'>
                    <MenuItem icon='list' title='Disciplinas' />
                  </Link>
                </CanContainer>
              </MenuItem>
            }
          />

          <Can
            rules={Rules.ViewReport}
            yes={
              <Link to='/report'>
                <MenuItem icon='file' title='Relatórios' />
              </Link>
            }
          />
          <CanContainer rules={[Rules.VisualizarEmails]}>
            <Link to='/e-mails'>
              <MenuItem icon='mail-bulk' title='E-mails' />
            </Link>
          </CanContainer>
          <CanContainer
            rules={[
              Rules.VisualizarLocal,
              Rules.VisualizarSistema,
              Rules.VisualizarItemManutencao,
              Rules.VisualizarEmpresaManutencao,
              Rules.VisualizarManutencao,
              Rules.VisualizarOrdemDeServico,
              Rules.VisualizarLicenca
            ]}
          >
            <MenuItem icon='folder' title='Manutenções'>
              <CanContainer
                rules={[
                  Rules.VisualizarLocal,
                  Rules.VisualizarSistema,
                  Rules.VisualizarItemManutencao,
                  Rules.VisualizarEmpresaManutencao
                ]}
              >
                <MenuItem icon='list' title='Cadastro'>
                  <CanContainer rules={Rules.VisualizarLocal}>
                    <Link to='/locations'>
                      <MenuItem icon='check' title='Locais' />
                    </Link>
                  </CanContainer>

                  <CanContainer rules={Rules.VisualizarSistema}>
                    <Link to='/system'>
                      <MenuItem icon='check' title='Sistemas' />
                    </Link>
                  </CanContainer>

                  <CanContainer rules={Rules.VisualizarItemManutencao}>
                    <Link to='/maintenance-item'>
                      <MenuItem icon='check' title='Itens de manutenção' />
                    </Link>
                  </CanContainer>

                  <CanContainer rules={Rules.VisualizarEmpresaManutencao}>
                    <Link to='/maintenance-companies'>
                      <MenuItem icon='check' title='Prestadores de Serviço' />
                    </Link>
                  </CanContainer>
                </MenuItem>
              </CanContainer>

              <CanContainer rules={Rules.VisualizarManutencao}>
                <Link to='/maintenances'>
                  <MenuItem icon='list' title='Chamados' />
                </Link>
              </CanContainer>

              <CanContainer rules={Rules.VisualizarOrdemDeServico}>
                <Link to='/ordem-servicos'>
                  <MenuItem icon='list' title='Ordens de Serviço' />
                </Link>
              </CanContainer>

              <CanContainer rules={Rules.VisualizarLicenca}>
                <Link to='/licenses'>
                  <MenuItem icon='list' title='Licenças' />
                </Link>
              </CanContainer>
              <CanContainer rules={Rules.VisualizarEmailCondominio}>
                <Link to='/notifications'>
                  <MenuItem icon='bell' title='Notificações' />
                </Link>
              </CanContainer>

              <CanContainer rules={Rules.VisualizarEmpresas}>
                <Link
                  to={`/condominiums/${currentCondominium}/serviceProvider/companies`}
                >
                  <MenuItem icon='list' title='Empresas' />
                </Link>
              </CanContainer>
            </MenuItem>
          </CanContainer>

          {/* <Can
            rules={Rules.ViewReport}
            yes={
              <Link to='/workinspection'>
                <MenuItem icon='file' title='Rotina de vistoria de obras' />
              </Link>
            }
          /> */}
        </Box>
      </Grid>
    </Sidebar>
  )
}

export default AppSidebar

function RotinasMenu({ currentCondominium }: { currentCondominium: string }) {
  const { data } = useQuery(
    ['rotinas', 'modulos', currentCondominium],
    async () => {
      try {
        const { data } = await api.get<
          {
            moduloNome: 'ROTINAS' | Omit<string, 'ROTINAS'>
          }[]
        >('/modulos', {
          params: {
            condominioId: currentCondominium
          }
        })

        return data
      } catch (error) {
        console.log(error)
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )

  if (!data || !data?.some(modulo => modulo.moduloNome === 'ROTINAS'))
    return null

  return (
    <MenuItem icon='list' title='Rotinas'>
      <Can
        rules={[
          Rules.VisualizarTiposDeChecklistDeRotinas,
          Rules.AdicionarTiposDeChecklistDeRotinas,
          Rules.EditarTiposDeChecklistDeRotinas,
          Rules.ExcluirTiposDeChecklistDeRotinas
        ]}
        yes={
          <Link to={`/routine/checklistTypes/${currentCondominium}`}>
            <MenuItem icon='check' title='Checklist de Rotinas' />
          </Link>
        }
      />
      <Can
        rules={[
          Rules.VisualizarTiposDeRotinas,
          Rules.AdicionarTiposDeRotinas,
          Rules.EditarTiposDeRotinas,
          Rules.ExcluirTiposDeRotinas
        ]}
        yes={
          <Link to={`/routine/typesRoutine/${currentCondominium}`}>
            <MenuItem icon='check' title='Tipos de Rotinas' />
          </Link>
        }
      />
    </MenuItem>
  )
}
