import {
  FetchUserCondominiumOptions,
  SaveUserCondominiumPayload,
  UserCondominium,
  fetchUsers,
  saveUserCondominium,
  deleteUser,
  editUser
} from 'condominium/api/UserCondominiumApi'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  loadUserCondominiumFail,
  loadUserCondominiumStarted,
  loadUserCondominiumSuccess,
  saveUserCondominiumFail,
  saveUserCondominiumStarted,
  saveUserCondominiumSuccess,
  deleteUserCondominiumFail,
  deleteUserCondominiumStarted,
  deleteUserCondominiumSuccess,
  editUserCondominiumStarted,
  editUserCondominiumSuccess,
  editUserCondominiumFail
} from 'condominium/reducers/UserCondominiumReducer'
import { normalize, schema } from 'normalizr'

import { Pagination } from 'types/global'
import { PayloadAction } from '@reduxjs/toolkit'

export const userCondominiumSchema = new schema.Entity<UserCondominium>(
  'userCondominium'
)

export function* handleLoadWorks(
  action: PayloadAction<FetchUserCondominiumOptions | undefined>
) {
  try {
    const response = yield call(fetchUsers, action.payload)

    const { entities } = normalize(response.result, [userCondominiumSchema])

    const pagination: Pagination = {
      current: response.page,
      total: response.totalItemCount,
      totalPaginas: response.pageAmount
    }

    return yield put(
      loadUserCondominiumSuccess({
        result: entities.userCondominium || [],
        pagination
      })
    )
  } catch (error) {
    return yield put(loadUserCondominiumFail(error))
  }
}

export function* handleSaveUserCondominium({
  payload
}: PayloadAction<SaveUserCondominiumPayload>) {
  try {
    const response = yield call(saveUserCondominium, payload)

    const { entities } = normalize(response, userCondominiumSchema)

    return yield put(
      saveUserCondominiumSuccess({
        forItem: payload.id || 'new',
        result: entities.userCondominium[response.id]
      })
    )
  } catch (error) {
    return yield put(
      saveUserCondominiumFail({
        forItem: payload.id || 'new',
        error
      })
    )
  }
}

export function* handleDeleteUserCondominium({
  payload
}: PayloadAction<string>) {
  try {
    yield call(deleteUser, payload)

    return yield put(deleteUserCondominiumSuccess(payload))
  } catch (error) {
    return yield put(
      deleteUserCondominiumFail({
        forItem: payload,
        error
      })
    )
  }
}

export function* handleEditUserCondominium({
  payload
}: PayloadAction<UserCondominium>) {
  try {
    if (!payload.id) return

    yield call(editUser(payload.id), payload)

    return yield put(editUserCondominiumSuccess(payload))
  } catch (error) {
    return yield put(
      editUserCondominiumFail({
        forItem: payload.id,
        error
      })
    )
  }
}

function* userCondominiumSaga() {
  yield takeLeading(saveUserCondominiumStarted.type, handleSaveUserCondominium)
  yield takeLatest(loadUserCondominiumStarted.type, handleLoadWorks)
  yield takeLeading(
    deleteUserCondominiumStarted.type,
    handleDeleteUserCondominium
  )
  yield takeLatest(editUserCondominiumStarted.type, handleEditUserCondominium)
}

export default userCondominiumSaga
