import React, { ComponentType, useEffect } from 'react'

import AppAuthLayout from 'app/components/AppAuthLayout'
import { AppLayout } from 'layout/reducers/layoutReducer'
import AppOpenLayout from 'app/components/AppOpenLayout'
import AppSidebarLayout from 'app/components/AppSidebarLayout'
import { BrowserRouter } from 'react-router-dom'
import LoadingLayout from 'carbono/layout/LoadingLayout'
import Routes from 'Routes'
import { hot } from 'react-hot-loader/root'
import { restoreStarted } from 'auth/reducers/authReducer'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'common/hooks/useTypedSelector'

const App = () => {
  const isAuthLayout = useTypedSelector(
    ({ layout }) => layout.current === AppLayout.Auth
  )
  const isLoadingLayout = useTypedSelector(
    ({ layout }) => layout.current === AppLayout.Loading
  )

  const isOpenLayout = useTypedSelector(
    ({ layout }) => layout.current === AppLayout.Open
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(restoreStarted())
  }, [])

  let Layout: ComponentType<any> = AppSidebarLayout
  if (isLoadingLayout) Layout = LoadingLayout
  if (isAuthLayout) Layout = AppAuthLayout
  if (isOpenLayout) Layout = AppOpenLayout

  return (
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  )
}

export default hot(App)
